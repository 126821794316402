<template>
  <div id="chart">
    <apexchart
      type="radar"
      height="430"
      width="430"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "chartRadar",
  props: {
    surveyScores: {
      type: Object,
      default: null,
    },
    analyzeScores: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "right",
          offsetX: 80,
          offsetY: 80,
          markers: {
            width: 13,
            height: 3,
            radius: 0,
          },
        },
        xaxis: {
          categories: ["Sleep (before)", "Sleep (after)", "Nerve or Excite"],
        },
        fill: {
          opacity: 0,
        },
      },
    };
  },
  methods: {
    async updateChartData() {
      this.series = [
        {
          name: "選手主観",
          data: [
            this.surveyScores["sleep_before_scores"],
            this.surveyScores["sleep_after_scores"],
            this.surveyScores["anticipation_scores"],
          ],
        },
        {
          color: "#FFA500",
          name: "血糖分析",
          data: [
            this.analyzeScores["sleep_before_scores"],
            this.analyzeScores["sleep_after_scores"],
            this.analyzeScores["anticipation_scores"],
          ],
        },
      ];
    },
  },
  mounted() {
    this.updateChartData();
  },
};
</script>
