<template>
  <div id="chart">
    <apexchart
      :key="chartKey"
      type="boxPlot"
      height="210"
      :options="chartOptionsColumn"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { chartOptionsColumn } from "../common.js";
export default {
  props: {
    boxPlotUpper: {
      type: String,
      default: "#ff0000",
    },
    checkProp: {
      type: Boolean,
      default: false,
    },
    averageData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    series: {
      get() {
        if (this.averageData) {
          return [
            {
              type: "boxPlot",
              data: [...this.averageData].map((item) => {
                const ydata = this.checkProp
                  ? [
                      item.all_day.min,
                      item.all_day.avg,
                      0,
                      item.all_day.avg,
                      item.all_day.max,
                    ]
                  : [
                      item.sleep.min,
                      item.sleep.avg,
                      0,
                      item.sleep.avg,
                      item.sleep.max,
                    ];
                return {
                  x: item.date,
                  y: ydata,
                };
              }),
            },
          ];
        } else {
          return [];
        }
      },
      set() {},
    },
  },
  data() {
    return {
      chartOptionsColumn: chartOptionsColumn(this.boxPlotUpper, ""),
      chartKey: 0,
    };
  },
  async created() {
    this.checkPropOption();
  },
  methods: {
    checkPropOption() {
      if (this.checkProp) {
        this.chartOptionsColumn = chartOptionsColumn(
          this.boxPlotUpper,
          "Interstitial glucose level / mg/dL"
        );
      } else {
        this.chartOptionsColumn = chartOptionsColumn(
          this.boxPlotUpper,
          "Interstitial glucose level in sleep / mg/dL"
        );
      }
    },
    updateMergeSeries() {
      this.series = [
        {
          type: "boxPlot",
          data: Object.values(this.averageData).map((item) => {
            const ydata = this.checkProp
              ? [
                  item.all_day.min,
                  Math.round(item.all_day.avg),
                  0,
                  Math.round(item.all_day.avg),
                  item.all_day.max,
                ]
              : [
                  item.sleep.min,
                  Math.round(item.sleep.avg),
                  0,
                  Math.round(item.sleep.avg),
                  item.sleep.max,
                ];
            return {
              x: item.date,
              y: ydata,
            };
          }),
        },
      ];
    },
  },
  mounted() {
    if (this.averageData) {
      this.updateMergeSeries();
    }
  },
  watch: {
    averageData: {
      handler: function () {
        this.updateMergeSeries();
        this.chartKey += 1;
      },
      deep: true,
    },
  },
};
</script>
