const state = {
  reportList: {
    data: [],
  },
  reportData: {
    data: {},
  },
  surveyOptions: {
    data: [],
  },
  createReport: {
    data: [],
  },
  detailReport: {
    data: {},
  },
  previewReport: {
    data: {},
  },
  reportId: null,
};

export default state;
