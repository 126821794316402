<template>
  <div class="modal-wrapper">
    <div class="modal-overlay" @click="$emit('hide')"></div>
    <div class="show account-modal">
      <form class="authen-form">
        <div class="mb-3">
          <div class="d-flex justify-content-between">
            <label for="exampleInputEmail1" class="form-label"
              >メールアドレス</label
            >
            <span
              v-show="!user.email"
              class="account-modal__btn-link"
              @click="changeEmail"
              >変更する</span
            >
          </div>
          <input
            type="email"
            :disabled="!!user.email"
            class="form-control"
            v-model="email"
          />
        </div>
        <div>
          <div class="d-flex justify-content-between">
            <label for="exampleInputPassword1" class="form-label"
              >パスワード</label
            >
            <span class="account-modal__btn-link" @click="changePassword"
              >変更する</span
            >
          </div>
          <input type="password" class="form-control" v-model="password" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "App",
  props: ["user"],
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  created: function () {
    this.email = this.user.email;
    this.full_name = this.user.full_name;
  },
  methods: {
    changePassword() {
      let data = {};
      data["password"] = this.password;
      axios.post("/manager/v2/profile/update/", data).then((res) => {
        this.password = "";
        this.$emit("update-profile", res.data);
        this.handleSuccess();
      });
    },
    handleSuccess() {
      this.$toasted.show("保存しました！", {
        theme: "bubble",
        position: "top-right",
        duration: 2000,
      });
    },
    changeEmail() {
      let data = {};
      data["email"] = this.email;
      axios.patch("/manager/v2/profile/", data).then((res) => {
        this.$emit("update-profile", res.data);
        this.handleSuccess();
      });
    },
  },
};
</script>

<style>
.authen-form input {
  background: #fafafa;
  border-radius: 5px;
  border: 0;
  padding: 12px 10px;
  font-size: 14px;
  line-height: 12px;
}

.account-modal__btn-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8b172f;
  cursor: pointer;
}
</style>
