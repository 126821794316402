<template>
  <div class="print-container">
    <!-- Page 1 -->
    <div class="print-page">
      <div class="logo-container d-flex align-items-center mb-3 px-2">
        <img
          class="logo-image"
          src="../../assets/img/group-48095639.svg"
          alt="logo"
        />
        <span class="logo-title">REPORT</span>
      </div>
      <div class="page-summary-container px-2">
        <div class="row align-items-end">
          <div class="col-sm-5 color-2">
            <p class="fw-bold fz-26">レポートサマリー</p>
          </div>
          <div class="col-sm-7">
            <p class="fz-12">
              複数回試合があった場合は期間中の平均を値を表示しています。
            </p>
          </div>
        </div>
      </div>
      <div class="under-line-1"></div>
      <div class="overflow-hidden d-flex flex-column px-2">
        <div class="beta">β版</div>
        <div
          class="card border-none bg-color-1 text-white p-3"
          style="margin-bottom: 10px"
        >
          <div class="row align-items-center">
            <div class="col-sm-2 text-center">
              <div class="d-flex flex-column">
                <div class="custom-number" v-if="previewData">
                  {{ previewData.hpz_lower }}<span class="unit">mg/dL</span>
                </div>
                <div style="height: 20px; font-size: 50px">&#11823;</div>
                <div class="custom-number" v-if="previewData">
                  {{ previewData.hpz_upper }}<span class="unit">mg/dL</span>
                </div>
              </div>
            </div>
            <div
              class="col-sm-10 align-items-center"
              style="border-left: 1px solid #fff"
            >
              <div class="fz-12 fw-bold mb-1">
                あなたのハイパフォーマンスエリア
              </div>
              <div class="text-justify fz-12 custom-lh">
                最もパフォーマンスを発揮しやすいあなたの血糖値エリアです。集中力、アドレナリン、メンタル、
                疲労など問題があると、最適なエネルギーを補給できなくなり、このエリアから血糖値が離れていきます。
              </div>
            </div>
          </div>
        </div>

        <div class="beta">β版</div>
        <div
          class="card border-none bg-color-1 text-white p-3"
          style="margin-bottom: 36px"
        >
          <div class="row align-items-center">
            <div class="col-sm-2 text-center">
              <div class="custom-number" v-if="previewData">
                {{ getBestScores(previewData?.match_reports) }}
                <span class="btn-sz-lg">/100</span>
              </div>
            </div>
            <div class="col-sm-10" style="border-left: 1px solid #fff">
              <div class="fz-12 fw-bold mb-1">あなたのSympaFitスコア</div>
              <div class="fz-12 custom-lh">
                アンケート等であなたが入力した主観データと血糖値から解析した体の状態が一致しているほどスコアが高くなり、
                自分の感覚が正しかったと言えます。心と体のズレを無くすことで、自分を正しくコントロールすることができます。
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-12 px-4">
              <div class="border-dash"></div>
              <div>
                <div class="fz-12 fw-bold">Sympafitスコアを左右する要素</div>
                <div class="fz-12 custom-lh my-1">
                  ■ 試合での緊張や興奮などの気持ち
                </div>
                <div class="fz-12 custom-lh">
                  緊張・興奮といった心の状態は血糖値と相関関係があります。その人に合った適度な緊張・興奮状態であることがパフォーマンスを最大化させるには大切であり、
                  緊張・興奮をコントロールしていくことで、パフォーマンスを発揮できる状態を作っていくことができます。緊張、興奮具合は自覚と実際が乖離していることが多く、この感覚を正しく持つことがパフォーマンスの最大化に必要です。
                </div>
                <div class="fz-12 custom-lh my-1">■ 睡眠</div>
                <div class="fz-12 custom-lh">
                  血糖値解析から睡眠の質を解析することができます。自分が感覚で感じている睡眠と、
                  実際の血糖値データからわかる睡眠を比較することで、心と体の感覚のズレをスコア化します。
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="beta">β版</div>
        <div class="card accuracy p-3">
          <div class="row text-dark">
            <div class="col-sm-12">
              <div class="d-flex fz-12 align-items-center">
                解析精度 <span class="rounded-circle"> 約 </span>
                <b class="title-sz-1 fw-bold">{{ accuracy }} %</b>
              </div>
              <div class="progress my-2">
                <div
                  class="progress-bar bg-color-1"
                  role="progressbar"
                  :style="{ width: accuracy + '%' }"
                  aria-valuenow="progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="fz-12 fw-normal lh-base">
                レポート精度の目安を表しています。解析データ数が多いほど精度が高まるため、継続的に血糖値解析をすることで解析精度を高めることができます。また、
                この解析には主観・生活習慣データが必要なため、選手からの入力情報が不足しているとレポートの質が低下する可能性があります。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page 2 -->
    <div class="print-page">
      <div class="logo-container d-flex align-items-center mx-2 mb-3">
        <img
          class="logo-image"
          src="../../assets/img/group-48095639.svg"
          alt="logo"
        />
        <span class="logo-title">REPORT</span>
      </div>
      <div class="overflow-hidden mx-2">
        <p class="fz-12">0時から24時まで1日のグルコース変動を可視化</p>
        <div class="mb-3">
          <p class="custom-number color-2">
            期間中のグルコース(血糖)変動グラフ
          </p>
          <div class="under-line-2"></div>
        </div>
        <div class="card mb-4 second-page">
          <div class="row">
            <div class="col-sm-12">
              <ChartLinePrev :glucose-data="previewData.series" />
              <div class="mb-2">
                <div class="p-1 text-dark text-box overall-comment">
                  {{ overallComment }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-hidden mx-2 my-3">
        <div class="mb-3">
          <p class="custom-number color-2">日毎のグルコース(血糖)変動グラフ</p>
          <div class="under-line-2"></div>
        </div>
        <div class="card second-page bg-white">
          <div class="row pt-2">
            <div class="col-sm-6 w-50">
              <ChartColumnPrev
                :average-data="previewData.series"
                :boxPlotUpper="boxPlotUpper"
                :check-prop="false"
              />
              <div class="text-center fz-12 chart-label">
                睡眠中のグルコース平均値の推移を示したグラフ
              </div>
              <div class="mb-2">
                <div class="text-box average-comment p-1">
                  {{ averageSleepGlucoseComment }}
                </div>
              </div>
            </div>
            <div class="col-sm-6 w-50">
              <ChartColumnPrev
                :average-data="previewData.series"
                :check-prop="true"
              />
              <div class="text-center fz-12 chart-label">
                1日のグルコース平均値の推移を示したグラフ
              </div>
              <div class="mb-2">
                <div class="text-box average-comment p-1">
                  {{ averageGlucoseComment }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page 3 -->
    <div
      class="print-page"
      v-for="item in previewData.match_reports"
      :key="item.match_event"
    >
      <div class="logo-container d-flex mx-2 mb-3">
        <img
          class="logo-image"
          src="../../assets/img/group-48095639.svg"
          alt="logo"
        />
        <span class="logo-title">REPORT</span>
      </div>
      <div class="overflow-hidden px-2">
        <div class="mb-3">
          <p class="custom-number color-2">試合ごとの解析</p>
          <div class="under-line-2"></div>
        </div>
        <div class="card third-page">
          <div class="row mx-0">
            <div class="col-sm-12 px-0">
              <div
                v-if="item.start_at && item.end_at"
                class="row mx-0 py-2 d-flex border-bottom border-dark align-items-center fz-12"
              >
                <div class="col-sm-6 px-0 fw-s-bold">
                  試合日時:
                  {{ formatDateTimeRange(item) }}
                </div>
                <div class="col-sm-6 px-0 text-end fw-s-bold">
                  {{ item.match_result_input }}
                </div>
              </div>
              <ChartLinePrev
                class="pt-1"
                :match-glucose-data="item.series"
                :ideal-data="item.ideal"
                :high-per-lower="previewData.hpz_lower"
                :high-per-upper="previewData.hpz_upper"
                :match-start="item.start_at"
                :match-end="item.end_at"
              />
              <div
                class="row mx-0 border-top border-bottom border-dark text-dark"
              >
                <div class="options d-flex flex-row">
                  <div style="margin-right: 10px">
                    <div class="badge rounded-pill bg-dark">睡眠</div>
                    <b class="fw-s-bold fz-12">
                      {{ item.sleep_state_day_before_match.title }},
                      {{ item.sleep_state_after_match.title }}</b
                    >
                  </div>
                  <div>
                    <div class="badge rounded-pill bg-dark">気持ち</div>
                    <b class="fw-s-bold fz-12">
                      {{ getTitle(item.mood_before_match) }}
                    </b>
                  </div>
                </div>
                <div class="options d-flex flex-row">
                  <div style="margin-right: 10px">
                    <div class="badge rounded-pill bg-dark">体調</div>
                    <b class="fw-s-bold fz-12">
                      {{ item.physical_state_before_match.title }}
                    </b>
                  </div>
                  <div>
                    <div class="badge rounded-pill bg-dark">結果</div>
                    <b class="fw-s-bold fz-12">
                      {{ getTitle(item.mood_during_match) }}
                    </b>
                  </div>
                </div>
              </div>
              <div class="rounded">
                <div class="advice">
                  <span class="title"
                    >この試合に対するコメント＆アドバイス
                  </span>
                  <br />
                  {{ item.match_glucose_comment }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="overflow-hidden d-flex flex-column border bg-white mx-2 mt-4 py-3 px-2"
      >
        <div class="row mx-0">
          <div class="col-sm-6">
            <div class="custom-number fw-bold">
              Sympafitスコア
              <b class="custom-number color-2">
                {{ item.overall_scores }}
              </b>
            </div>
            <div class="mt-3" style="margin-left: 10px">
              右のグラフは、 主観 (青線) と血糖値分析 (橙線) の三角が一致す
              るほど、乖離ない正確な把握を示すので、
              <span class="fw-s-bold">SympaFitスコア</span>は高くな ります。<br />
              パフォーマンスを発揮するための心身の適切なコンディショニング
              には、まず状態の正確な把握が不可欠です。<br />
              <span class="fw-s-bold">SympaFitスコア</span>を上げ、
              自分を正確に把握できるようにしていくこ
              とで、高いパフォーマンスを発揮できる状態を作っていきましょう。
            </div>
          </div>
          <div class="col-sm-6">
            <ChartRadar
              :analyze-scores="item.analyzed_scores"
              :survey-scores="item.survey_scores"
              style="margin-top: -60px"
            />
          </div>
        </div>

        <div class="row mx-0" style="margin-top: -95px">
          <div class="custom-number fw-bold mb-2">コメント</div>
          <div style="margin-left: 10px">
            あなたの
            <span class="fw-s-bold">
              SympaFitスコアは 「{{ item.overall_scores }}」
            </span>
            で、緊張興奮状態を自身で正確に把握できていると言えます。<br />(
            <span class="fw-s-bold">0-20 :</span> 乖離が大きい、
            <span class="fw-s-bold">21-40 :</span> 乖離がある、
            <span class="fw-s-bold">41-60 :</span>
            把握できているが乖離もある、
            <span class="fw-s-bold">61-80 :</span>
            ある程度正確に把握できている、
            <span class="fw-s-bold">81-100 :</span> 正確に把握できている)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartColumnPrev from "../../components/Report/ChartColumnPrev.vue";
import ChartLinePrev from "../../components/Report/ChartLinePrev.vue";
import ChartRadar from "../../components/Report/ChartRadar.vue";
import store from "../../store/index.js";
import { computed } from "vue";

export default {
  name: "Preview",
  components: {
    ChartColumnPrev,
    ChartLinePrev,
    ChartRadar,
  },
  data() {
    return {
      previewData: computed(() => store.state.previewReport.data),
      minValue: 0,
      maxValue: 100,
      showModal: false,
      boxPlotUpper: "#1f4ee6",
      isPrinting: false,
      matchResult: "",
      overallComment: "",
      averageGlucoseComment: "",
      averageSleepGlucoseComment: "",
      accuracy: 0,
    };
  },

  async created() {
    await this.getApiReview();
    this.accuracy = this.previewData.accuracy;
    this.overallComment = this.previewData.overall_comment;
    this.averageGlucoseComment = this.previewData.avg_glucose_comment;
    this.averageSleepGlucoseComment =
      this.previewData.avg_sleep_glucose_comment;
  },
  methods: {
    async getApiReview() {
      await store.dispatch("getPreviewReport", {
        uuid: this.$route.params.uuid,
      });
    },

    formatDateTimeRange(obj) {
      const date = obj.start_at.split(" ")[0];

      const startTime = new Date(obj.start_at).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      const endTime = new Date(obj.end_at).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      const year = date.split("-")[0];
      const month = date.split("-")[1];
      const day = date.split("-")[2];

      const jpDate = `${year}年${month}月${day}日`;

      return `${jpDate} ${startTime} ~ ${endTime}`;
    },

    handleBeforePrint() {
      this.isPrinting = true;
    },

    handleAfterPrint() {
      this.isPrinting = false;
    },

    getTitle(array) {
      const resultTitles = array.map((item) => item?.title);
      return resultTitles.join(", ");
    },

    getBestScores(array) {
      if (!Array.isArray(array) || array.length === 0) {
        return 0;
      }

      return Math.max(...array.map((item) => item?.overall_scores || 0));
    },
  },
  mounted() {
    window.addEventListener("beforeprint", this.handleBeforePrint);
    window.addEventListener("afterprint", this.handleAfterPrint);
  },
  beforeDestroy() {
    window.removeEventListener("beforeprint", this.handleBeforePrint);
    window.removeEventListener("afterprint", this.handleAfterPrint);
  },
};
</script>

<style scoped>
.print-container {
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.print-page {
  height: 100%;
  width: 100%;
  page-break-after: always;
  padding: 0 20px 500px;
  background-color: #fafafa;
}

.under-line-1 {
  border-top: 18px solid #f1f1f1;
  margin: -25px 8px 0px 8px;
}

.under-line-2 {
  border-top: 18px solid #f1f1f1;
  margin: -25px 0px 0px 0px;
}

.custom-lh {
  color: #fff;
  line-height: normal;
  font-weight: normal;
}

.border-dash {
  margin-top: 15px;
  margin-bottom: 15px;

  border-top: 2px #fff dashed;
}

.unit {
  font-size: 15px;
  font-weight: 700;
}

.logo-container {
  gap: 5px;
}

.logo-title {
  color: var(--8-b-172-f, #8b172f);
  text-align: center;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

.accuracy {
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background: #fff;
}

.second-page {
  border: 1px solid #b32a1f;
  border-radius: 25px;
  padding: 0px 10px;
}

.text-box {
  border: 1px solid #d48f8d;
}

.third-page {
  border: 1px solid #000000;
  padding: 0px 10px;
}

.approx-icon {
  left: 59px;
  position: relative;
  font-weight: 700;
}

.border-custom {
  border: 1px solid #35363a;
  display: flex;
}

.custom-number {
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
}

.borderTop {
  border-top: 2px dashed #fff;
  margin: 24px 0px 0px 0px;
}

.sidebar {
  display: none;
}

.wrapper-content {
  padding-left: 0px;
  padding-top: 0px;
}

.fw-s-bold {
  font-weight: 500;
}

.badge {
  font-size: 12px;
}

.options {
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.advice-title {
  color: #8b172f;
  font-weight: 700;
}

.beta {
  display: inline-flex;
  position: relative;

  top: 20px;
  left: 6px;

  z-index: 1000;
  padding: 4px 8px;
  margin-left: auto;
  border-radius: 4px;

  color: var(--ffffff, #fff);
  background: var(--17618-b, #17618b);
}

.average-comment {
  font-size: 11px;
  line-height: 1.4;
  font-weight: 300;
  color: #000000;
}

.overall-comment {
  font-size: 11px;
  line-height: 1.4;
  font-weight: 300;
  color: #000000;
}

.advice {
  font-size: 12px;
  line-height: 1.4;

  margin: 5px 0;
}

.advice .title {
  color: #8b172f;
  font-weight: 700;
}

.fz-12 {
  font-size: 12px;
  font-size: 0.75rem;
}

.chart-label {
  margin-top: -15px;
  margin-bottom: 5px;
  font-weight: 300;
}

@media print {
  @page {
    margin: 0;
  }

  .print-container {
    page-break-after: auto;
  }

  .print-page {
    page-break-after: always;
  }

  .logo-container {
    margin-top: 30px;
  }

  .options {
    font-size: 11px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
</style>
