<template>
  <div v-if="user">
    <div class="d-flex">
      <div
        v-click-outside="hideModal"
        class="header-user dropdown"
        v-if="!isRoutePreview"
      >
        <div class="notification">
          <b-button
            v-b-tooltip.hover.bottom.arrow="'通知'"
            variant="primary"
            data-bs-toggle="dropdown"
            aria-expanded="true"
            @click="handleShowModalNoti"
            class="icon-wrapper p-0"
          >
            <img src="./assets/img/icons/ico-notification.svg" alt="no-img" />
          </b-button>
          <notification-dropdown :show="showModalNoti" />
        </div>
        <div>
          <b-button
            variant="none"
            class="btn-dropdown-user btn-clear"
            type="button"
            :placement="'left'"
            data-bs-toggle="dropdown"
            aria-expanded="true"
            id="my-button"
            @click="handleShowModalAccount"
          >
            <img
              class="avatar"
              v-if="user?.avatar"
              :src="user?.avatar"
              alt="noimg"
            />
            <img
              class="avatar"
              v-else
              src="./assets/img/users/avatar-default.svg"
              alt=""
            />
            <b-tooltip target="my-button" id="account-tooltip" triggers="hover">
              アカウント
            </b-tooltip>
          </b-button>
        </div>
        <div
          class="dropdown-menu dropdown-menu-end dropdown-account"
          :class="{ show: showModal }"
        >
          <div class="dropdown-account__header">
            <div class="upload-avatar mx-auto dropdown-account__avatar">
              <img v-if="user?.avatar" :src="user?.avatar" alt="noimg" />
              <img v-else src="./assets/img/users/avatar-default.svg" alt="" />
            </div>
            <h3 class="dropdown-account__name">{{ user?.full_name }}</h3>
            <p class="dropdown-account__mail">{{ user?.email }}</p>
          </div>
          <div class="dropdown-account__body">
            <div
              @click="showEditProfile = true"
              class="dropdown-account__action"
            >
              <p>プロフィール</p>
            </div>
            <div
              @click="showSettingAccount = true"
              class="dropdown-account__action"
            >
              <p>アカウント設定</p>
            </div>
            <div
              @click="showSettingReceiveMail = true"
              class="dropdown-account__action"
            >
              <p>通知設定</p>
            </div>
          </div>
          <div class="dropdown-account__footer">
            <div class="dropdown-account__action">
              <p @click="logout">ログアウトする</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar" v-if="!isRoutePreview">
        <div class="team_menu">
          <button
            @click="changeActiveTeam(v.id)"
            :class="['btn_select_team', { active: v.id === activeTeamId }]"
            v-for="(v, k) in teams"
            :key="k"
            :id="'team-' + v.id"
          >
            <span>
              {{ getShortName(v.name) }}
            </span>
            <b-tooltip
              :target="'team-' + v.id"
              :id="'team-tooltip-' + v.id"
              placement="right"
              offset="0"
              >{{ v.name }}</b-tooltip
            >
          </button>
        </div>
        <div class="sidebar-inner">
          <div class="sidebar-inner-head">
            <div class="sidebar-logo">
              <router-link to="/">
                <img src="./assets/img/logo.svg" alt="" />
              </router-link>
            </div>
            <div
              class="working-hours"
              :class="{ active: $route.name == 'feed' }"
            >
              <router-link to="/">
                <div class="d-flex align-items-center ps-3">
                  <i
                    v-if="$route.name == 'feed'"
                    class="ico-working-hours-white"
                  ></i>
                  <i v-if="$route.name !== 'feed'" class="ico-working-hours"></i
                  >日別記録
                </div>
              </router-link>
            </div>
          </div>
          <div class="users">
            <div class="sidebar-inner-body-search">
              <div
                class="users-head d-flex align-items-center justify-content-between"
              >
                <h2 class="title title-sz-3">選手一覧</h2>
                <div class="add-user" v-show="0">
                  <button class="btn-add-user">
                    <span class="ico-plus-circle">＋</span>
                  </button>
                </div>
              </div>
              <div class="users-search">
                <form action="">
                  <div class="form-search-user position-relative">
                    <input
                      v-model="keyword"
                      class="form-control"
                      type="text"
                      placeholder="ユーザーを検索"
                    />
                    <i class="ico-user-group"></i>
                  </div>
                </form>
              </div>
            </div>
            <div class="list-users">
              <router-link
                :to="{
                  name: 'detail',
                  params: { id: user.id },
                  query: { mode: getMode() },
                }"
                v-for="user in filteredUsers"
                :key="user.id"
              >
                <div
                  class="user-item"
                  :class="{ active: $route.params.id == user.id }"
                >
                  <div class="user-item-img">
                    <img v-if="user?.avatar" :src="user?.avatar" alt="noimg" />
                    <img
                      v-else
                      src="./assets/img/users/avatar-default.svg"
                      alt=""
                    />
                  </div>
                  <div class="user-item-text">{{ user?.full_name }}</div>
                </div>
              </router-link>
            </div>
            <div class="add-new-member" v-show="0">
              <button class="btn btn-outline-grey w-100 text-start">
                <span class="ico-plus-circle ico-btn-left">+</span
                >メンバーを追加
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-backdrop"></div>
      <router-view :activeTeamId="activeTeamId"></router-view>
      <update-profile
        @update-profile="updateProfile"
        @hide="showEditProfile = false"
        v-show="showEditProfile"
        :user="user"
      />
      <setting-account
        @update-profile="updateProfile"
        @hide="showSettingAccount = false"
        v-show="showSettingAccount"
        :user="user"
      />
      <setting-receive-mail
        @update-profile="updateProfile"
        @hide="showSettingReceiveMail = false"
        v-show="showSettingReceiveMail"
        :user="user"
      />
    </div>
  </div>

  <div v-else class="login-page">
    <div style="min-height: 100vh" class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NotificationDropdown from './components/NotificationDropdown';
import SettingAccount from './components/SettingAccount';
import SettingReceiveMail from './components/SettingReceiveMail';
import UpdateProfile from './components/UpdateProfile';
import { BButton } from 'bootstrap-vue';

export default {
  name: 'App',
  components: {
    BButton,
    NotificationDropdown,
    UpdateProfile,
    SettingAccount,
    SettingReceiveMail,
  },
  data: function () {
    return {
      members: undefined,
      showModal: false,
      showModalNoti: false,
      showEditProfile: false,
      showSettingAccount: false,
      showSettingReceiveMail: false,
      keyword: '',
      loaded: false,
      user: {},
      teams: [],
      activeTeamId: '',
    };
  },
  computed: {
    isRoutePreview() {
      return this.$route.name === 'report.preview';
    },
    filteredUsers() {
      if (this.keyword.trim() === '') return this.members;
      return this.members.filter((item) =>
        item.full_name.toLowerCase().includes(this.keyword.toLowerCase())
      );
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      try {
        this.user = JSON.parse(localStorage.getItem('user'));
        window.user = this.user;
        if (this.user) this.getTeamMembers();
      } catch (err) {
        this.user = false;
      }
    },
    user() {
      // get teams every refresh page or login with new user to get newest team data
      if (this.user) this.getTeams();
    },
  },

  created: function () {
    try {
      this.user = JSON.parse(localStorage.getItem('user'));
      window.user = this.user;
    } catch (err) {
      this.user = false;
    }
  },
  methods: {
    getMode() {
      let mode = localStorage.getItem('mode');
      if (!mode) {
        mode = 1;
        localStorage.setItem('mode', mode);
      }
      return parseInt(mode);
    },
    changeActiveTeam(id) {
      this.activeTeamId = +id;
      // change value of selected active team id
      localStorage.setItem('activeTeamId', +this.activeTeamId);
      this.getTeamMembers(true);
    },
    getShortName(name) {
      let shortName = '';
      const words = name.split(' ');
      for (let i = 0; i < words.length; i++) {
        shortName += words[i][0];
      }
      return shortName?.slice(0, 2);
    },
    getTeams() {
      axios.get(`/manager/v2/teams/`).then((res) => {
        const isAdvisor = this.user.type === 'Advisor';
        let teams = res.data.filter((team) => team.is_display === true);

        // if is isAdvisor => can view and switch all team
        // else only view my own team
        if (!isAdvisor) {
          teams = teams.filter((v) => {
            const managerIds = v?.manager.map((v) => v.id);

            const isManagerOfTeam = managerIds.includes(this.user.id);

            return isManagerOfTeam;
          });
        }
        this.teams = teams;

        // get selected old team id if has, else get first id of team
        const oldActiveTeamId = localStorage.getItem('activeTeamId') ?? '';
        const foundTeam = this.teams.find((v) => +v.id === +oldActiveTeamId);
        this.activeTeamId = foundTeam ? +foundTeam?.id : +this?.teams?.[0]?.id;
        localStorage.setItem('activeTeamId', +this.activeTeamId);

        this.getTeamMembers();
      });
    },
    getTeamMembers(isChangeTeam = false) {
      axios
        .get(`/manager/v2/teams/${this.activeTeamId}/members/`)
        .then((resp) => {
          this.members = resp.data.members;

          const currentRouter = window.location.pathname;
          const regex = /^\/detail\/\d+$/;
          const isDetailPage = regex.test(currentRouter);
          const newRouter = '/';
          // force redirect to home page when change team
          if (isChangeTeam && newRouter !== currentRouter && isDetailPage) {
            this.$router.push(newRouter);
          }
          this.loaded = true;
        });
    },
    updateProfile(user) {
      localStorage.setItem('user', JSON.stringify(user));
      this.user = user;
      window.user = user;
    },
    hideModal() {
      this.showModalNoti = false;
      if (
        this.showEditProfile ||
        this.showSettingAccount ||
        this.showSettingReceiveMail
      ) {
        return;
      }
      this.showModal = false;
    },
    handleShowModalNoti() {
      this.hideModal();
      this.showModalNoti = true;
    },
    handleShowModalAccount() {
      this.hideModal();
      this.showModal = true;
    },
    handleShowSettingReceiveMail() {
      this.hideModal();
      this.showSettingReceiveMail = true;
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('activeTeamId');
      this.$router.push('/login');
    },
  },
};
</script>

<style>
@import './assets/lib/bootstrap/bootstrap.min.css';
@import './assets/css/main.css';

.notification {
  position: relative;
}

.dropdown-account {
  padding: 25px 0 0;
}

.dropdown-account__header {
  padding: 0 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}

.dropdown-account__avatar {
  margin: 0;
  margin-bottom: 20px;
  width: fit-content;
}

.dropdown-account__avatar img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.dropdown-account__name {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #35363a;
  margin-bottom: 10px;
}

.dropdown-account__mail {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #bfbfbf;
  margin-bottom: 0;
}

.dropdown-account__body {
  padding: 5px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.dropdown-account__action {
  padding: 12px 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.dropdown-account__action p {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #35363a;
  margin-bottom: 0;
}

.dropdown-account__action:hover {
  background-color: #fafafa;
}

.dropdown-account__footer {
  padding: 5px 10px;
}

.dropdown-account__footer .dropdown-account__action:hover {
  background-color: white;
}

.dropdown-account__footer p {
  color: #8b172f;
}

#account-tooltip {
  margin-right: 50px;
}

#account-tooltip .arrow:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 65px;
  transform: translateX(-50%);
  border-width: 0 5px 5px;
  border-style: solid;
  border-color: #000 transparent;
}

.team_menu {
  width: 55px;
  flex-shrink: 0;
  border-right: 1px solid #eaeaea;
  padding-top: 49px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
}

.sidebar {
  display: flex;
}

.btn_select_team {
  width: 35px;
  height: 35px;

  background: #f1f1f1;
  border-radius: 5px;
  padding: 0;
  border: none;
}

.btn_select_team span {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;

  text-align: center;
  letter-spacing: -0.05em;
  color: #35363a;
}

.btn_select_team.active {
  border: 2px solid #8b172f;
}
</style>
