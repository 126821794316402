<template>
  <div class="wrapper-content">
    <div class="header-site d-flex justify-content-between align-items-end">
      <div class="d-flex align-items-center">
        <div class="filters-title me-3" style="margin-bottom: 12px">
          {{ date.yy }}<span>年</span>{{ date.mm }}<span>月</span>{{ date.dd
          }}<span>日</span>
        </div>
        <a class="nav-link px-0" href="#!" @click="addDate(-1)"
          ><i class="ico-left"
        /></a>
        <a class="nav-link px-0" href="#!" @click="addDate(1)"
          ><i class="ico-right"
        /></a>
      </div>

      <div class="tabs" v-show="0">
        <ul class="nav nav-tabs-horizontal" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              2024/01/01
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-content" id="main">
      <div class="tab-content" id="myTabContent">
        <div v-show="records.length == 0">データがありません。</div>
        <div
          v-for="item in records"
          :key="item.id"
          class="d-flex bg-light mb-3 p-3"
        >
          <div class="p-3" style="width: 220px">
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <div class="user-item-img">
                <img
                  v-if="item.member?.avatar"
                  :src="item.member?.avatar"
                  alt="noimg"
                />
                <img
                  v-else
                  src="./assets/img/users/avatar-default.svg"
                  alt=""
                />
              </div>
              <h2 class="ms-2 title title-sz-3">
                {{ item.member?.full_name }}
              </h2>
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-between mb-1 align-items-end">
              <div class="small">グルコースmg/dL</div>
            </div>
            <apexchart
              height="180"
              width="600"
              type="line"
              :options="item.options"
              :series="item.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
export default {
  name: "App",
  components: {},
  props: ["activeTeamId"],
  data: function () {
    return {
      currentDate: "",
      date: {
        mm: 0,
        dd: 0,
        yy: 0,
      },
      records: [],
    };
  },
  mounted: function () {
    this.currentDate = new Date();
    if (this.activeTeamId) this.fetchData();
  },
  watch: {
    currentDate(newValue) {
      this.date.dd = newValue.getDate();
      this.date.mm = newValue.getMonth() + 1;
      this.date.yy = newValue.getFullYear();
    },
    activeTeamId() {
      if (this.activeTeamId) this.fetchData();
    },
  },
  methods: {
    addDate(i) {
      this.currentDate.setDate(this.currentDate.getDate() + i);
      this.date.dd = this.currentDate.getDate();
      this.date.mm = this.currentDate.getMonth() + 1;
      this.date.yy = this.currentDate.getFullYear();
      this.fetchData();
    },

    getColor(type) {
      const typeMappings = {
        1: { color: "#18698c", text: "睡眠時間" },
        2: { color: "#8c7518", text: "朝食" },
        3: { color: "#8c7518", text: "昼食" },
        4: { color: "#8c7518", text: "夜食" },
        5: { color: "#8c7518", text: "間食" },
        6: { color: "#75188c", text: "練習" },
        7: { color: "#75188c", text: "試合" },
        8: { color: "#75188c", text: "筋トレ" },
        default: { color: "#808080", text: "Unknown" },
      };

      return typeMappings[type] || typeMappings.default;
    },
    fetchData() {
      let data = {
        params: {
          date: dayjs(this.currentDate).format("YYYY-MM-DD"),
        },
      };
      axios
        .get(`/manager/v2/teams/${this.activeTeamId}/feed/`, data)
        .then((resp) => {
          this.records = [];
          resp.data.records.forEach((item) => {
            this.records.push({
              member: item.member,
              options: {
                annotations: {
                  yaxis: [
                    {
                      y: 90,
                      y2: 160,
                      borderColor: "#000",
                      fillColor: "#333",
                      opacity: 0.1,
                    },
                  ],
                  xaxis: [
                    {
                      x: item.start_at,
                      x2: item.end_at,
                      fillColor: this.getColor(item.type).color,
                      opacity: 0.2,
                      label: {
                        borderColor: "#B3F7CA",
                        style: {
                          fontSize: "10px",
                          color: "#fff",
                          background: this.getColor(item.type).color,
                        },
                        offsetY: -10,
                        text: this.getColor(item.type).text,
                      },
                    },
                  ],
                },
                chart: {
                  id: "vuechart-example",
                  toolbar: {
                    show: false,
                  },
                },
                stroke: {
                  width: 3,
                  curve: "smooth",
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "datetime",
                  labels: {
                    datetimeUTC: false,
                  },
                },
                yaxis: {
                  min: 0,
                  max: 220,
                  tickAmount: 5,
                },
              },
              series: [
                {
                  name: "series",
                  data: item.series.map((obj, index) => {
                    if (index < item.series.length - 1) {
                      const nextItem = item.series[index + 1];
                      const timeDiff = moment(nextItem.x).diff(moment(obj.x));
                      if (timeDiff > 30 * 60 * 1000) {
                        return {
                          x: moment(obj.x, "YYYY-MM-DD HH:mm").valueOf(),
                          y: null,
                        };
                      }
                    }
                    return {
                      x: moment(obj.x, "YYYY-MM-DD HH:mm").valueOf(),
                      y: obj.y,
                    };
                  }),
                },
              ],
            });
          });
        });
    },
  },
};
</script>

<style></style>
