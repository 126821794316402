import axios from "axios";

export async function getListReport({ commit }, { user_id }) {
  try {
    const response = await axios.get(`/manager/v2/reports/${user_id}/`);
    const data = response.data;
    commit("setListReport", data);
    return data;
  } catch (error) {
    console.error("Error in getListReport", error);
    throw error;
  }
}
// eslint
export async function getReportData({ commit }, { user_id, dates }) {
  try {
    const response = await axios.post(`/manager/v2/reports/data/${user_id}/`, {
      dates,
    });
    const data = response.data;
    commit("setReportData", data);
    return data;
  } catch (error) {
    console.error("Error in reportData:", error);
    throw error;
  }
}

export async function getSurvey({ commit }) {
  try {
    const response = await axios.get(`/manager/v2/reports/survey/options/`);
    const data = response.data;
    commit("setSurvey", data);
  } catch (error) {
    console.error("Error in getSurvey:", error);
    throw error;
  }
}

export async function createReport({ commit }, { user_id, reportData }) {
  try {
    const response = await axios.post(
      `/manager/v2/reports/create/${user_id}/`,
      reportData
    );
    if (response.status === 201) {
      commit("setCreateReport", response.data);
    }
  } catch (error) {
    console.error("Error in createReport:", error);
    throw error;
  }
}

export async function getDetail({ commit }, { report_id }) {
  try {
    const response = await axios.get(
      `/manager/v2/reports/detail/${report_id}/`
    );
    const data = response.data;
    commit("setDetailReport", data);
  } catch (error) {
    console.error("Error in getDetail:", error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
export async function updateReport({ dispatch }, { report_id, reportData }) {
  try {
    const response = await axios.put(
      `manager/v2/reports/edit/${report_id}/`,
      reportData
    );
    if (response.status === 200) {
      console.log("Updated");
    }
  } catch (error) {
    console.error("Error in updateReport:", error);
    throw error;
  }
}

export async function getPreviewReport({ commit }, { uuid }) {
  try {
    const response = await axios.get(`/manager/v2/reports/preview/${uuid}/`);
    const data = response.data;
    commit("setPreviewReport", data);
  } catch (error) {
    console.error("Error in getPreview:", error);
    throw error;
  }
}

// eslint-disable-next-line no-unused-vars
export async function deleteReport({ dispatch }, { report_id }) {
  try {
    const response = await axios.delete(
      `/manager/v2/reports/delete/${report_id}/`
    );
    return response;
  } catch (error) {
    console.error("Error in deleteReport:", error);
    throw error;
  }
}
