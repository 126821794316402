<template>
  <div id="chart">
    <apexchart
      type="line"
      height="210"
      width="745"
      :key="chartKey"
      :options="chartOptions"
      :series="mergeSeries"
    ></apexchart>
  </div>
</template>

<script>
import { previewChartOptions } from "../common.js";
import { colors } from "../../utils/const";
import moment from "moment";
export default {
  props: {
    glucoseData: {
      type: Array,
      default: () => [],
    },
    matchGlucoseData: {
      type: Array,
      default: () => [],
    },
    idealData: {
      type: Array,
      default: () => [],
    },
    highPerLower: {
      type: Number,
      default: 0,
    },
    highPerUpper: {
      type: Number,
      default: 0,
    },
    matchStart: {
      type: String,
      default: "",
    },
    matchEnd: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      chartKey: 0,
      colors: colors,
      width: 2,
      dash: 0,
      startTime: 0,
      endTime: 0,
      chartOptions: previewChartOptions(
        this.highPerLower,
        this.highPerUpper,
        0,
        0,
        0,
        2
      ),
      mergeSeries: [],
    };
  },
  methods: {
    async updateMergeSeries() {
      const transformData = (data) =>
        data.map((item) => ({
          x: this.formatDate(item.x),
          y: item.y,
        }));

      if (this.glucoseData.length > 0) {
        this.mergeSeries = this.glucoseData.map((obj) => {
          const date = obj.date;
          const data = obj.all_day.data;
          return {
            name: date,
            data: data.slice(0, -1).map((item) => {
              const { x, y } = item;
              const nextItem = data[data.indexOf(item) + 1];
              const timeDiff = moment(nextItem.x).diff(moment(x));
              if (timeDiff > 30 * 60 * 1000) {
                return {
                  x: this.formatDate(item.x),
                  y: null,
                };
              }
              return {
                x: this.formatDate(item.x),
                y,
              };
            }),
          };
        });
      } else if (
        this.matchGlucoseData.length > 0 &&
        this.idealData.length > 0
      ) {
        const matchSeries = this.matchGlucoseData.map((obj) => {
          const date = obj.date;
          const data = obj.data;
          return {
            name: date,
            data: data.slice(0, -1).map((item) => {
              const { x, y } = item;
              const nextItem = data[data.indexOf(item) + 1];
              const timeDiff = moment(nextItem.x).diff(moment(x));
              if (timeDiff > 30 * 60 * 1000) {
                return {
                  x: this.formatDate(item.x),
                  y: null,
                };
              }
              return {
                x: this.formatDate(item.x),
                y,
              };
            }),
          };
        });

        const idealSeries =
          {
            name: "Ideal",
            data: transformData(this.idealData || []),
            color: "#000000",
          } || [];

        this.mergeSeries = [...matchSeries, idealSeries];
      }
    },

    formatDate(dateStr) {
      const dateFormat =
        `${moment().format("YYYY-MM-DD")} ` + dateStr.substring(11, 16);
      return dateFormat;
    },

    handleColor(index) {
      return this.colors[index % 14];
    },
  },
  async created() {
    if (this.matchStart) {
      this.startTime = new Date(this.formatDate(this.matchStart)).getTime();
    }
    if (this.matchEnd) {
      this.endTime = new Date(this.formatDate(this.matchEnd)).getTime();
    }
  },
  mounted() {
    this.updateMergeSeries();
  },
  watch: {
    mergeSeries: {
      deep: true,
      handler(newVal) {
        const lineCount = newVal.length;
        let dashArray = [];
        if (this.idealData.length > 0) {
          if (lineCount > 1) {
            dashArray = Array(lineCount - 1).fill(0);
            dashArray.push(2);
          } else if (lineCount === 1) {
            dashArray = [2];
          }
        }

        let widthArray = [];
        let matchDate = new Date(this.matchEnd).getDate();
        for (const item of newVal) {
          let date = new Date(item.name).getDate();
          if (date == matchDate) {
            widthArray.push(5);
          } else {
            widthArray.push(2);
          }
        }

        this.width = widthArray;
        this.dash = dashArray;

        this.chartOptions = previewChartOptions(
          this.highPerLower,
          this.highPerUpper,
          this.startTime,
          this.endTime,
          this.dash,
          this.width
        );
      },
    },
    glucoseData: {
      handler: function () {
        this.chartOptions = previewChartOptions(0, 0, 0, 0, 0, 2);
        this.updateMergeSeries();
        this.chartKey += 1;
      },
      deep: true,
    },
    matchGlucoseData: {
      handler: function () {
        this.chartOptions = previewChartOptions(
          this.highPerLower,
          this.highPerUpper,
          this.startTime,
          this.endTime,
          this.dash,
          this.width
        );
        this.updateMergeSeries();
        this.chartKey += 1;
      },
      deep: true,
    },
  },
};
</script>
