<template>
  <div class="card border-none mb-5">
    <div class="row g-0 mb-5" v-for="item in reportList" :key="item.id">
      <!-- Left Section -->
      <div class="col-md-3 text-center">
        <img
          v-if="item.reported_by?.avatar"
          :src="item.reported_by?.avatar"
          class="img-fluid rounded-circle mb-3 custom-size-img custome-size"
          alt="reported by"
        />
        <img
          v-else
          src="../../assets/img/users/avatar-default.svg"
          alt="reported by"
        />
        <h6 class="fz-10 lh-14">作成者</h6>

        <span class="fw-bold title-sz-3">
          {{ item.reported_by?.full_name || 'Advisor' }}
        </span>
      </div>

      <!-- Right Section -->
      <div class="col-md-7">
        <div class="card-body">
          <div class="fz-15 fw-bold mb-3">
            {{ item.title ? item.title : `Report ${item.id}` }}
          </div>
          <div class="row g-0">
            <div class="col-md-6">
              <h5 class="fz-12 mb-2 time-info">解析期間</h5>
              <div class="fw-bold">
                {{ formattedDate(item.start_date) }} ~
                {{ formattedDate(item.end_date) }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="fz-12 mb-2 time-info">試合日</div>
              <div
                v-for="match_date in item.match_dates"
                class="fw-bold fw-bold mb-2"
                :key="match_date.start_at"
              >
                {{
                  formatDateTimeRange(match_date.start_at, match_date.end_at)
                }}
              </div>
            </div>
          </div>
          <button class="mt-4 fw-bold fz-12 color-2 cl preview-btn">
            <router-link
              :to="{ name: 'report.preview', params: { uuid: item.uuid } }"
              target="_blank"
            >
              レポート表示する
            </router-link>
          </button>
        </div>
      </div>
      <div v-if="isAdvisor" class="col-md-2 prev-styre mt-4">
        <router-link
          :to="{
            name: 'report.detail',
            params: { id: $route.params.id, reportId: item.id },
            replace,
          }"
        >
          <i class="ico-next"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/index.js';
// import { computed } from 'vue';
import { formatJapaneseDate, rootApiUrl } from '../../components/common';

export default {
  name: 'List',
  computed: {
    isAdvisor() {
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        return user.type === 'Advisor';
      }
      return false;
    },
    reportList() {
      return store.state.reportList.data;
    },
  },
  data() {
    return {
      minValue: 0,
      maxValue: 100,
      urlApi: rootApiUrl(),
    };
  },
  async created() {
    await store.dispatch('getListReport', { user_id: this.$route.params.id });
    localStorage.setItem('memberId', this.$route.params.id);
    localStorage.removeItem('peroidCover');
  },
  mounted() {
    localStorage.setItem('memberId', this.$route.params.id);
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = '../assets/img/users/avatar-default.svg';
    },

    formatDateTimeRange(start, end) {
      const startDate = formatJapaneseDate(start.slice(0, 10));
      const startTime = new Date(start).toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      });
      const endTime = new Date(end).toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      });
      return `${startDate} ${startTime} ~ ${endTime}`;
    },
    formattedDate(dataStr) {
      return formatJapaneseDate(dataStr);
    },
  },
};
</script>
<style scoped>
.time-info {
  width: fit-content;
  padding: 5px 5px;
  border-radius: 5px;
  color: #35363a;
  background-color: #f1f1f1;
}

.custome-size {
  width: 62px;
  height: 62px;
  background: transparent;
  padding: 0px;
}

.cl a {
  color: #8b172f;
}

.preview-btn {
  border: 1px solid #8b172f;
  padding: 8px 5px;
  border-radius: 5px;
}
</style>
