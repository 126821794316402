<template>
  <div
    v-click-outside="hideComment"
    class="show dropdown-menu mutiple-comment"
    style="max-width: 400px"
  >
    <div class="form-comment" v-if="!comment.id">
      <div class="form-group mutiple-comment__textarea">
        <div class="input-append-grid">
          <div class="form-group-control">
            <textarea
              class="form-control"
              v-model="commentText"
              placeholder="コメントを入力..."
            ></textarea>
          </div>
        </div>
      </div>
      <p class="mutiple-comment__title">対象グラフ</p>
      <div class="mutiple-comment__box-date">
        <div
          :key="item"
          v-for="item in datesArray"
          class="mutiple-comment__item"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="datesSelected.includes(item)"
              :value="item"
              @click="handleChangeDateSelected"
              :id="item"
            />
            <label class="form-check-label" :for="item">
              {{ item }}
            </label>
          </div>
        </div>
      </div>
      <div class="divide"></div>
      <div class="form-buttons mt-0 mutiple-comment__btn-group">
        <button
          @click="$emit('hide')"
          class="btn btn-outline-secondary"
          data-bs-dismiss="modal"
        >
          キャンセル
        </button>
        <button
          :disabled="datesSelected.length === 0 || loading"
          @click="addComment"
          class="btn btn-primary-light"
        >
          送信する
        </button>
      </div>
    </div>

    <!-- Reply Content  -->
    <div class="form-comment mt-2" v-if="comment.id" style="position: relative">
      <i
        role="button"
        class="ico-close"
        style="position: absolute; top: -15px; right: 0px"
        @click="$emit('hide')"
      ></i>

      <div class="mb-2">
        <div class="d-flex align-items-center">
          <img
            v-if="comment.comment_by?.avatar"
            :src="comment.comment_by?.avatar"
            class="ico-avatar-default"
            alt="noimg"
          />
          <i v-else class="ico-avatar-default"></i>
          <span class="ms-2 text-dark">{{
            comment.comment_by?.full_name
          }}</span>
          <span class="small ms-3" style="font-size: 10px">{{
            moment(comment.comment_at).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="ms-5 mt-2 text-secondary">
          {{ comment.comment }}
        </div>
      </div>
      <div class="mb-2" v-for="item in comment.children" :key="item.id">
        <div class="d-flex align-items-center">
          <img
            v-if="item.comment_by?.avatar"
            :src="item.comment_by?.avatar"
            class="ico-avatar-default"
            alt="noimg"
          />
          <i v-else class="ico-avatar-default"></i>
          <span class="ms-2">{{ item.comment_by?.full_name }}</span>
          <span class="small ms-3" style="font-size: 10px">{{
            item.time
          }}</span>
        </div>
        <div class="ms-5 mt-2 text-secondary">
          {{ item.comment }}
        </div>
      </div>
      <div class="form-buttons">
        <input
          v-on:keyup.enter="addReply"
          class="form-control"
          type="text"
          v-model="reply"
          placeholder="Reply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "App",
  props: ["comment", "datesArray", "loading"],
  data: function () {
    return {
      commentText: "",
      reply: "",
      avatar: "",
      datesSelected: [],
    };
  },
  created() {
    this.avatar = window.user.avatar;
  },
  methods: {
    moment(v) {
      return moment(v);
    },
    hideComment() {
      this.$emit("hide===");
    },
    addComment() {
      this.$emit("add-comment", { text: this.commentText }, this.datesSelected);
      this.commentText = "";
      this.datesSelected = [];
    },
    addReply() {
      this.$emit("add-reply", { text: this.reply });
      this.reply = "";
    },
    handleChangeDateSelected(e) {
      if (this.datesSelected.includes(e.target.value)) {
        this.datesSelected = this.datesSelected.filter(
          (item) => item !== e.target.value
        );
      } else {
        this.datesSelected.push(e.target.value);
      }
    },
  },
};
</script>

<style>
.mutiple-comment {
  width: 400px;
  padding: 15px;
}

.mutiple-comment__textarea .form-group-control {
  width: 100%;
  margin-bottom: 20px;
}

.mutiple-comment__textarea textarea {
  height: 120px;
}

.mutiple-comment__box-date {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px 10px;
}

.mutiple-comment__item {
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.mutiple-comment__item .form-check {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mutiple-comment__item label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
  color: #35363a;
}

.mutiple-comment__item input {
  margin-bottom: 5px;
}

.mutiple-comment__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #35363a;
}

.divide {
  border: 1px solid #f1f1f1;
  margin: 15px 0px;
}

.mutiple-comment__btn-group button {
  width: 100px;
  max-width: 100px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}

.mutiple-comment__btn-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
