import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VueRouter from 'vue-router';
import App from './App.vue';
import Feed from './Feed.vue';
// import Member from "./Member.vue";
import Detail from './Detail.vue';
// import MemberSleep from "./MemberSleep.vue";
import Login from './Login.vue';
import Verify from './Verify.vue';
// import ResetPassword from './ResetPassword.vue';
import Axios from 'axios';
import VueTimepicker from 'vue2-timepicker';
import Toasted from 'vue-toasted';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Create from './views/Report/Create.vue';
import ReportDetail from './views/Report/ReportDetail.vue';
import Preview from './views/Report/Preview.vue';
import store from './store/index.js';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTimepicker);

Vue.prototype.$http = Axios;

Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_ROOT_API;

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] =
    'Token ' + token;
}

Vue.use(VueRouter);
Vue.use(VueApexCharts);
Vue.use(Toasted);

Vue.component('apexchart', VueApexCharts);
Vue.config.productionTip = false;

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (err) {
    return null;
  }
};

const isAdvisor = () => {
  const user = getUser();
  return user && user.type === 'Advisor';
};

const isManagerAndViewOnly = () => {
  const user = getUser();
  return user && user.type === 'Manager' && user.view_only === true;
};

const routes = [
  { path: '/', component: Feed, name: 'feed', meta: { requiresAuth: true } },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: { requiresAuth: false },
  },
  {
    path: '/verify',
    component: Verify,
    name: 'verify',
    meta: { requiresAuth: false },
  },
  // {
  //   path: '/reset_pass',
  //   component: ResetPassword,
  //   name: 'reset_pass',
  //   meta: { requiresAuth: false },
  // },
  {
    path: '/members/:id',
    component: Detail,
    name: 'detail',
    meta: { requiresAuth: true },
    props: (route) => ({
      id: route.params.id,
    }),
    beforeEnter: (to, from, next) => {
      if (!isManagerAndViewOnly() || isAdvisor()) {
        if (to.query.mode && (to.query.mode === '1' || to.query.mode === '2')) {
          next();
        } else {
          next({ name: 'detail', params: to.params, query: { mode: '1' } });
        }
      } else {
        if (to.query.mode && to.query.mode !== '1') {
          next({ name: 'detail', params: to.params, query: { mode: '1' } });
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/members/:id/reports',
    component: Create,
    name: 'report.create',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (isAdvisor()) {
        next();
      } else {
        next({ name: 'detail', params: to.params, query: { mode: '2' } });
      }
    },
  },
  {
    path: '/members/:id/reports/:reportId/detail',
    component: ReportDetail,
    name: 'report.detail',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (isAdvisor()) {
        next();
      } else {
        next({ name: 'detail', params: to.params, query: { mode: '2' } });
      }
    },
  },

  {
    path: '/reports/:uuid/preview',
    component: Preview,
    name: 'report.preview',
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  routes, // short for `routes: routes`,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (token) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }

  const username = localStorage.getItem('username');
  if (to.name === 'verify' && !username) {
    next('login');
  } else if (to.name === 'login') {
    const token = localStorage.getItem('token');
    if (token) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
