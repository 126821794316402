<template>
  <div class="modal-wrapper">
    <div class="modal-overlay" @click="$emit('hide')"></div>
    <div class="show account-modal">
      <div class="upload-avatar account-modal__avatar">
        <img
          v-if="user?.avatar || avatarPreview"
          :src="avatarPreview ? avatarPreview : user?.avatar"
          alt="noimg"
        />
        <img v-else src="../assets/img/users/avatar-default.svg" alt="" />
        <input type="file" accept="image/*" @change="previewFiles" />
      </div>
      <p class="account-modal__change-text">プロフィール画像の変更</p>
      <form>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">名前</label>
          <input type="text" class="form-control" v-model="full_name" />
        </div>
        <div class="d-flex justify-content-center mt-4">
          <button type="button" @click="save" class="btn btn-primary">
            保存する
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "EditAccount",
  props: ["user"],
  data: function () {
    return {
      full_name: "",
      avatar: "",
      avatarPreview: "",
    };
  },
  created: function () {
    this.full_name = this.user.full_name;
  },
  methods: {
    save() {
      let data = {};
      if (this.full_name) {
        data["full_name"] = this.full_name;
      }
      if (this.avatar) {
        data["avatar"] = this.avatar;
      }
      axios
        .patch("/manager/v2/profile/", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$emit("update-profile", res.data);
          this.$emit("hide");
          this.$toasted.show("保存しました！", {
            theme: "bubble",
            position: "top-right",
            duration: 2000,
          });
        });
    },
    previewFiles(e) {
      let file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        e.preventDefault();
        this.$toasted.error("ファイルが大きすぎます (> 1MB)", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
        return;
      }
      this.avatar = file;
      this.avatarPreview = window.URL.createObjectURL(file);
    },
  },
};
</script>

<style>
.account-modal .upload-avatar {
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 15px;
}

.upload-avatar input[type="file"] {
  cursor: pointer;
}

.account-modal__avatar img {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
}

.account-modal__change-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8b172f;
  margin-bottom: 15px;
}

.account-modal form {
  width: 100%;
}

.account-modal form input {
  font-size: 14px;
  line-height: 12px;
  padding: 12px 10px;
}

.account-modal form label {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.account-modal button {
  background: #8b172f;
  border-radius: 5px;
  padding: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #ffffff;
  border: 0;
}

.account-modal button:hover {
  background: #8b172f;
  opacity: 0.8;
}

.account-modal button:focus {
  background: #8b172f;
}
</style>
