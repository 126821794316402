<template>
  <b-modal
    ref="modal"
    @ok="handleOk"
    :id="modalId"
    :hide-footer="true"
    :title="eventTitle"
  >
    <form @submit.prevent="validateForm" class="d-flex flex-column">
      <div class="row mb-3">
        <label class="mb-2"
          >追加するイベントを選択してください
          <span style="color: red">*</span></label
        >
        <div class="radio-grid d-flex">
          <div class="radio-box" v-for="(type, index) in types" :key="index">
            <input
              :class="type.class"
              type="radio"
              name="radio-option"
              :id="`radio${type.value}`"
              :value="type.value"
              v-model="formData.type"
            />
            <label :for="`radio${type.value}`">
              <i :class="type.iconClass"></i>
              <span class="mt-1 ms-2" :style="{ color: type.color }">{{
                type.label
              }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label class="mb-2"
          >カテゴリを選択してくだい <span style="color: red">*</span></label
        >
        <div class="radio-grid d-flex" v-if="formData.type">
          <div
            class="radio-box"
            v-for="(subtype, index) in getSubtypes(formData.type)"
            :key="index"
          >
            <input
              :class="subtype.class"
              type="radio"
              name="sub-radio-option"
              :id="`radioSub${subtype.value}`"
              :value="subtype.value"
              v-model="formData.subtype"
            />
            <label :for="`radioSub${subtype.value}`">
              <i :class="subtype.iconClass"></i>
              <span class="mt-1 ms-2" :style="{ color: subtype.color }">{{
                subtype.label
              }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <label class="mb-2"
          >カテゴリを選択してくだい <span style="color: red">*</span>
        </label>
        <div class="d-flex p-3 align-items-center">
          <div class="me-auto fw-bold">開始</div>
          <div class="d-flex" style="gap: 5px; flex-wrap: nowrap">
            <input
              v-model="startDate"
              class="form-control form-control-sm text-secondary"
              type="date"
              :disabled="date && formData.type != 1"
              style="flex: 1"
            />
            <vue-timepicker
              v-model="startTime"
              ref="timepicker"
              input-class="form-control form-control-sm bg-white"
              :minute-interval="15"
              hour-label="时"
              minute-label="分"
            />
          </div>
        </div>
        <div v-if="startAtError" class="d-flex text-danger justify-content-end">
          開始時間は終了時間よりも前である必要あります。
        </div>
        <div v-if="fieldError" class="d-flex text-danger justify-content-end">
          このフィールドは必須です
        </div>
        <div class="d-flex p-3 align-items-center" v-if="formData.type != 2">
          <div class="me-auto fw-bold">終了</div>
          <div class="d-flex" style="gap: 5px; flex-wrap: nowrap">
            <input
              v-model="endDate"
              class="form-control form-control-sm text-secondary"
              type="date"
              :disabled="date"
            />
            <vue-timepicker
              v-model="endTime"
              input-class="form-control form-control-sm bg-white"
              :minute-interval="15"
              hour-label="时"
              minute-label="分"
            />
          </div>
        </div>
        <div
          v-if="fieldError && formData.type != 2"
          class="d-flex text-danger justify-content-end"
        >
          このフィールドは必須です
        </div>
      </div>
      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="outline-secondary" @click="handleClose()">
          キャンセル
        </b-button>
        <b-button
          type="submit"
          class="btn btn-primary-custom ms-3"
          @click="handleOk()"
        >
          保存
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { types, subtypes } from "../utils/const";

export default {
  props: {
    eventTitle: String,
    modalId: String,
    eventData: Object,
    datesArray: {
      type: Array,
      required: false,
    },
    dateSelected: {
      type: String,
      required: false,
    },
  },
  components: {
    VueTimepicker,
  },
  computed: {
    combinedStartDatetime() {
      if (this.startDate && this.startTime) {
        const startAt = moment(this.startDate);
        const timeParts = this.startTime.split(":");
        startAt.hours(parseInt(timeParts[0]));
        startAt.minutes(parseInt(timeParts[1]));
        startAt.seconds(0);

        const formattedStartAt = startAt.format("YYYY-MM-DDTHH:mm:ss");

        return formattedStartAt;
      }
      return null;
    },
    combinedEndDatetime() {
      if (this.endDate && this.endTime) {
        const endAt = moment(this.endDate);
        const timeParts = this.endTime.split(":");
        endAt.hours(parseInt(timeParts[0]));
        endAt.minutes(parseInt(timeParts[1]));
        endAt.seconds(0);

        const formattedEndAt = endAt.format("YYYY-MM-DDTHH:mm:ss");

        return formattedEndAt;
      }
      return null;
    },
  },
  data() {
    return {
      types: types,
      subtypes: subtypes,
      defaultSubtypes: {
        1: 1,
        2: 2,
        3: 6,
      },
      formData: {
        user: "",
        type: 2,
        subtype: 2,
        start_at: null,
        end_at: null,
      },
      date: this.dateSelected || "",
      initialType: 2,
      startAtError: false,
      fieldError: false,
      startDate: this.dateSelected || "",
      startTime: "",
      endDate: this.dateSelected || "",
      endTime: "",
    };
  },
  methods: {
    getSubtypes(type) {
      return this.subtypes.filter((subtype) => subtype.type === type);
    },
    validateForm() {
      const startAt = moment(this.formData.start_at);
      let endAt;
      if (this.formData.type === 2) {
        endAt = moment(startAt).add(30, "minutes");
      } else {
        endAt = moment(this.formData.end_at);
      }

      if (this.formData.type === 2) {
        if (!startAt.isValid()) {
          console.error("Start time is required.");
          this.fieldError = true;
          return false;
        }
        this.fieldError = false;
      } else {
        if (!startAt.isValid() || !endAt.isValid()) {
          console.error("Start and end times are required.");
          this.fieldError = true;
          return false;
        }

        if (startAt >= endAt) {
          console.error("Start time must be before end time.");
          this.startAtError = true;
          return false;
        }

        this.startAtError = false;
        this.fieldError = false;
      }

      this.formData.start_at = startAt.format("YYYY-MM-DDTHH:mm:ss");
      this.formData.end_at = endAt.format("YYYY-MM-DDTHH:mm:ss");

      return true;
    },

    async handleOk() {
      if (this.validateForm()) {
        try {
          const eventData = {
            user: this.$route.params.id,
            type: this.formData.type,
            subtype: this.formData.subtype,
            start_at: this.formData.start_at,
            end_at: this.formData.end_at,
          };

          const response = this.eventData
            ? await axios.put(
                `/manager/v2/events/${this.eventData.id}/`,
                eventData
              )
            : await axios.post(`/manager/v2/events/`, eventData);

          if (response.status === 201) {
            this.$emit("add-event", response.data);
            this.handleClose();
          } else if (response.status === 200) {
            this.$emit("edit-event", {
              eventData: response.data,
              oldType: this.initialType,
            });
            this.handleClose();
          } else {
            console.error(
              `Error ${this.eventData ? "editing" : "creating"} event:`,
              response.data
            );
          }
        } catch (error) {
          console.error(
            `Error ${this.eventData ? "editing" : "creating"} event:`,
            error
          );
        }

        this.$refs.modal.hide();
      }
    },

    handleClose() {
      this.$refs.modal.hide();

      if (!this.eventData) {
        this.formData = {
          user: "",
          type: 1,
          start_at: "",
          end_at: "",
        };
        this.endTime = "";
        this.startTime = "";
        this.startAtError = false;
        this.fieldError = false;
      }
    },
  },
  created() {
    if (this.eventData) {
      this.formData.user = this.eventData.user;
      this.formData.type = this.eventData.type;
      this.formData.subtype = this.eventData.subtype;
      this.formData.start_at = this.eventData.start_at;
      this.formData.end_at = this.eventData.end_at;
      this.date = this.eventData.end_at.split("T")[0];
      this.startDate = this.eventData.start_at.split("T")[0];
      this.startTime = this.eventData.start_at.split("T")[1];
      this.endDate = this.eventData.end_at.split("T")[0];
      this.endTime = this.eventData.end_at.split("T")[1];
      this.initialType = this.eventData.type;
    }
  },
  watch: {
    combinedStartDatetime(newValue) {
      this.formData.start_at = newValue;
    },
    combinedEndDatetime(newValue) {
      this.formData.end_at = newValue;
    },
    eventData: {
      handler(newEventData) {
        if (newEventData) {
          this.formData.user = newEventData.user;
          this.formData.type = newEventData.type;
          this.formData.start_at = newEventData.start_at;
          this.formData.end_at = newEventData.end_at;
          this.date = this.eventData.end_at.split("T")[0];
          this.startDate = newEventData.start_at.split("T")[0];
          this.startTime = newEventData.start_at.split("T")[1];
          this.endDate = newEventData.end_at.split("T")[0];
          this.endTime = newEventData.end_at.split("T")[1];
          this.initialType = newEventData.type;
        }
      },
      immediate: true,
    },
    "formData.type": {
      handler(newType) {
        if (newType) {
          this.formData.subtype = this.defaultSubtypes[newType];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #0d6efd !important;
  color: white !important;
}

.radio-grid {
  display: grid;
  grid-template-columns: repeat(4, 100%);
  grid-gap: 10px;
  margin-bottom: 10px;
}

.radio-box {
  width: 25%;
  height: 100%;
  border-radius: 8px;
  background: rgba(241, 241, 241, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio-box input[type="radio"] {
  display: none;
}

.radio-box input#radio1:checked + label,
.radio-box input#radioSub1:checked + label {
  border: 2px solid #18698c;
}

.radio-box input#radio2:checked + label,
.radio-box input#radioSub2:checked + label,
.radio-box input#radioSub3:checked + label,
.radio-box input#radioSub4:checked + label,
.radio-box input#radioSub5:checked + label {
  border: 2px solid #8c7518;
}

.radio-box input#radio3:checked + label,
.radio-box input#radioSub6:checked + label,
.radio-box input#radioSub7:checked + label,
.radio-box input#radioSub8:checked + label {
  border: 2px solid #75188c;
}

.radio-box label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  font-weight: bold;
}

.radio-box label {
  padding: 8px;
  text-align: center;
}

.radio-box label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  font-weight: bold;
}

.radio-box label {
  padding: 8px;
  text-align: center;
}
</style>
