<template>
  <div class="container">
    <div class="login">
      <div class="login-form">
        <div class="logo text-center">
          <img
            class="img-fluid"
            src="./assets/img/logo.svg"
            alt="Sympafit"
            width="165"
            height="40"
          />
        </div>
        <div class="form">
          <div class="form-group input-append">
            <span class="input-append-icon">
              <i class="ico-envelop"></i>
            </span>
            <input
              v-model="username"
              class="form-control"
              :class="{ error: showError }"
              type="text"
              placeholder="会員ID or メールアドレス"
              name=""
            />
          </div>
          <div class="form-group input-append">
            <span class="input-append-icon">
              <i class="ico-key"></i>
            </span>
            <input
              v-model="password"
              class="form-control"
              type="password"
              placeholder="パスワード"
              name=""
            />
          </div>
          <!-- <div class="forgot-text">
            <router-link to="/reset_pass"> パスワードを忘れた場合 </router-link>
          </div> -->
          <div class="mt-3 text-danger" v-show="showError">
            ユーザー名やパスワードが謝ります。<br />再度入力してください。
          </div>
          <button
            @click="doLogin"
            class="mt-4 btn btn-secondary w-100 btn-sz-lg"
            type="button"
          >
            ログイン
          </button>
        </div>
        <div class="register-text d-none">
          <a href="#">新規登録はこちらから</a>
        </div>
      </div>
      <!-- .login-form-->
      <div class="copyright">
        © Nisshinbo Holdings Inc. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    // HelloWorld
  },

  data: function () {
    return {
      username: '',
      password: '',
      showError: false,
    };
  },

  methods: {
    doLogin() {
      delete axios.defaults.headers.common['Authorization'];
      axios
        .post('/manager/v2/auth/login/', {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          if (res.data.token) {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.user));
            axios.defaults.headers.common['Authorization'] =
              'Token ' + res.data.token;
            this.$router.push('/');
          } else {
            localStorage.setItem('username', this.username);
            this.$router.push('/verify');
          }
        })
        .catch(() => {
          this.showError = true;
        });
    },
  },
};
</script>

<style scoped>
input[type='text'].error {
  box-shadow: 0 0 0.5em 0 red;
}
</style>
