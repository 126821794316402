<template>
  <div>
    <apexchart
      :key="chartKey"
      type="boxPlot"
      height="350"
      :options="chartOptionsColumn"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import { chartOptionsColumn } from "../common.js";
import store from "../../store/index.js";
export default {
  props: {
    boxPlotUpper: {
      type: String,
      default: "#ff0000",
    },
    checkProp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartData() {
      return store.state.reportData.data?.daily;
    },
    series: {
      get() {
        if (this.chartData) {
          return [
            {
              type: "boxPlot",
              data: [...this.chartData].map((item) => {
                const ydata = this.checkProp
                  ? [
                      item.series.min,
                      Math.round(item.series.avg),
                      0,
                      Math.round(item.series.avg),
                      item.series.max,
                    ]
                  : [
                      item.sleep.min,
                      Math.round(item.sleep.avg),
                      0,
                      Math.round(item.sleep.avg),
                      item.sleep.max,
                    ];
                return {
                  x: item.date,
                  y: ydata,
                };
              }),
            },
          ];
        } else {
          return [];
        }
      },
      set() {},
    },
  },
  data() {
    return {
      chartKey: 0,
      chartOptionsColumn: chartOptionsColumn(this.boxPlotUpper, ""),
      default: false,
    };
  },
  created() {
    this.checkPropOption();
  },
  methods: {
    checkPropOption() {
      if (this.checkProp) {
        this.chartOptionsColumn = chartOptionsColumn(
          this.boxPlotUpper,
          "Interstitial glucose level / mg/dL"
        );
      } else {
        this.chartOptionsColumn = chartOptionsColumn(
          this.boxPlotUpper,
          "Interstitial glucose level in sleep / mg/dL"
        );
      }
    },
    updateMergeSeries() {
      this.series = [
        {
          type: "boxPlot",
          data: Object.values(this.chartData).map((item) => {
            const ydata = this.checkProp
              ? [
                  item.series.min,
                  item.series.avg,
                  0,
                  item.series.avg,
                  item.series.max,
                ]
              : [
                  item.sleep.min,
                  item.sleep.avg,
                  0,
                  item.sleep.avg,
                  item.sleep.max,
                ];
            return {
              x: item.date,
              y: ydata,
            };
          }),
        },
      ];
    },
  },
  mounted() {
    if (this.chartData) {
      this.updateMergeSeries();
    }
  },
  watch: {
    chartData: {
      handler: function () {
        this.updateMergeSeries();
        this.chartKey += 1;
      },
      deep: true,
    },
  },
};
</script>
