import dayjs from "dayjs";
// import moment from "moment";
//Format date to YYYY年YY月DD日
export function formatJapaneseDate(dateString) {
  const dateParts = dateString.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  const japaneseDate = `${year}年${month}月${day}日`;
  return japaneseDate;
}

// Url root api https://api.sympafit.com
export function rootApiUrl() {
  return process.env.VUE_APP_ROOT_API;
}

// Options Line chart
// export const chartOptions = (propy, propy2, xprop, xprop2) => ({
export const chartOptions = (propy, propy2, propx, propx2, dash, width) => ({
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: width,
    curve: "smooth",
    dashArray: dash,
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "right",
    fontSize: "11px",
    markers: {
      width: 13,
      height: 3,
      radius: 0,
    },
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"],
      opacity: 0.5,
    },
  },
  yaxis: {
    min: 0,
    max: (max) => {
      return max + 50;
    },
    forceNiceScale: true,
    title: {
      text: "Interstitial glucose level mg/dL",
    },
  },
  xaxis: {
    type: "datetime",
    fillColor: "#B3F7CA",
    opacity: 0.4,
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: "",
        month: "",
        day: "00:00",
        hour: "HH:mm",
        minute: "mm",
        second: "ss",
      },
    },
    title: {
      text: "time",
    },
  },
  annotations: {
    yaxis: [
      {
        y: propy,
        y2: propy2,
        borderColor: "transparent",
        fillColor: "#f5cca9",
      },
    ],
    xaxis: [
      {
        x: propx,
        x2: propx2,
        fillColor: "#CBDBEC",
        opacity: 0.4,
        label: {
          borderColor: "#CBDBEC",
          style: {
            fontSize: "10px",
            color: "#fff",
            background: "#CBDBEC",
          },
        },
      },
    ],
    points: [],
  },
});

// Options Line chart preview
export const previewChartOptions = (
  propy,
  propy2,
  propx,
  propx2,
  dash,
  width
) => ({
  chart: {
    type: "line",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: width,
    curve: "smooth",
    dashArray: dash,
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "right",
    fontSize: "11px",
    markers: {
      width: 13,
      height: 3,
      radius: 0,
    },
    offsetX: 0.5,
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"],
      opacity: 0.5,
    },
  },
  yaxis: {
    min: (min) => {
      return Math.floor(min / 10) * 10;
    },
    max: (max) => {
      return Math.ceil(max / 50) * 50;
    },
    forceNiceScale: true,
    title: {
      text: "Interstitial glucose level mg/dL",
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeUTC: false,
      datetimeFormatter: {
        year: "",
        month: "",
        day: "00:00",
        hour: "HH:mm",
        minute: "mm",
        second: "ss",
      },
    },
    title: {
      text: "time",
    },
  },
  annotations: {
    yaxis: [
      {
        y: propy,
        y2: propy2,
        borderColor: "transparent",
        fillColor: "#f5cca9",
      },
    ],
    xaxis: [
      {
        x: propx,
        x2: propx2,
        fillColor: "#CBDBEC",
        opacity: 0.4,
        label: {
          borderColor: "#CBDBEC",
          style: {
            fontSize: "10px",
            color: "#fff",
            background: "#CBDBEC",
          },
        },
      },
    ],
    points: [],
  },
});

// Options Column chart
export const chartOptionsColumn = (boxPlotUpper, title) => ({
  chart: {
    type: "boxPlot",
    height: 350,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  yaxis: {
    min: 0,
    max: (max) => {
      return max;
    },
    forceNiceScale: true,
    title: {
      text: title,
      offsetY: 25,
      style: {
        fontSize: "10px",
      },
    },
  },
  xaxis: {
    type: "date",
    labels: {
      formatter: function (value) {
        var date = new Date(value);
        var month = date.getMonth() + 1; // Month starts from 0
        var day = date.getDate();
        return month + "/" + day;
      },
    },
    title: {
      text: "date",
    },
  },
  plotOptions: {
    boxPlot: {
      colors: {
        upper: "transparent",
        lower: boxPlotUpper,
      },
    },
    bar: {
      columnWidth: "30%",
    },
  },
});

export function getRecentDates() {
  let initDates = [];
  for (let i = 3; i >= 0; i--) {
    const date = dayjs().subtract(i, "day").format("YYYY-MM-DD");
    initDates.push(date);
  }
  return initDates;
}

export function removeArray(array, flatArray) {
  if (!Array.isArray(array)) return null;

  return array
    .map((item) => {
      if (typeof item !== "string") return null;

      const parts = item.split("+");
      return parts.length === 2
        ? flatArray
          ? parts[1]
          : parseInt(parts[0])
        : null;
    })
    .filter((item) => item !== null);
}

export function getValueBeforePlus(str, flat) {
  if (typeof str === "string" && str.trim() !== "") {
    const parts = str.split("+");
    const value = flat ? parts[0].trim() : parts[1].trim();
    return value;
  } else {
    console.error("Check input data");
    return "";
  }
}

export function hanlderPropress(condition1, condition2, step) {
  if (condition1 && !step) {
    const date = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", date + 1);
    step = true;
  }
  if (condition2 && step) {
    const date = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", date - 1);
    step = false;
  }
}

export const itemsToRemove = [
  "highPerformance",
  "peroidCover",
  "selectedAccuracy",
  "periodComment_data",
  "averageGlucoseCommentData",
  "averageSleepGlucoseCommentData",
  "surveyResultData",
  "analyzeResultData",
  "noteHistory",
];

export function updateProgress(detailList) {
  if (detailList?.title !== "" && detailList?.title !== null) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step0 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (detailList?.dates.length > 0) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step1 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (detailList?.hpz_lower) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step2 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (detailList?.accuracy > 0) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step3 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (
    detailList?.overall_comment !== "" &&
    detailList?.overall_comment !== null
  ) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step4 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (
    detailList?.avg_glucose_comment != "" &&
    detailList?.avg_glucose_comment !== null
  ) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step5 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (
    detailList?.avg_sleep_glucose_comment !== "" &&
    detailList?.avg_sleep_glucose_comment !== null
  ) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step6 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }

  if (detailList?.match_reports.length > 0) {
    const data = parseInt(localStorage.getItem("progress"));
    localStorage.setItem("progress", Math.min(data + 1, 8));
    this.step7 = true;
    this.progressPer(parseInt(localStorage.getItem("progress")));
  }
}

export function processDetailList(detailList) {
  //if (detailList) {
  this.minperoidCover = detailList?.min_date;
  this.maxperoidCover = detailList?.max_date;
  const dates = detailList?.dates;
  const formattedArray = dates.map((item) => item.date.trim());
  this.datesSelectInCalendar = formattedArray;

  this.lower = detailList?.hpz_lower;
  this.upper = detailList?.hpz_upper;
  localStorage.setItem(
    "highPerformance",
    JSON.stringify({ lower: this.lower, upper: this.upper })
  );
  this.analysisAccuracy = detailList?.accuracy;
  localStorage.setItem("selectedAccuracy", detailList?.accuracy);

  this.periodComment = detailList?.comment;
  this.averageGlucoseComment = detailList?.avg_glucose_comment;
  this.sleepAverageComment = detailList?.avg_sleep_glucose_comment;
  this.analysisComment = detailList?.match_result_comment;

  this.matchResult = detailList.match_result ? detailList.match_result : "";
  localStorage.setItem("matchResult", this.matchResult);

  // getValueBeforePlus set to api id value
  this.selectedOptions_1 = detailList?.sleep_state_before_match.title;
  this.selectedOptions_2 = detailList?.sleep_state_the_day_before_match.title;
  // this.selectedOptions_2 = detailList.sleep_state_the_day_before_match.value+'+'+detailList.sleep_state_the_day_before_match.title

  this.selectedOptions_3 = detailList?.sleep_state_after_match.title;
  this.selectedOptions_4 = detailList?.mood_before_match
    .map((item) => item.id)
    .join(" ");
  this.selectedOptions_5 = detailList?.mood_during_match
    .map((item) => item.title)
    .join(" ");

  this.selectedOptions_6 = detailList?.physical_state_before_match.title;
  this.selectedOptions_7 = detailList?.today_match_result.title;
  localStorage.setItem(
    "surveyResultData",
    JSON.stringify({
      selectedOptions1:
        detailList.sleep_state_before_match.value +
        "+" +
        detailList.sleep_state_before_match.title,
      selectedOptions2:
        detailList.sleep_state_the_day_before_match.value +
        "+" +
        detailList.sleep_state_the_day_before_match.title,
      selectedOptions3:
        detailList.sleep_state_after_match.value +
        "+" +
        detailList.sleep_state_after_match.title,
      selectedOptions4: detailList?.mood_before_match.map(
        (item) => item.id + "+" + item.title
      ),
      selectedOptions5: detailList?.mood_during_match.map(
        (item) => item.id + "+" + item.title
      ),
      selectedOptions6:
        detailList.physical_state_before_match.value +
        "+" +
        detailList.physical_state_before_match.title,
      selectedOptions7:
        detailList.today_match_result.value +
        "+" +
        detailList.today_match_result.title,
    })
  );

  this.selectedAnalyzeOptions_1 =
    detailList?.sleep_state_before_match_analyis.title;
  this.selectedAnalyzeOptions_2 =
    detailList?.sleep_state_after_match_analyis.title;
  this.selectedAnalyzeOptions_3 = detailList?.match_result_analysis.title;
  localStorage.setItem(
    "analyzeResultData",
    JSON.stringify({
      selectedOptions1:
        detailList.sleep_state_before_match_analyis.value +
        "+" +
        detailList.sleep_state_before_match_analyis.title,
      selectedOptions2:
        detailList.sleep_state_after_match_analyis.value +
        "+" +
        detailList.sleep_state_after_match_analyis.title,
      selectedOptions3:
        detailList.match_result_analysis.value +
        "+" +
        detailList.match_result_analysis.title,
    })
  );
}
//}
