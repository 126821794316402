<template>
  <div class="wrapper-content">
    <div class="header-site d-flex justify-content-between">
      <div class="tabs">
        <ul class="nav nav-tabs-horizontal" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              :class="{ active: activeMode === 1 }"
              @click="changeMode(1)"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              生活ストレスデータ
            </button>
          </li>
          <li v-if="!isManagerAndViewOnly" class="nav-item" role="presentation">
            <button
              class="nav-link"
              :class="{ active: activeMode == 2 }"
              @click="changeMode(2)"
              id="report-tab"
              data-bs-toggle="tab"
              data-bs-target="#report-tab-pane"
              type="button"
              role="tab"
              aria-controls="report-tab-pane"
              aria-selected="false"
            >
              レポート
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-content" id="main">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabindex="0"
        >
          <div class="filters-grid">
            <template v-if="mode == 1">
              <combine-chart
                v-if="records.length > 1"
                :series="mergeSeries"
                :dates-array="datesArray"
                :show-comment="true"
                @refresh-data="getData"
                :series-display="seriesDisplay"
                :comment-combine-chart="commentCombineChart"
                ref="refCombineChart"
              />
              <h5 class="mb-0 mt-3 fw-bold">睡眠データ</h5>
              <sleep-chart
                :series="processedSleepData"
                :series-display="seriesDisplay"
              />
              <br />
              <div
                v-for="item in records.slice(0, visibleCharts)"
                :key="`${$route.params.id}_${mode}_${item.date}`"
              >
                <activity-chart
                  ref="activityChart"
                  :record="item"
                  :show-meal="showMeal"
                  :show-sleep="showSleep"
                  :show-activity="showActivity"
                  :show-comment="true"
                  :show-mood="true"
                  :syncCommentToCombineChart="syncCommentToCombineChart"
                />
              </div>
            </template>
            <template v-else>
              <Report :key="`${$route.params.id}_${mode}`" />
            </template>
          </div>
          <div
            id="comment-list"
            :style="{ display: activeMode === 2 ? 'none' : 'block' }"
          >
            <div class="sidebar-action">
              <p
                :class="this.actionTabActive === 'filterDate' ? 'active' : ''"
                @click="changeActionTab('filterDate')"
              >
                表示日程
              </p>
              <p
                :class="this.actionTabActive === 'readComment' ? 'active' : ''"
                @click="changeActionTab('readComment')"
              >
                コメント
              </p>
            </div>
            <div v-if="this.actionTabActive === 'readComment'">
              <div class="comment__list">
                <div
                  class="item"
                  @click="openComment(item)"
                  v-for="item in comments"
                  :key="item.id"
                >
                  <div class="d-flex align-items-center">
                    <img
                      v-if="item.comment_by.avatar"
                      :src="item.comment_by.avatar"
                      class="ico-avatar-default"
                      alt="noimg"
                    />
                    <i v-else class="ico-avatar-default"></i>
                    <span class="ms-2">{{ item.comment_by?.full_name }}</span>
                  </div>
                  <div class="color-light2 my-2"># {{ item.comment_at }}</div>
                  <div>{{ item.comment }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="filter-date">
                <calendar
                  :dates="datesSelectInCalendar"
                  @toggle="toggleCalendar"
                  @filter-chart="onChangeCalendar"
                  v-if="isShowCalendar"
                />
                <button
                  @click="toggleCalendar"
                  class="btn btn-primary-light btn-select-date"
                >
                  日程を選択
                </button>
                <p class="filter-date__title">選択中の日程</p>
                <div class="filter-date__list">
                  <div
                    :key="item.date"
                    v-for="item in recordsCombineChart"
                    class="filter-date__item"
                  >
                    <div class="form-check d-flex">
                      <input
                        v-bind:style="{
                          backgroundColor: handleColor(item.date),
                          borderColor: handleColor(item.date),
                        }"
                        class="form-check-input"
                        type="checkbox"
                        :value="item.date"
                        @click="handleChangeSeriesDisplay"
                        :id="item.date"
                        :checked="seriesDisplay.includes(item.date)"
                      />
                      <label class="form-check-label" :for="item.date">
                        {{ item.date }} ({{ getWeekday(item.date) }})
                      </label>
                    </div>
                    <div class="filter-date__icon-group">
                      <img
                        src="./assets/img/icons/match-icon.svg"
                        v-if="item.has_match"
                        alt="no-img"
                        class="filter-date__icon"
                      />
                      <img
                        src="./assets/img/icons/workout-icon.svg"
                        v-if="item.has_practice"
                        alt="no-img"
                        class="filter-date__icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CombineChart from './components/CombineChart.vue';
import ActivityChart from './components/ActivityChart.vue';
import SleepChart from './components/SleepChart.vue';
import Calendar from './components/Calendar.vue';
import { colors, weekdayColors } from './utils/const';
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Report from './views/Report/index.vue';

export default {
  name: 'App',
  components: {
    'activity-chart': ActivityChart,
    CombineChart,
    SleepChart,
    Calendar,
    Report,
  },
  data: function () {
    const initDates = Array.from({ length: 4 }, (_, i) =>
      dayjs().subtract(i, 'day').format('YYYY-MM-DD')
    );

    return {
      actionTabActive: 'filterDate',
      isShowCalendar: false,
      mode: 1,
      mouseX: 0,
      mouseY: 0,
      showMeal: true,
      showSleep: true,
      showActivity: true,
      visibleCharts: 1,

      commentCombineChart: [],
      comments: [],
      datesArray: [],
      datesSelectInCalendar: initDates,
      records: [],
      recordsCombineChart: [],
      seriesDisplay: [],

      mergeSeries: undefined,
      originalSleepData: undefined,
      processedSleepData: undefined,
      user: null,

      colors: colors,
      increment: 1,
      selectedMember: 1,
      weekdayColors: weekdayColors,

      dateRange: {
        startDate: dayjs().subtract(3, 'day'),
        endDate: dayjs(),
      },
    };
  },
  filters: {
    date(val) {
      let d = val ? dayjs(val).format('YYYY-MM-DD') : '';
      return d;
    },
  },

  computed: {
    isManagerAndViewOnly() {
      return this.user?.type === 'Manager';
    },
    activeMode() {
      return parseInt(this.mode);
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  async created() {
    try {
      this.user = JSON.parse(localStorage.getItem('user'));
    } catch (err) {
      this.user = false;
    }

    const currentQuery = { ...this.$route.query };

    const updateQuery = (key, value) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!currentQuery.hasOwnProperty(key)) {
        currentQuery[key] = value;
        this.$router.replace({ query: currentQuery });
      }
    };

    // eslint-disable-next-line no-prototype-builtins
    if (!currentQuery.hasOwnProperty('dates')) {
      updateQuery('dates', this.datesSelectInCalendar.join(','));
    } else {
      const datesArray = currentQuery.dates.split(',');
      this.datesSelectInCalendar = datesArray.filter(
        (dateString, index, self) => {
          const date = new Date(dateString);
          const isValidDate = !isNaN(date.getTime());
          const isUnique = isValidDate && self.indexOf(dateString) === index;
          return isValidDate && isUnique;
        }
      );
      this.getData();
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!currentQuery.hasOwnProperty('mode')) {
      updateQuery('mode', this.mode);
    } else {
      this.mode = currentQuery.mode;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.isShowCalendar = false;
      this.recordsCombineChart = [];
      this.commentCombineChart = [];
      this.datesArray = [];
      this.getData();
    },
    seriesDisplay() {
      let oldSleepData = this.originalSleepData[0]?.data || [];
      const newSleepData = oldSleepData.filter((v) =>
        this.seriesDisplay.includes(v.date)
      );

      this.processedSleepData = [
        {
          name: 'Sleep Data',
          data: [...newSleepData],
        },
      ];
    },
  },
  methods: {
    syncCommentToCombineChart(comment) {
      if (this.$refs.refCombineChart) {
        this.$refs.refCombineChart.addPoint(comment);
      }
    },

    toggleCalendar() {
      this.isShowCalendar = !this.isShowCalendar;
    },

    handleChangeSeriesDisplay(e) {
      if (this.seriesDisplay.includes(e.target.value)) {
        this.seriesDisplay = this.seriesDisplay.filter(
          (item) => item !== e.target.value
        );
      } else {
        this.seriesDisplay.push(e.target.value);
      }
    },

    changeActionTab(acitionTab) {
      this.actionTabActive = acitionTab;
    },

    hideModal() {
      if (this.showModal) this.showModal = false;
    },

    openComment(item) {
      const annotationClass = `.annotation_comment_${item.id}`;
      const el = document.querySelector(annotationClass);
      if (!el) {
        console.error(`Element ${annotationClass} not found`);
        return;
      }

      const clientRect = el.getBoundingClientRect();
      const parent = el.closest('.apexcharts-canvas');
      if (!parent) {
        console.error(`Parent element not found for ${annotationClass}`);
        return;
      }

      parent.scrollIntoView({ behavior: 'smooth' });
      const parentRect = parent.getBoundingClientRect();
      const dateFormatted = dayjs(item.dates[0]).format('YYYY-MM-DD');
      const idx = this.records.findIndex((r) => r.date === dateFormatted);

      this.$refs.activityChart.forEach((item) => item.openComment(null, 0, 0));

      let commentItem = item;
      if (item.parent) {
        const parentItem = this.comments.find((r) => r.id === item.parent);
        if (parentItem) {
          commentItem = parentItem;
        }
      }

      const xOffset = 20;
      const yOffset = 100;
      this.$refs.activityChart[idx].openComment(
        commentItem,
        clientRect.x - parentRect.x + xOffset,
        clientRect.y - parentRect.y + yOffset
      );
    },

    onChangeCalendar(dates) {
      this.datesSelectInCalendar = dates;
      const currentQuery = { ...this.$route.query };
      const newDatesQuery = this.datesSelectInCalendar.join(',');
      currentQuery.dates = newDatesQuery;
      this.$router.replace({ query: currentQuery });

      this.toggleCalendar();
      this.getData();
    },

    async getData() {
      const { id: memberId } = this.$route.params;
      this.records = [];
      this.comments = [];

      try {
        const response = await axios.post(
          `/manager/v2/teams/member-detail/?member=${memberId}&mode=${this.mode}`,
          {
            dates: this.datesSelectInCalendar,
          }
        );

        const { records: rawRecords, comments: rawComments } = response.data;
        this.recordsCombineChart = rawRecords;
        this.commentCombineChart = rawComments;

        // process comments
        const dateComments = {};
        rawComments?.forEach((comment) => {
          comment.dates.forEach((date) => {
            const oldComments = dateComments[date] || [];
            dateComments[date] = [...oldComments, { ...comment, date: date }];
          });
        });

        this.comments = rawComments.filter((comment) => comment.x);

        // process records
        const records = rawRecords.map((record) => {
          const { date } = record;
          const comments = dateComments[date] || [];
          const events = [
            ...record.sleeps,
            ...record.meals,
            ...record.activity,
          ];
          return {
            ...record,
            series: [
              {
                name: 'series',
                data: record.series || [],
              },
            ],
            comments,
            events,
          };
        });

        this.records = records;

        // process sleep data
        const sleepData = records.flatMap((record) => {
          return record.sleeps.map((item) => {
            const startDate = dayjs(item.start_at);
            const endDate = dayjs(item.end_at);
            const isSameDay = startDate.isSame(endDate, 'day');

            if (isSameDay) {
              return {
                x: dayjs(item.end_at).format('MM月DD日'),
                y: [
                  Date.UTC(
                    2022,
                    1,
                    2,
                    startDate.get('hour'),
                    startDate.get('minute'),
                    0
                  ),
                  Date.UTC(
                    2022,
                    1,
                    2,
                    endDate.get('hour'),
                    endDate.get('minute'),
                    0
                  ),
                ],
                quality: item.type,
                date: item.date,
              };
            } else {
              return {
                x: dayjs(item.end_at).format('MM月DD日'),
                y: [
                  Date.UTC(2022, 1, 1, 0, 0, 0),
                  Date.UTC(
                    2022,
                    1,
                    2,
                    endDate.get('hour'),
                    endDate.get('minute'),
                    0
                  ),
                ],
                quality: item.type,
                date: item.date,
              };
            }
          });
        });

        this.originalSleepData = [
          {
            name: 'Sleep Data',
            data: sleepData,
          },
        ];
        this.processedSleepData = this.originalSleepData;

        // process combine chart
        this.mergeSeries = rawRecords.map((record) => {
          const { date, series } = record;
          return {
            name: date,
            data: series.slice(0, -1).map((item) => {
              const { x, y } = item;
              const nextItem = series[series.indexOf(item) + 1];
              const timeDiff = moment(nextItem.x).diff(moment(x));
              if (timeDiff > 30 * 60 * 1000) {
                return {
                  x: `${moment().format('YYYY-MM-DD')} ${x.substring(11, 16)}`,
                  y: null,
                };
              }
              return {
                x: `${moment().format('YYYY-MM-DD')} ${x.substring(11, 16)}`,
                y,
              };
            }),
            color: this.handleColor(date),
          };
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      this.datesArray = this.datesSelectInCalendar;
      this.seriesDisplay = this.datesSelectInCalendar;
    },

    changeMode(m) {
      localStorage.setItem('mode', m);
      this.mode = parseInt(m);
      const currentQuery = { ...this.$route.query };
      currentQuery.mode = m.toString();

      this.$router.replace({ query: currentQuery });
    },

    handleColorCheckbox(index) {
      return this.colors[index % 14];
    },

    createColorArray() {
      const arr = this.datesSelectInCalendar;
      const dateColorArray = [];
      const colorMapping = {};

      for (let i = 0; i < arr.length; i++) {
        const currentDate = new Date(arr[i]);
        const weekdayIndex = currentDate.getDay();

        if (!colorMapping[weekdayIndex]) {
          colorMapping[weekdayIndex] = 0;
        }

        const colorIndex =
          colorMapping[weekdayIndex] % weekdayColors[weekdayIndex].length;
        const color = weekdayColors[weekdayIndex][colorIndex];

        colorMapping[weekdayIndex]++;
        dateColorArray.push({ date: currentDate, color });
      }
      return dateColorArray;
    },

    handleColor(date) {
      const dateToCheck = new Date(date);

      for (let i = 0; i < this.createColorArray().length; i++) {
        if (
          this.createColorArray()[i].date.getTime() === dateToCheck.getTime()
        ) {
          return this.createColorArray()[i].color;
        }
      }
    },

    getWeekday(date) {
      const weekdayNames = ['日', '月', '火', '水', '木', '金', '土'];
      const weekdayIndex = new Date(date).getDay();
      return weekdayNames[weekdayIndex];
    },

    handleScroll() {
      const { pageYOffset, innerHeight } = window;
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      const {
        scrollTop: bodyScrollTop,
        clientHeight: bodyClientHeight,
        scrollHeight: bodyScrollHeight,
      } = document.body;

      const scrollPosition = Math.max(pageYOffset, scrollTop, bodyScrollTop);
      const windowHeight = Math.max(
        innerHeight,
        clientHeight,
        bodyClientHeight
      );
      const documentHeight = Math.max(scrollHeight, bodyScrollHeight);

      const buffer = 200;

      if (scrollPosition + windowHeight >= documentHeight - buffer) {
        this.isLoading = true;
        this.visibleCharts += this.increment;
      }
    },
  },
};
</script>

<style>
@import './assets/lib/bootstrap/bootstrap.min.css';
@import './assets/css/main.css';

.form-control.reportrange-text {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}

.btn-select-date {
  background-color: #8b172f;
  height: 36px;
  border-radius: 5px;
  border-color: #8b172f;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 25px;
}

.btn-select-date:hover,
.btn-select-date:focus {
  background-color: #8b172f;
}

.submit-button {
  border-color: #8b172f;
  background-color: #8b172f;

  color: #fff;
}

.submit-button:hover {
  border-color: #8b172f;
  background-color: #8b172f;

  color: #fff;
}
</style>
