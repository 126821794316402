<template>
  <div class="col">
    <p class="fw-bold fz-15 mb-0 text-uppercase">Step</p>
    <div class="step">
      <span class="step-number fw-bold pt-1">{{ numberStep }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    numberStep: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style>
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}

.step-number {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: #8B172F;
  color: #fff;
  font-size: 21px;
}

.step-title {
  margin-left: 10px;
}

.step.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.border-right {
  border-right: 1px solid #D9D9D9;
  /* Set the desired border color */
}
</style>
