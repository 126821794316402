<template>
  <b-modal
    ref="modal"
    @ok="handleOk"
    id="modal-performance"
    :hide-footer="true"
    v-model="modalVisible"
    title="ハイパフォーマンスエリア"
  >
    <form method="POST" @submit.prevent="validateForm">
      <div class="alert alert-danger" v-if="!isValid" role="alert">
        {{ errorMsg }}
      </div>
      <div class="row">
        <div class="col-6">
          <label for="lower" class="form-label">下限 (mg/dL)</label>
          <input
            type="number"
            class="form-control"
            id="lower"
            min="0"
            max="350"
            v-model="lower"
          />
        </div>
        <div class="col-6">
          <label for="upper" class="form-label">上限 (mg/dL)</label>
          <input
            type="number"
            class="form-control"
            id="upper"
            min="0"
            max="350"
            v-model="upper"
          />
        </div>
      </div>

      <div id="chart" v-if="isChartVisible">
        <apexchart
          type="line"
          height="350"
          key="line-chart"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <!-- Button OK and cancel -->
      <div class="text-end">
        <b-button variant="outline-secondary" @click="handleClose()"
          >キャンセル</b-button
        >
        <b-button type="submit" class="btn btn-primary-custom ms-3"
          >決定</b-button
        >
      </div>
    </form>
  </b-modal>
</template>

<script>
import { ref } from "vue";
import { MSG_VALIDATE } from "../../utils/const";

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    lowerData: {
      type: Number,
      default: 100,
      required: false,
    },
    upperData: {
      type: Number,
      default: 230,
      required: false,
    },
  },

  data() {
    return {
      lower: null,
      upper: null,
      errorMsg: ref(""),
      msg_validate: ref(MSG_VALIDATE),
      min: 0,
      max: 350,
      isValid: true,
      isChartVisible: false,
      chartOptions: {
        chart: {
          id: "line-chart",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
          max: 350,
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: ["#BFBFBF"],
        },
        annotations: {
          yaxis: [
            {
              min: 0,
              max: 100,
              plotLines: [],
              y: 0,
              y2: 160,
              strokeDashArray: 0,
              borderColor: "#8b172f",
              fillColor: "#fad4db",
            },
            {
              y: 0,
              y2: 0,
              strokeDashArray: 0,
              borderColor: "#8b172f",
              fillColor: "#fad4db",
            },
          ],
        },
      },
      series: [
        {
          name: "Data",
          data: [
            119, 126, 144, 188, 189, 171, 166, 180, 190, 199, 192, 176, 173,
            162, 143, 135, 132, 137, 134, 129, 123,
          ],
        },
      ],
    };
  },
  created() {
    if (this.lowerData) {
      this.lower = this.lowerData;
    }
    if (this.upperData) {
      this.upper = this.upperData;
    }
    this.errorMsg = this.msg_validate;
  },
  mounted() {
    this.loadFormData();
    this.setMinMaxValues();
  },
  computed: {
    modalVisible: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("update:showModal", value);
      },
    },
  },
  watch: {
    lower() {
      this.updateChartVisibility();
    },
    upper() {
      this.updateChartVisibility();
    },
    lowerData() {
      this.lower = this.lowerData;
      this.updateChartVisibility();
    },
    upperData() {
      this.upper = this.upperData;
      this.updateChartVisibility();
    },
  },
  methods: {
    updateChartVisibility() {
      this.isChartVisible = false;
      this.$nextTick(() => {
        this.isChartVisible = true;
      });
    },
    getItemUpdate() {
      const data = localStorage.getItem("highPerformance");
      if (data) {
        const parsedData = JSON.parse(data);
        this.lower = parsedData.lower;
        this.upper = parsedData.upper;
      }
    },
    // Set min max value
    setMinMaxValues() {
      const lower = parseFloat(this.lower);
      const upper = parseFloat(this.upper);
      // const data = this.series[0].data;
      if (!isNaN(lower) && !isNaN(upper)) {
        this.chartOptions.annotations.yaxis[0].y = lower;
        this.chartOptions.annotations.yaxis[1].y = upper;
        this.chartOptions.annotations.yaxis[1].y2 = lower;
        this.chartOptions.annotations.yaxis[0].y2 = upper;
      }
    },
    validateForm() {
      const lower = parseFloat(this.lower);
      const upper = parseFloat(this.upper);

      if (
        isNaN(lower) ||
        isNaN(upper) ||
        lower >= upper ||
        lower < this.min ||
        upper > this.max
      ) {
        this.isValid = false;
      } else {
        this.isValid = true;
        this.errorMsg = "";
        this.handleOk();
      }
    },
    handleOk() {
      localStorage.setItem(
        "highPerformance",
        JSON.stringify({ lower: this.lower, upper: this.upper })
      );
      this.$emit("modalClicked");
      this.$refs.modal.hide();
    },
    loadFormData() {
      const formData = localStorage.getItem("highPerformance");
      if (formData) {
        const { lower, upper } = JSON.parse(formData);
        this.lower = lower;
        this.upper = upper;
        this.series[0].data = [
          119, 126, 144, 188, 189, 171, 166, 180, 190, 199, 192, 176, 173, 162,
          143, 135, 132, 137, 134, 129, 123,
        ];
      }
    },

    handleClose() {
      this.$refs.modal.hide();
    },
  },
  updated() {
    this.setMinMaxValues();
  },
};
</script>

<style>
.close {
  border: 0px solid #fff;
}
</style>
