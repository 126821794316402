<template>
  <div class="cl-35363A">
    <div v-if="isAdvisor" class="card border-none mb-5">
      <div class="row g-0">
        <!-- Left Section -->
        <div class="col-md-3 text-center">
          <img
            src="../../assets/img/users/user-7.png"
            class="img-fluid rounded-start"
            alt="Left Image"
          />
        </div>

        <!-- Right Section -->
        <div class="col-md-9">
          <div class="card-body">
            <h5 class="fz-15 fw-bold">レポート作成機能はβ版です</h5>
            <router-link
              :to="{
                name: 'report.create',
                params: { id: $route.params.id },
              }"
              replace
              class="btn btn-primary-custom fz-12"
              >新規レポート作成
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <List />
  </div>
</template>

<script>
import List from './List.vue';
export default {
  name: 'Index',
  components: {
    List,
  },
  computed: {
    isAdvisor() {
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        return user.type === 'Advisor';
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
