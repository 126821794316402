<template>
  <div class="">
    <apexchart type="rangeBar" height="350px" width="800" :options="chartOptions" :series="series" ref="sleepChart">
    </apexchart>
  </div>
</template>

<script>
export default {
  name: "SleepChart",
  props: ["series", "seriesDisplay"],
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: "rangeBar",
          height: 350,
          zoom: {
            enabled: false,
          },
          width: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "30%",
          },
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeFormatter: {
              year: "",
              month: "",
              day: "00:00",
              hour: "HH:mm",
            },
            datetimeUTC: true,
          },
        },
      },
    };
  },
};
</script>

<style></style>
