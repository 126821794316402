<template>
  <b-modal
    ref="modal"
    title="解析精度"
    id="modal-accuracy"
    @ok="handleOk"
    :hide-footer="true"
  >
    <select
      class="form-select w-100"
      v-model="selectAccuracy"
      @change="updateProgress"
    >
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <p class="fz-12 mt-5 d-flex align-items-center">
      解析精度
      <span class="rounded-circle"> 約 </span>
      <b class="title-sz-1 fw-bold">{{ progress }}%</b>
    </p>
    <div class="progress mb-3">
      <div
        class="progress-bar bg-color-1"
        role="progressbar"
        :style="{ width: progress + '%' }"
        aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <p>
      レポート精度の目安を表しています。解析データ数が多いほど精度が高まるため、継続的に血糖値解析をすることで解析精度を高めることができます。また、この解析には主観・生活習慣データが必要なため、選手からの入力情報が不足しているとレポートの質が低下する可能性があります。
    </p>
    <div class="text-end">
      <b-button variant="outline-secondary" @click="handleClose()">
        キャンセル
      </b-button>
      <b-button
        type="submit"
        class="btn btn-primary-custom ms-3"
        @click="handleOk()"
      >
        決定
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Accuracy",
  props: {
    accuracyData: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      progress: 0,
      selectAccuracy: 0,
      options: [
        { label: "未選択", value: 0 },
        { label: "10%", value: 10 },
        { label: "20%", value: 20 },
        { label: "30%", value: 30 },
        { label: "40%", value: 40 },
        { label: "50%", value: 50 },
        { label: "60%", value: 60 },
        { label: "70%", value: 70 },
        { label: "80%", value: 80 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
    };
  },

  mounted() {
    const accuracy = localStorage.getItem("selectedAccuracy");
    this.selectAccuracy = accuracy ? accuracy : 0;
    this.progress = this.selectAccuracy;
  },

  methods: {
    updateProgress() {
      this.progress = this.selectAccuracy;
    },
    handleClose() {
      this.$refs.modal.hide();
    },
    handleOk() {
      localStorage.setItem(
        "selectedAccuracy",
        JSON.stringify(this.selectAccuracy)
      );
      this.$emit("accuracy-change");
      this.$refs.modal.hide();
    },
  },
  watch: {
    accuracyData() {
      this.selectAccuracy = this.accuracyData;
      this.progress = this.accuracyData;
    },
  },
};
</script>

<style scope>
@import "../../assets/css/main.css";

.rounded-circle {
  width: 13px;
  height: 14px;
  font-weight: 700;
  /* line-height: 8px; */
  background: #040000;
  border-radius: 10px;
  color: #fff;
  padding: 3px;
  font-size: 8px;
}
</style>
