<template>
  <div
    v-click-outside="hideComment"
    class="show dropdown-menu"
    style="max-width: 400px"
  >
    <div class="form-comment" v-if="!comment.id">
      <div class="form-group">
        <div class="input-append-grid d-flex">
          <span class="input-append-icon">
            <img
              v-if="comment.comment_by?.avatar"
              :src="comment.comment_by?.avatar"
              class="ico-avatar-default"
              alt="noimg"
            />
            <i v-else class="ico-avatar-default"></i>
          </span>
          <div class="form-group-control">
            <textarea
              class="form-control"
              v-model="commentText"
              placeholder="Comment here"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button
          @click="$emit('hide')"
          class="btn btn-outline-secondary"
          data-bs-dismiss="modal"
        >
          キャンセル
        </button>
        <button @click="addComment" class="btn btn-primary-light">
          送信する
        </button>
      </div>
    </div>

    <!-- Reply Content  -->
    <div class="form-comment mt-2" v-if="comment.id" style="position: relative">
      <i
        role="button"
        class="ico-close"
        style="position: absolute; top: -15px; right: 0px"
        @click="$emit('hide')"
      ></i>

      <div class="mb-2">
        <div class="d-flex align-items-center">
          <img
            v-if="comment.comment_by?.avatar"
            :src="comment.comment_by?.avatar"
            class="ico-avatar-default"
            alt="noimg"
          />
          <i v-else class="ico-avatar-default"></i>
          <span class="ms-2 text-dark">{{
            comment.comment_by?.full_name
          }}</span>
          <span class="small ms-3" style="font-size: 10px">{{
            moment(comment.comment_at).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="ms-5 mt-2 text-secondary">
          {{ comment.comment }}
        </div>
      </div>
      <div class="mb-2" v-for="item in comment.children" :key="item.id">
        <div class="d-flex align-items-center">
          <img
            v-if="item.comment_by?.avatar"
            :src="item.comment_by?.avatar"
            class="ico-avatar-default"
            alt="noimg"
          />
          <i v-else class="ico-avatar-default"></i>
          <span class="ms-2">{{ item.comment_by?.full_name }}</span>
          <span class="small ms-3" style="font-size: 10px">{{
            moment(comment.comment_at).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="ms-5 mt-2 text-secondary">
          {{ item.comment }}
        </div>
      </div>
      <div class="form-buttons">
        <input
          v-on:keyup.enter="addReply"
          class="form-control"
          type="text"
          v-model="reply"
          placeholder="Reply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "App",
  props: ["comment"],
  data: function () {
    return {
      commentText: "",
      reply: "",
      avatar: "",
    };
  },
  created() {
    this.avatar = window.user.avatar;
  },
  methods: {
    moment(v) {
      return moment(v);
    },
    hideComment() {
      this.$emit("hide===");
    },
    addComment() {
      this.$emit("add-comment", { text: this.commentText });
      this.commentText = "";
    },
    addReply() {
      this.$emit("add-reply", { text: this.reply });
      this.reply = "";
    },
  },
};
</script>

<style></style>
