<template>
  <div class="calendar">
    <div class="calendar__check-list">
      <div
        v-for="item in checkListOptions"
        :key="`check-${item.value}`"
        class="form-check"
      >
        <input
          class="form-check-input"
          type="checkbox"
          :value="item.value"
          :id="`${item.value}-id`"
          :checked="checkList.includes(item.value)"
          @click="handleCheckList(item.value)"
        />
        <label class="form-check-label" :for="`${item.value}-id`">
          {{ item.name }}
        </label>
      </div>
    </div>
    <div class="calendar__header">
      <div class="calendar__header-action">
        <div @click="previousMonth" class="calendar__change-month">
          <img src="../assets/img/icons/pre-month.svg" />
        </div>
        <div class="calendar__month-year">{{ year }}年{{ months[month] }}</div>
        <div
          :class="{
            disabled:
              new Date().getMonth() === month &&
              new Date().getFullYear() == year,
          }"
          @click="nextMonth"
          class="calendar__change-month next"
        >
          <img
            v-if="
              new Date().getMonth() === month &&
              new Date().getFullYear() == year
            "
            src="../assets/img/icons/next-month-disabled.svg"
          />

          <img v-else src="../assets/img/icons/pre-month.svg" />
        </div>
      </div>
    </div>
    <div class="calendar__days-of-week">
      <div
        v-for="(day, index) in daysOfWeek"
        :key="`${index}-day-week`"
        class="calendar__day"
      >
        {{ day }}
      </div>
    </div>
    <div class="weeks">
      <div
        v-for="(week, index) in weeks"
        :key="`${index}-week`"
        class="calendar__week"
      >
        <div
          @click="selectDay(day)"
          v-for="(day, index) in week"
          :key="`${index}-calendar-day`"
          class="calendar__day"
          :class="{
            empty: !day.date,
            faded: day.isPrevMonth || day.isNextMonth,
            disabled: isDisabledDate(day),
            selected: isSelected(day),
          }"
        >
          <div>
            <span class="date_area">
              {{ day.date }}
              <span
                :class="{
                  glucose_sign: hasGlucose(day),
                }"
              >
              </span>
            </span>
          </div>
          <div v-if="!day.isPrevMonth && !day.isNextMonth">
            <div class="event-label" v-if="isDisplayMatch(day)">試合</div>
            <div class="event-label" v-if="isDisplayWorkout(day)">練習</div>
            <div v-if="isDisplayComment(day)" class="comment-label">
              コメント
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar__footer">
      <div class="calendar__filter-selected">
        {{ this.daysSelected.length }}件選択中
      </div>
      <div class="form-buttons mt-0 calendar__btn-group">
        <button @click="$emit('toggle')" class="btn btn-outline-secondary">
          キャンセル
        </button>
        <button
          @click="$emit('filter-chart', daysSelected)"
          class="btn btn-primary-custom"
        >
          送信する
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Calendar",
  props: ["dates"],
  data() {
    return {
      checkList: ["match", "workout", "comments"],
      calendarData: [],
      month: 0,
      year: 0,
      daysOfWeek: ["日", "月", "火", "水", "木", "金", "土"],
      weeks: [],
      months: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      checkListOptions: [
        {
          value: "match",
          name: "試合",
        },
        {
          value: "workout",
          name: "練習",
        },
        {
          value: "comments",
          name: "コメント有",
        },
      ],
      daysSelected: this.dates,
    };
  },
  methods: {
    getDataByMonth() {
      const urlApi =
        this.$route.name == "report.detail"
          ? `/manager/v2/calendar/?member=${localStorage.getItem(
              "memberId"
            )}&month=${this.year}-${this.month + 1}`
          : `/manager/v2/calendar/?member=${this.$route.params.id}&month=${
              this.year
            }-${this.month + 1}`;
      axios.get(urlApi).then((res) => {
        this.calendarData = res.data;
      });
    },
    isDisplayMatch(day) {
      return (
        this.calendarData[day.date - 1] &&
        this.calendarData[day.date - 1].has_match &&
        this.checkList.includes("match")
      );
    },
    isDisplayWorkout(day) {
      return (
        this.calendarData[day.date - 1] &&
        this.calendarData[day.date - 1].has_practice &&
        this.checkList.includes("workout")
      );
    },
    isDisplayComment(day) {
      return (
        this.calendarData[day.date - 1] &&
        this.calendarData[day.date - 1].has_comment &&
        this.checkList.includes("comments")
      );
    },
    isSelected(day) {
      return (
        !day.isPrevMonth &&
        !day.isNextMonth &&
        this.daysSelected.includes(
          moment(`${this.year}-${this.month + 1}-${day.date}`).format(
            "YYYY-MM-DD"
          )
        )
      );
    },
    hasGlucose(day) {
      const dateData = this.calendarData[day.date - 1];
      return !day.isPrevMonth && !day.isNextMonth && dateData?.has_glucose;
    },
    isDisabledDate() {
      return true;
    },
    previousMonth() {
      if (this.month === 0) {
        this.month = 11;
        this.year -= 1;
      } else {
        this.month -= 1;
      }
      this.generateCalendar();
      this.getDataByMonth();
    },
    selectDay(day) {
      if (day.isPrevMonth || day.isNextMonth) {
        return;
      }
      let newDate = moment(`${this.year}-${this.month + 1}-${day.date}`).format(
        "YYYY-MM-DD"
      );
      if (this.daysSelected.includes(newDate)) {
        this.daysSelected = this.daysSelected.filter(
          (item) => item !== newDate
        );
      } else {
        this.daysSelected = [...this.daysSelected, newDate];
      }
    },
    nextMonth() {
      if (
        new Date().getMonth() === this.month &&
        new Date().getFullYear() == this.year
      ) {
        return;
      }
      if (this.month === 11) {
        this.month = 0;
        this.year += 1;
      } else {
        this.month += 1;
      }
      this.getDataByMonth();
      this.generateCalendar();
    },
    handleCheckList(value) {
      if (this.checkList.includes(value)) {
        this.checkList = this.checkList.filter((item) => item !== value);
      } else {
        this.checkList.push(value);
      }
    },
    renderContentDay(day) {
      let data = moment(`${this.year}-${this.month + 1}-${day.date}`).format(
        "YYYY-MM-DD"
      );
      let itemData = this.calendarData.find((item) => item.date === data);
      return itemData;
    },
    generateCalendar() {
      this.weeks = [];
      const date = new Date(this.year, this.month, 1);
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
      const offset = date.getDay();

      let day = 1;
      let prevMonthDays = new Date(this.year, this.month, 0).getDate();
      for (let i = 0; i < 6; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < offset) {
            week.push({
              date: prevMonthDays - offset + j + 1,
              isPrevMonth: true,
            });
          } else if (day > daysInMonth) {
            week.push({ date: day - daysInMonth, isNextMonth: true });
            day += 1;
          } else if (
            new Date(this.year, this.month, day).getMonth() === this.month
          ) {
            week.push({ date: day });
            day += 1;
          } else {
            week.push({ date: day, isNextMonth: true });
            day += 1;
          }
        }
        this.weeks.push(week);
      }
    },
  },
  mounted() {
    const now = new Date();
    this.month = now.getMonth();
    this.year = now.getFullYear();
    this.generateCalendar();
    this.getDataByMonth();
  },
};
</script>

<style>
.calendar {
  font-size: 14px;
  background-color: #ffffff;
  border: 2px solid #f1f1f1;
  box-shadow: 0px 4px 40px rgba(53, 54, 58, 0.08);
  border-radius: 10px;
  width: 405px;
  padding: 10px;
  box-sizing: border-box;
  padding: 16px 20px 20px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 498;
}

.calendar__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.calendar__month-year {
  font-weight: bold;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #35363a;
}

.calendar__days-of-week {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
}

.calendar__day {
  cursor: pointer;
  text-align: center;
  width: 50px;
  height: 60px;
  padding: 2px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #35363a;
}

.calendar__day.selected {
  background-color: #8b172f !important;
  color: #fff !important;
}

.calendar__day.selected .comment-label {
  background-color: #fff;
  color: #8b172f;
}

.calendar__day.selected .event-label {
  background-color: #fff;
  color: #edaa3e;
}

.calendar__week {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar__day.empty {
  color: #cccccc;
}

.calendar__prev-month,
.calendar__next-month {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.calendar__prev-month:hover,
.calendar__next-month:hover {
  background-color: #f5f5f5;
}

.calendar__day.faded {
  opacity: 0.5;
  cursor: unset;
}

.calendar__check-list {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.calendar__check-list .form-check input {
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}

.calendar__check-list .form-check {
  display: flex;
  align-items: center;
  height: 20px;
  cursor: pointer !important;
}

.calendar__check-list .form-check-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #35363a;
  padding-left: 10px;
  margin-top: 4px;
  cursor: pointer;
}

.calendar__days-of-week .calendar__day {
  height: unset;
}

.calendar__footer {
  padding: 15px 0px 0px;
  border-top: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar__filter-selected {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #35363a;
}

.calendar__footer button {
  width: 100px;
  height: 30px;
  padding: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.calendar__btn-group {
  display: grid;
  grid-template-columns: 100px 100px;
}

.calendar__footer .btn-primary-light {
  background: #8b172f;
  border-color: #8b172f;
}

.calendar__change-month img {
  width: 5px;
  height: 10px;
}

.calendar__change-month {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f1f1f1;
  cursor: pointer;
}

.calendar__change-month.next img {
  transform: rotate(180deg);
}

.calendar__change-month.next.disabled {
  background: #f1f1f1;
  cursor: unset;
}

.calendar__change-month.next.disabled img {
  transform: rotate(0deg);
}

.calendar__header-action {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 20px;
  align-items: center;
}

.event-label {
  height: 13px;
  background-color: #edaa3e;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  color: #fff;
  margin-top: 2px;
  border-radius: 2px;
}

.comment-label {
  height: 13px;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  color: #fff;
  margin-top: 2px;
  background-color: #8b172f;
  border-radius: 2px;
}

.calendar__day.disabled {
  background-color: #f1f1f1;
  opacity: 1;
}

.calendar__day.disabled.faded {
  background-color: #fff;
  opacity: 0.5;
}

.glucose_sign {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #edaa3e;
  display: inline-block;
  position: absolute;
  top: 3px;
  right: -8px;
}

.date_area {
  position: relative;
}
</style>
