<template>
  <b-modal
    ref="modal"
    @ok="handleOk"
    :id="modalId"
    :hide-footer="true"
    title="アンケート結果"
  >
    <span>試合3日前や2日前はよく眠れましたか？</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_1">
      <option
        v-for="option1 in surveyList.sleep_state"
        :value="option1.value + '+' + option1.title"
        :key="option1.value"
      >
        {{ option1.title }}
      </option>
    </select>
    <span>試合前日はよく眠れましたか？</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_2">
      <option
        v-for="option2 in surveyList.sleep_state"
        :value="option2.value + '+' + option2.title"
        :key="option2.value"
      >
        {{ option2.title }}
      </option>
    </select>
    <span>試合の後はよく眠れましたか？</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_3">
      <option
        v-for="option3 in surveyList.sleep_state"
        :value="option3.value + '+' + option3.title"
        :key="option3.value"
      >
        {{ option3.title }}
      </option>
    </select>
    <span
      >試合前は緊張しましたか？燃えましたか？(何個でもあてはまるものチェックしてください)</span
    >
    <div class="select-wrapper" @click="toggleSelect_1">
      <div class="select-value">{{ selectedCount_1 }} selected</div>
      <div class="select-options z-index" v-show="isOpen_1">
        <label
          class="select-option w-100"
          v-for="option4 in surveyList.mood_before_match"
          :key="option4.id"
        >
          <input
            type="checkbox"
            :value="option4.id + '+' + option4.title"
            v-model="selectedOptions_4"
            :checked="
              selectedOptions_4.includes(option4.id + '+' + option4.title)
            "
          />

          {{ option4.title }}
        </label>
      </div>
    </div>

    <span
      >試合中の気持ち・感覚は？(何個でもあてはまるものチェックしてください)</span
    >
    <div class="select-wrapper" @click="toggleSelect">
      <div class="select-value">{{ selectedCount }} selected</div>
      <div class="select-options" v-show="isOpen">
        <label
          class="select-option w-100 z-index"
          v-for="option5 in surveyList.mood_during_match"
          :key="option5.id"
        >
          <input
            type="checkbox"
            :value="option5.id + '+' + option5.title"
            v-model="selectedOptions_5"
          />
          {{ option5.title }}
        </label>
      </div>
    </div>

    <span>試合までの調整や体調はいかがでしたか？</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_6">
      <option
        v-for="option6 in surveyList.physical_state"
        :value="option6.value + '+' + option6.title"
        :key="option6.value"
      >
        {{ option6.title }}
      </option>
    </select>

    <span>本日の結果はどうでしたか？</span>
    <select class="form-select w-100mt-2 mb-3" v-model="selectedOptions_7">
      <option
        v-for="option7 in surveyList.match_result"
        :value="option7.value + '+' + option7.title"
        :key="option7.value + '+' + option7.title"
      >
        {{ option7.title }}
      </option>
    </select>
    <div class="text-end">
      <b-button variant="outline-secondary" @click="handleClose()"
        >キャンセル
      </b-button>
      <b-button
        type="submit"
        @click="handleOk()"
        class="btn btn-primary-custom ms-3"
        >決定
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import store from "../../store/index.js";
import { computed } from "vue";

export default {
  props: {
    modalId: String,
    loadSurvey: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      surveyList: computed(() => store.state.surveyOptions.data),
      selectedOptions_1: "",
      selectedOptions_2: "",
      selectedOptions_3: "",
      selectedOptions_4: [],
      selectedOptions_5: [],
      selectedOptions_6: "",
      selectedOptions_7: "",
      isOpen: false,
      isOpen_1: false,
      selected: [],
    };
  },

  computed: {
    selectedCount() {
      return this.selectedOptions_5.length;
    },
    selectedCount_1() {
      return this.selectedOptions_4.length;
    },
  },

  mounted() {
    this.apiSurvey();
    this.loadSurveyData();
  },

  methods: {
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },

    toggleSelect_1() {
      this.isOpen_1 = !this.isOpen_1;
    },

    handleClose() {
      this.$refs.modal.hide();
    },

    handleOk() {
      this.apiSurvey();
      const surveyData = JSON.stringify({
        selectedOptions1: this.selectedOptions_1,
        selectedOptions2: this.selectedOptions_2,
        selectedOptions3: this.selectedOptions_3,
        selectedOptions4: this.selectedOptions_4,
        selectedOptions5: this.selectedOptions_5,
        selectedOptions6: this.selectedOptions_6,
        selectedOptions7: this.selectedOptions_7,
      });
      this.$emit("surveySubmitted", surveyData);
      this.$refs.modal.hide();
    },

    loadSurveyData() {
      if (this.loadSurvey !== "") {
        const {
          selectedOptions1,
          selectedOptions2,
          selectedOptions3,
          selectedOptions4,
          selectedOptions5,
          selectedOptions6,
          selectedOptions7,
        } = JSON.parse(this.loadSurvey);

        this.selectedOptions_1 = selectedOptions1;
        this.selectedOptions_2 = selectedOptions2;
        this.selectedOptions_3 = selectedOptions3;
        this.selectedOptions_4 = selectedOptions4;
        this.selectedOptions_5 = selectedOptions5;
        this.selectedOptions_6 = selectedOptions6;
        this.selectedOptions_7 = selectedOptions7;
      }
      const surveyData = JSON.stringify({
        selectedOptions1: this.selectedOptions_1,
        selectedOptions2: this.selectedOptions_2,
        selectedOptions3: this.selectedOptions_3,
        selectedOptions4: this.selectedOptions_4,
        selectedOptions5: this.selectedOptions_5,
        selectedOptions6: this.selectedOptions_6,
        selectedOptions7: this.selectedOptions_7,
      });
      this.$emit("surveySubmitted", surveyData);
    },

    apiSurvey() {
      store.dispatch("getSurvey");
    },
  },
};
</script>

<style scope>
.select-wrapper {
  position: relative;
  cursor: pointer;
}

.select-value {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
}

.select-option {
  padding: 5px;
}

.select-option input[type="checkbox"] {
  margin-right: 5px;
}

.z-index {
  z-index: 999;
}
</style>
