<template>
  <div
    class="dropdown-menu dropdown-menu-end dropdown-noti"
    :class="{ show: this.show }"
  >
    <div class="dropdown-noti__header">
      <h4>通知</h4>
    </div>
    <div class="dropdown-noti__main">
      <b-tabs class="dropdown-noti__content">
        <b-tab title="コメント" active>
          <div>
            <div
              v-for="comment in comments"
              :key="comment.id"
              class="comment-item"
            >
              <img
                v-if="comment.comment_by?.avatar"
                class="comment-item__avatar"
                :src="comment.comment_by?.avatar"
                alt=""
              />
              <img
                v-else
                class="comment-item__avatar"
                src="../assets/img/users/avatar-default.svg"
                alt=""
              />
              <div class="comment-item__content">
                <p class="comment-item__author-and-time">
                  {{
                    comment.comment_by.full_name
                      ? comment.comment_by.full_name
                      : `User ${comment.comment_by.id}`
                  }}<span>{{ formatDataToTimeAgo(comment.comment_at) }}</span>
                </p>
                <p class="comment-item__title">
                  {{ comment.comment ? comment.comment : "" }}
                </p>
                <div class="comment-item__date-list">
                  <div
                    v-for="date in comment.dates"
                    v-bind:key="`${comment.id}-${date}`"
                    class="comment-item__timeline"
                  >
                    <img src="./../assets/img/icons/ico-timeline.svg" alt="" />
                    <span>{{ convertDate(date) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <infinite-loading :distance="200" @infinite="loadMoreComments">
            <div slot="spinner"></div>
          </infinite-loading>
        </b-tab>
        <b-tab title="レポート">
          <div>
            <div v-for="report in reports" :key="report.id" class="report-item">
              <img
                v-if="report.reported_by?.avatar"
                class="report-item__avatar"
                :src="report.reported_by?.avatar"
                alt=""
              />
              <img
                v-else
                class="report-item__avatar"
                src="../assets/img/users/avatar-default.svg"
                alt=""
              />
              <div class="report-item__content">
                <p class="report-item__author-and-time">
                  {{ report.reported_by?.full_name }} has created a report on
                  {{ report.user?.full_name }}
                  <span>{{ formatDataToTimeAgo(report?.created_at) }}</span>
                </p>
                <div class="report-item__title">
                  {{ report.title ? report.title : "" }}
                </div>
                <div class="report-item__title fw-bold">
                  {{ convertDate(report?.start_date) }} ~
                  {{ convertDate(report?.end_date) }}
                </div>
                <div class="report-item__date-list">
                  <div
                    v-for="match_date in report.match_dates"
                    :key="match_date.match_event__start_at"
                    class="report-item__timeline"
                  >
                    <img src="./../assets/img/icons/ico-match-12.svg" alt="" />
                    <span class="text-dark">
                      {{
                        formatDateTimeRange(
                          match_date.match_event__start_at,
                          match_date.match_event__end_at
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <infinite-loading :distance="200" @infinite="loadMoreReports">
            <div slot="spinner"></div>
          </infinite-loading>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "Notification",
  props: ["show"],
  components: {
    InfiniteLoading,
  },
  data: function () {
    return {
      comments: [],
      reports: [],
      currentCommentPage: 1,
      currentReportPage: 1,
      totalComments: 0,
      totalReports: 0,
    };
  },
  created: function () {
    this.fetchCommentList();
    this.fetchReportList();
  },
  methods: {
    fetchData(type, page, $state = null) {
      axios
        .get(`/manager/v2/${type}/?page=${page}`)
        .then((res) => {
          this[type] = [...this[type], ...res.data.results];
          this[`total${type.charAt(0).toUpperCase() + type.slice(1)}`] =
            res.data.count;
          if ($state) $state.loaded();
        })
        .catch((error) => {
          console.error(`Error fetching ${type}: `, error);
        });
    },
    fetchCommentList() {
      this.fetchData("comments", this.currentCommentPage);
    },
    loadMoreComments($state) {
      if (this.comments.length === this.totalComments) return;
      this.currentCommentPage += 1;
      this.fetchData("comments", this.currentCommentPage, $state);
    },
    fetchReportList() {
      this.fetchData("reports", this.currentReportPage);
    },
    loadMoreReports($state) {
      if (this.reports.length === this.totalReports) return;
      this.currentReportPage += 1;
      this.fetchData("reports", this.currentReportPage, $state);
    },
    convertDate(date) {
      return moment(date).format("YYYY.MM.DD");
    },
    formatDataToTimeAgo(time) {
      moment.locale("ja");
      return moment(time).fromNow(true);
    },
    formatDateTimeRange(start, end) {
      const startDate = this.convertDate(start.slice(0, 10));
      const startTime = new Date(start).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      const endTime = new Date(end).toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      return `${startDate} ${startTime} ~ ${endTime}`;
    },
  },
};
</script>

<style>
.dropdown-noti {
  width: 544px;
  background-color: #ffffff !important;
  height: 560px;
  border: 2px solid #f1f1f1 !important;
  box-shadow: 0px 4px 40px rgba(53, 54, 58, 0.08) !important;
  border-radius: 10px !important;
  padding: 0 !important;
}

.dropdown-noti__header {
  padding: 15px 25px;
}

.dropdown-noti__header h4 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #35363a;
}

.dropdown-noti__main {
  width: 100%;
}

.dropdown-noti__main ul {
  flex-wrap: nowrap;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 20px;
  margin-left: 25px;
  border-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.dropdown-noti__main ul .nav-link {
  color: #35363a;
  padding-top: 0;
}

.dropdown-noti__main ul .nav-link.active {
  color: #8b172f;
  border-bottom: 1px solid #8b172f !important;
}

.dropdown-noti__content {
  width: 100% !important;
}

.dropdown-noti__content > div:first-child {
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}

.dropdown-noti__main ul li {
  min-width: fit-content;
}

.dropdown-noti__main ul li a {
  padding: 10px 0;
  border: 0 !important;
}

.comment-item,
.report-item {
  padding: 15px 25px;
  background-color: #fff;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
  cursor: pointer;
}

.comment-item:hover,
.report-item:hover {
  background-color: #fafafa;
}

.comment-item__avatar,
.report-item__avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-item__author-and-time,
.report-item__author-and-time {
  padding-top: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #35363a;
  margin-bottom: 8px;
}

.comment-item__author-and-time span,
.report-item__author-and-time span {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-left: 5px;
  color: #bfbfbf;
}

.comment-item__title,
.report-item__title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #35363a;
  margin-bottom: 10px;
}

.comment-item__timeline,
.report-item__timeline {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 5px;
  align-items: center;
  padding: 5px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  width: fit-content;
  margin-right: 8px;
  margin-bottom: 4px;
}

.comment-item__timeline span,
.report-item__timeline span {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #35363a;
}

.comment-item__date-list,
.report-item__date-list {
  display: flex;
  flex-wrap: wrap;
}

.report-item__content p {
  font-weight: 700;
  padding-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: #35363a;
}

.report-item__content span {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #bfbfbf;
  margin-left: 4px;
}

.dropdown-noti__content .tab-content {
  max-height: 490px;
  overflow: auto;
}
</style>
