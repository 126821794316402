<template>
  <div class="report-wrapper-content cl-35363A" :key="componentKey">
    <div
      class="report-header-site d-flex justify-content-between align-items-center"
    >
      <button
        type="button"
        class="back-btn bg-white border-0 d-flex"
        @click="backToDetail"
      >
        <i class="ico-back"></i>戻る
      </button>
    </div>
    <div class="row d-flex report-header px-4 mx-0">
      <div class="col text-start title">
        <p class="fw-bold fz-26">レポート作成（β版）</p>
      </div>
      <p class="fz-15 fw-bold mb-1">
        レポート入力項目：<b class="color-2">{{ this.minProgress }}/8</b>
      </p>
      <div class="progress bg-color-2 override-style-progress pl-0">
        <div
          class="progress-bar bg-color-1"
          role="progressbar"
          :style="{ width: progressPercentage.toFixed(2) + '%' }"
          :aria-valuenow="progressPercentage"
          :aria-valuemin="minProgress"
          :aria-valuemax="maxProgress"
        ></div>
      </div>
      <p class="fz-12 fw-normal mt-1">
        以下のSTEPに従って解析を進めてください。
      </p>
    </div>

    <!-- step 0 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="1" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15">タイトル</span>

          <div>
            <textarea
              class="form-control mb-3 mt-3"
              rows="1"
              v-model="title"
              v-on:input="handleTitleInput"
              maxlength="100"
              placeholder="100数字。文字以下入力してください"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- step 1 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="2" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15">初期設定</span>
          <p class="fz-13">
            今回作成するレポートの対象期間と試合日を設定します。はじめに設定してください。
          </p>
          <div>
            <calendar
              :dates="datesSelectInCalendar"
              @toggle="toggleCalendar"
              @filter-chart="onChangeCalendar"
              v-if="isShowCalendar"
            />
            <!-- </b-modal> -->
            <div class="fixline">
              <button
                @click="toggleCalendar"
                type="button"
                class="btn border-custom fz-12 fw-normal"
              >
                対象期間を編集
                <i class="ico-next-1"></i>
              </button>
              <b
                class="fz-13 fw-bold color-2 ms-4"
                :class="{ 'text-black': minPeroidCover === '' }"
              >
                {{
                  minPeroidCover !== ''
                    ? minPeroidCover + ' ~ ' + maxPeroidCover
                    : noSelect
                }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- step 2 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="3" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15">ハイパフォーマンスエリア</span>
          <p class="fz-13">
            ハイパフォーマンスのとなる血糖値エリアを入力してください。
          </p>
          <div class="fixline">
            <button
              v-b-modal.modal-performance
              type="button"
              class="btn border-custom fz-12 fw-normal"
            >
              ハイパフォーマンスエリアを編集
              <i class="ico-next-1"></i>
            </button>
            <!-- <b class="fz-13 fw-bold color-2 ms-4">170mg/dL ~ 240mg/dL </b> -->
            <b
              class="fz-13 fw-bold color-2 ms-4"
              :class="{ 'text-black': lower == 0 }"
            >
              {{ lower > 0 ? lower + 'mg/dL ~ ' + upper + 'mg/dL' : noSelect }}
            </b>
          </div>
        </div>
      </div>
    </div>

    <!-- step 3 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="4" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15">解析精度</span>
          <p class="fz-13">レポートの解析精度を選択してください。</p>
          <div class="fixline">
            <button
              type="button"
              v-b-modal.modal-accuracy
              class="btn border-custom fz-12 fw-normal"
            >
              解析精度を選択
              <i class="ico-next-1"></i>
            </button>
            <b
              class="fz-13 fw-bold color-2 ms-4"
              :class="{ 'text-black': !Number(accuracy) }"
            >
              {{ Number(accuracy) ? accuracy + '%' : '未選択' }}
            </b>
          </div>
        </div>
      </div>
    </div>

    <!-- step 4 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="5" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15">計測期間データへのコメント入力</span>
          <p class="fz-13">
            計測期間内の血糖値に対してコメントを入力してください。
          </p>
          <b
            class="fz-13 fw-bold color-2 ms-4"
            :class="{ 'text-black': datesSelectInCalendar.length === 0 }"
          >
            <template v-if="datesSelectInCalendar.length > 0">
              <ChartLine :check-prop="false" />
              <div class="rounded mt-4 mb-4">
                <b-form-textarea
                  v-model="overallComment"
                  :rows="4"
                  maxlength="1000"
                  placeholder="1000数字・文字以下入力してください。"
                  v-on:input="handleOverallCommentInput"
                >
                </b-form-textarea>
              </div>
            </template>
            <template v-else><NoDataMessage /></template>
          </b>
        </div>
      </div>
    </div>

    <!-- step 5 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="6" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15"
            >計測期間平均値変動データへのコメント入力
          </span>
          <p class="fz-13">
            計測期間内のグルコース平均値の推移に対してコメントを入力してください。
          </p>
          <b
            class="fz-13 fw-bold color-2 ms-4"
            :class="{ 'text-black': datesSelectInCalendar.length === 0 }"
          >
            <template v-if="datesSelectInCalendar.length > 0">
              <ChartColumn :check-prop="true" />
              <div class="rounded mt-4 mb-4">
                <b-form-textarea
                  v-model="averageGlucoseComment"
                  :rows="4"
                  maxlength="1000"
                  placeholder="1000数字・文字以下入力してください。"
                  v-on:input="handleAverageGlucoseInput"
                >
                </b-form-textarea>
              </div>
            </template>
            <template v-else><NoDataMessage /></template>
          </b>
        </div>
      </div>
    </div>

    <!-- step 6 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="7" />
        </div>
        <div class="col-md-10 step-content">
          <span class="title-sz-15"
            >計測期間睡眠平均値変動データへのコメント入力
          </span>
          <p class="fz-13">
            計測期間内の睡眠時グルコース平均値の推移に対してコメントを入力してください。
          </p>

          <b
            class="fz-13 fw-bold color-2 ms-4"
            :class="{ 'text-black': datesSelectInCalendar.length === 0 }"
          >
            <template v-if="datesSelectInCalendar.length > 0">
              <ChartColumn :boxPlotUpper="boxPlotUpper" :check-prop="false" />
              <div class="rounded mt-4 mb-4">
                <b-form-textarea
                  v-model="averageSleepGlucoseComment"
                  :rows="4"
                  maxlength="1000"
                  placeholder="1000数字・文字以下入力してください。"
                  v-on:input="handleSleepAverageInput"
                >
                </b-form-textarea>
              </div>
            </template>
            <template v-else><NoDataMessage /></template>
          </b>
        </div>
      </div>
    </div>

    <!-- step 7 -->
    <div class="card border-none mb-3 mx-3 py-4">
      <div class="row g-0 mx-0">
        <div class="col-md-2 text-center border-right">
          <Step :numberStep="8" />
        </div>

        <template v-if="datesSelectInCalendar.length > 0">
          <div class="col-md-10 step-content">
            <span class="title-sz-15">試合ごとの解析 </span>
            <p class="fz-13">試合に対して解析項目を入力してください。</p>
            <MatchReport
              v-for="item in reportData.match"
              :key="item.match_event"
              :match-data="item"
              :match-event="item.match_event"
              :high-per-lower="Number(lower)"
              :high-per-upper="Number(upper)"
              :match-start="item.start_at"
              :match-end="item.end_at"
              @matchReportChanged="getMatchReportData"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-md-10 step-content"><NoDataMessage /></div>
        </template>
      </div>
    </div>
    <div class="card form-footer border-none mb-3 mx-3 py-4">
      <div class="fz-15 fw-bold cl-35363A">確認</div>
      <div class="fz-13 pt-3 pb-3">
        最終的なレポートを確認することができます。
      </div>
      <button
        type="button"
        class="submit-btn bg-color-1 text-white"
        @click="onSubmit"
      >
        保存
      </button>
    </div>
    <HighPerformance
      :show-modal="showModal"
      @modalClicked="modalHighPerformance"
    />
    <Accuracy @accuracy-change="handleAccuracyChange" />
    <NoteBook @saveNotes="getNotesData" />
  </div>
</template>

<script>
import store from '../../store/index.js';
import {
  formatJapaneseDate,
  removeArray,
  getValueBeforePlus,
  itemsToRemove,
} from '../../components/common.js';
import Calendar from '../../components/Calendar.vue';
import ChartColumn from '../../components/Report/ChartColumn.vue';
import ChartLine from '../../components/Report/ChartLine.vue';
import Step from '../../components/Report/Step.vue';
import NoteBook from '../../components/Report/NoteBook.vue';
import Accuracy from '../../components/Report/Accuracy.vue';
import NoDataMessage from '../../components/Report/NoDataMessage.vue';
import HighPerformance from '../../components/Report/HighPerformance.vue';
import MatchReport from '../../components/Report/MatchReport.vue';

export default {
  name: 'CreateReport',
  components: {
    Accuracy,
    Calendar,
    ChartColumn,
    ChartLine,
    HighPerformance,
    MatchReport,
    NoDataMessage,
    NoteBook,
    Step,
  },

  data() {
    return {
      boxPlotUpper: '#1f4ee6',
      componentKey: 0, // Key của component
      overallComment: '',
      averageGlucoseComment: '',
      averageSleepGlucoseComment: '',
      datesSelectInCalendar: '',
      isShowCalendar: false,
      lower: 0,
      upper: 0,
      accuracy: 0,
      minProgress: 0,
      maxProgress: 8,
      progressPercentage: (0 / 8) * 100,
      maxPeroidCover: '',
      minPeroidCover: '',
      matchReports: [],
      noSelect: '未選択',
      showModal: false,
      title: '',
      notes: '',
      step0: false,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      validate: false,
      validate0: false,
      validate1: false,
      validate2: false,
      validate3: false,
      validate4: false,
    };
  },
  computed: {
    reportData() {
      return store.state.reportData.data;
    },
    createdReport() {
      return store.state.createReport.data;
    },
  },
  async created() {
    try {
      this.user = JSON.parse(localStorage.getItem('user'));
    } catch (err) {
      this.user = false;
    }

    itemsToRemove.forEach(function (item) {
      localStorage.removeItem(item);
    });

    await this.peroidCover();
    if (this.datesSelectInCalendar != null) {
      await this.apiGetReportData();
    }
    this.updateReportData();
    this.peroidMaxValue();
    this.peroidMinValue();
    this.getItemHighPerformance();
    this.getAccuracy();
    localStorage.setItem('progress', 0);
    this.overallComment = localStorage.getItem('periodComment_data');
    this.averageGlucoseComment = localStorage.getItem(
      'averageGlucoseCommentData'
    );
    this.averageSleepGlucoseComment = localStorage.getItem(
      'averageSleepGlucoseCommentData'
    );
  },

  mounted() {
    let accuracy = localStorage.getItem('selectedAccuracy');
    this.accuracy = accuracy ? accuracy : this.noSelect;
  },
  methods: {
    onSubmit() {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const isAdvisor = user.type === 'Advisor';
      const token = localStorage.getItem('token');

      if (this.datesSelectInCalendar.length > 0 && isAdvisor && token) {
        this.$toasted.show('保存しました', {
          theme: 'outline',
          position: 'top-right',
          duration: 3000,
          className: 'customCss',
        });
        this.apiCreateReport();
      } else {
        this.$toasted.show('レポートの削除に失敗しました', {
          theme: 'outline',
          position: 'top-right',
          duration: 3000,
          className: 'customErrorCss',
        });
      }
    },

    backToDetail() {
      this.$router.push({
        name: 'detail',
        params: { id: this.$route.params.id },
        query: { mode: '2' },
      });
    },

    apiCreateReport() {
      const reportValue = {
        title: this.title,
        dates: this.datesSelectInCalendar.join(','),
        hpz_lower: this.lower,
        hpz_upper: this.upper,
        accuracy: Number(this.accuracy) ? this.accuracy : 0,
        overall_comment: this.overallComment,
        avg_glucose_comment: this.averageGlucoseComment,
        avg_sleep_glucose_comment: this.averageSleepGlucoseComment,
        notes: Array.isArray(this.notes) ? this.notes.join(';') : this.notes,
        match_reports: this.matchReports.map((item) => {
          return {
            match_event: item.matchEvent,
            match_result_input: item.matchResultInput,
            match_glucose_comment: item.matchGlucoseComment,
            sleep_state_before_match: item.surveyAnswers.selectedOptions1
              ? getValueBeforePlus(item.surveyAnswers.selectedOptions1, true) ||
                -5
              : -5,
            sleep_state_day_before_match: item.surveyAnswers.selectedOptions2
              ? getValueBeforePlus(item.surveyAnswers.selectedOptions2, true) ||
                -5
              : -5,
            sleep_state_after_match: item.surveyAnswers.selectedOptions3
              ? getValueBeforePlus(item.surveyAnswers.selectedOptions3, true) ||
                -5
              : -5,
            mood_before_match: item.surveyAnswers.selectedOptions4
              ? removeArray(item.surveyAnswers.selectedOptions4, false) || []
              : [],
            mood_during_match: item.surveyAnswers.selectedOptions5
              ? removeArray(item.surveyAnswers.selectedOptions5, false) || []
              : [],
            physical_state_before_match: item.surveyAnswers.selectedOptions6
              ? getValueBeforePlus(item.surveyAnswers.selectedOptions6, true) ||
                -5
              : -5,
            match_result: item.surveyAnswers.selectedOptions7
              ? getValueBeforePlus(item.surveyAnswers.selectedOptions7, true) ||
                -5
              : -5,
            analyzed_sleep_state_before_match: item.analyzeResults
              .selectedGOptions1
              ? getValueBeforePlus(
                  item.analyzeResults.selectedGOptions1,
                  true
                ) || -5
              : -5,
            analyzed_sleep_state_after_match: item.analyzeResults
              .selectedGOptions2
              ? getValueBeforePlus(
                  item.analyzeResults.selectedGOptions2,
                  true
                ) || -5
              : -5,
            analyzed_match_result: item.analyzeResults.selectedGOptions3
              ? getValueBeforePlus(
                  item.analyzeResults.selectedGOptions3,
                  true
                ) || -5
              : -5,
          };
        }),
      };
      store.dispatch('createReport', {
        user_id: this.$route.params.id,
        reportData: reportValue,
      });
    },

    handleTitleInput(event) {
      if (event.target.value.length > 100) {
        this.validate0 = true;
      } else {
        this.validate0 = false;
      }
      this.title = event.target.value;
      if (this.title !== '' && !this.step0) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step0 = true;
      }
      if (this.title === '' && this.step0) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step0 = false;
      }
      this.progressPer(parseInt(localStorage.getItem('progress')));
    },

    handleOverallCommentInput(event) {
      if (event.length > 1000) {
        this.validate1 = true;
      } else {
        this.validate1 = false;
      }
      this.overallComment = event;
      if (this.overallComment !== '' && !this.step4) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step4 = true;
      }
      if (this.overallComment === '' && this.step4) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step4 = false;
      }
      this.progressPer(parseInt(localStorage.getItem('progress')));
    },

    handleAverageGlucoseInput(event) {
      if (event.length > 1000) {
        this.validate2 = true;
      } else {
        this.validate2 = false;
      }

      this.averageGlucoseComment = event;
      if (this.averageGlucoseComment != '' && !this.step5) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step5 = true;
      }
      if (this.averageGlucoseComment == '' && this.step5) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step5 = false;
      }
      this.progressPer(parseInt(localStorage.getItem('progress')));
    },

    handleSleepAverageInput(event) {
      if (event.length > 1000) {
        this.validate3 = true;
      } else {
        this.validate3 = false;
      }
      this.averageSleepGlucoseComment = event;
      if (this.averageSleepGlucoseComment != '' && !this.step6) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step6 = true;
      }
      if (this.averageSleepGlucoseComment == '' && this.step6) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step6 = false;
      }
      this.progressPer(parseInt(localStorage.getItem('progress')));
    },

    handleAccuracyChange() {
      this.getAccuracy();

      if (this.accuracy > 0 && !this.step3) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step3 = true;
      }
      if (this.accuracy == 0 && this.step3) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step3 = false;
      }
      this.progressPer(parseInt(localStorage.getItem('progress')));
    },

    getNotesData(data) {
      this.notes = data;
    },

    getAccuracy() {
      const accuracyValue = localStorage.getItem('selectedAccuracy');
      if (accuracyValue) {
        this.accuracy = accuracyValue >= 10 ? accuracyValue : this.noSelect;
      }
    },

    getMatchReportData(matchReport) {
      const existingIndex = this.matchReports.findIndex(
        (report) => report.matchEvent === matchReport.matchEvent
      );

      if (existingIndex !== -1) {
        this.matchReports[existingIndex] = matchReport;
      } else {
        this.matchReports.push(matchReport);
      }
    },

    modalHighPerformance() {
      this.getItemHighPerformance();
      this.progressPer(localStorage.getItem('progress'));

      if (this.upper > 0 && !this.step2) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step2 = true;
      }
      if (this.upper == 0 && this.step2) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step2 = false;
      }
      this.progressPer(parseInt(localStorage.getItem('progress')));
    },

    getItemHighPerformance() {
      const data = localStorage.getItem('highPerformance');
      if (data) {
        const parsedData = JSON.parse(data);
        this.lower = parsedData.lower;
        this.upper = parsedData.upper;
      }
    },

    onChangeCalendar(dates) {
      this.datesSelectInCalendar = dates;
      this.updateReportData();
      this.peroidCover();
      this.toggleCalendar();
      this.apiGetReportData();
      this.peroidMaxValue();
      this.peroidMinValue();

      if (this.datesSelectInCalendar.length > 0 && !this.step1) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data + 1);
        this.step1 = true;
      }
      if (this.datesSelectInCalendar.length == 0 && this.step1) {
        const data = parseInt(localStorage.getItem('progress'));
        localStorage.setItem('progress', data - 1);
        this.step1 = false;
      }

      this.progressPer(parseInt(localStorage.getItem('progress')));
      this.componentKey += 1;
    },

    progressPer(value) {
      if (value >= 0) {
        this.progressPercentage = (value / 8) * 100;
        this.minProgress = value;
      } else {
        this.progressPercentage = 0;
        this.minProgress = 0;
      }
    },

    updateReportData() {
      this.datesSelectInCalendar.length > 0
        ? localStorage.setItem(
            'peroidCover',
            JSON.stringify(this.datesSelectInCalendar)
          )
        : localStorage.setItem('peroidCover', JSON.stringify([]));
    },

    // Max Date
    peroidMaxValue() {
      const peroidCover = localStorage.getItem('peroidCover');
      const maxValue = this.datesSelectInCalendar
        ? this.datesSelectInCalendar
        : JSON.parse(peroidCover);
      this.maxPeroidCover =
        maxValue && maxValue.length >= 1
          ? formatJapaneseDate(
              new Date(Math.max(...maxValue.map((date) => new Date(date))))
                .toISOString()
                .slice(0, 10)
            )
          : '';
    },
    // Min Date
    peroidMinValue() {
      const peroidCover = localStorage.getItem('peroidCover');
      const minValue = this.datesSelectInCalendar
        ? this.datesSelectInCalendar
        : JSON.parse(peroidCover);
      this.minPeroidCover =
        minValue && minValue.length >= 1
          ? formatJapaneseDate(
              new Date(Math.min(...minValue.map((date) => new Date(date))))
                .toISOString()
                .slice(0, 10)
            )
          : '';
    },

    apiGetReportData() {
      store.dispatch('getReportData', {
        user_id: this.$route.params.id,
        dates: JSON.parse(localStorage.getItem('peroidCover')),
      });
    },

    peroidCover() {
      const storedValue = JSON.parse(localStorage.getItem('peroidCover'));
      if (storedValue) {
        this.datesSelectInCalendar = storedValue;
      }
    },

    toggleCalendar() {
      this.isShowCalendar = !this.isShowCalendar;
    },
  },
  watch: {
    createdReport(newReport) {
      this.$router.push({
        path: `/member/${this.$route.params.id}/report/${newReport.id}/detail`,
      });
    },
  },
};
</script>

<style scoped>
.report-header {
  position: sticky;
  top: 85px;
  z-index: 499;

  margin-bottom: 40px;
  padding: 16px 0 4px 0;

  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}

.border-custom {
  border: 1px solid #35363a;
  display: flex;
}

.lh-27 {
  line-height: 27px;
}

.calendar {
  right: initial;
  border: 1px solid #ddd;
  transition: transform 0.3s ease-out;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  top: -30px;
}

.text-black {
  font-weight: 700;
  font-size: 13px;
  color: #bfbfbf;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.btn {
  padding: 5px 5px 5px 10px;
}

.submit-btn {
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
}

.back-btn {
  font-size: 18px;
  font-weight: 700;
}

.fixline {
  display: flex;
  align-items: baseline;
}

.form-footer {
  display: flex;
  padding: 16px 60px;
  align-items: flex-start;
}

.step-content {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
