<template>
  <div v-if="showNotebook" class="popup-notebook">
    <div class="notebook-header">
      <span class="notebook-title">メモ</span>
      <button class="close-button" @click="closeNotebook">X</button>
    </div>
    <div class="notebook-body" ref="messageList">
      <ul class="message-list">
        <li
          v-for="(message, index) in messages"
          :key="index"
          class="user-message"
        >
          {{ message }}
          <button class="delete-button" @click="deleteMessage(index)">
            削除
          </button>
        </li>
      </ul>
    </div>
    <div class="notebook-footer">
      <input
        class="form-control"
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="メモを入力してください..."
      />
    </div>
  </div>
  <button v-else class="open-button" @click="openNotebook">メモを開く</button>
</template>

<script>
export default {
  name: "NoteBook",
  props: {
    notesData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showNotebook: false,
      messages: [],
      newMessage: "",
    };
  },
  watch: {
    notesData: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.messages = newVal;
          this.$emit("saveNotes", JSON.stringify(this.messages));
        }
      },
      deep: true,
      immediate: true,
    },

    messages() {
      if (this.messages.length > 0) {
        this.showNotebook = true;
      }
    },
  },
  methods: {
    openNotebook() {
      this.showNotebook = true;
    },
    closeNotebook() {
      this.showNotebook = false;
    },
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.messages.push(this.newMessage);
        this.newMessage = "";
      }
      this.$emit("saveNotes", JSON.stringify(this.messages));
      this.scrollMessageListToBottom();
    },
    scrollMessageListToBottom() {
      this.$nextTick(() => {
        const messageList = this.$refs.messageList;
        messageList.scrollTop = messageList.scrollHeight;
      });
    },
    deleteMessage(index) {
      this.messages.splice(index, 1);
      this.$emit("saveNotes", JSON.stringify(this.messages));
    },
    loadNoteHistory() {
      const noteHistory = localStorage.getItem("noteHistory");
      if (noteHistory) {
        this.messages = JSON.parse(noteHistory);
      }
    },
  },
  mounted() {
    this.loadNoteHistory();
    if (this.notesData.length > 0) {
      this.messages = this.notesData;
    }
  },
};
</script>

<style>
.actions {
  display: inline-block;
}

.popup-notebook {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  background-color: #fff;
  border: 1px solid #8b172f;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  z-index: 1000;

  word-wrap: break-word;
}

.notebook-header {
  display: flex;
  padding: 10px;
  justify-content: space-between;

  color: #fff;
  background-color: #8b172f;
  border-bottom: 1px solid #8b172f;
  align-items: center;
}

.notebook-title {
  font-weight: bold;
}

.close-button {
  cursor: pointer;
  border: none;

  color: #fff;
  background-color: #8b172f;
}

.notebook-body {
  height: 300px;
  padding: 10px;
  overflow-y: auto;
}

.message-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-message {
  display: block;
  max-width: fit-content;
  word-wrap: break-word;

  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;

  background-color: #f0f0f0;
}

.notebook-footer {
  padding: 10px;
}

.open-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  color: #8b172f;

  background-color: #fff;
  border: 1px solid #8b172f;

  border-radius: 5px;
  cursor: pointer;
}

.delete-button {
  border: none;
  font-style: italic;
  font-weight: 200;

  font-size: 12px;

  color: #8b172f;
  padding-right: 0;
  padding-left: 0;
}
</style>
