<template>
  <div id="chart">
    <apexchart
      type="line"
      height="350"
      :key="chartKey"
      :options="chartOptions"
      :series="mergeSeries"
    >
    </apexchart>
  </div>
</template>

<script>
import store from "../../store/index.js";
import moment from "moment";
import { colors } from "../../utils/const";
import { chartOptions } from "../common.js";

export default {
  computed: {
    chartData() {
      return store.state.reportData.data.daily;
    },
  },

  data() {
    return {
      chartKey: 0,
      colors: colors,
      default: false,
      chartOptions: chartOptions(0, 0, 0, 0, 0, 2),
      mergeSeries: [],
    };
  },

  methods: {
    async updateMergeSeries() {
      this.mergeSeries = this.chartData.map((obj) => {
        const date = obj.date;
        const data = obj.series.data;

        return {
          name: date,
          data: data.slice(0, -1).map((item) => {
            const { x, y } = item;
            const nextItem = data[data.indexOf(item) + 1];
            const timeDiff = moment(nextItem.x).diff(moment(x));

            if (timeDiff > 30 * 60 * 1000) {
              return {
                x: this.formatDate(item.x),
                y: null,
              };
            }
            return {
              x: this.formatDate(item.x),
              y,
            };
          }),
        };
      });
    },

    formatDate(dateStr) {
      const dateFormat =
        `${moment().format("YYYY-MM-DD")} ` + dateStr.substring(11, 16);
      return dateFormat;
    },

    handleColor(index) {
      return this.colors[index % 14];
    },
  },

  mounted() {
    if (this.chartData) {
      this.updateMergeSeries();
    }
  },
  watch: {
    chartData: {
      handler: function () {
        this.updateMergeSeries();
        this.chartKey += 1;
      },
      deep: true,
    },
  },
};
</script>
