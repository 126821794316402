export function setListReport(state, data = {}) {
  state.reportList.data = data;
}

export function setReportData(state, data = {}) {
  state.reportData.data = data;
}

export function setSurvey(state, data = {}) {
  state.surveyOptions.data = data;
}

export function setDetailReport(state, data = {}) {
  state.detailReport.data = data;
}

export function setPreviewReport(state, data = {}) {
  state.previewReport.data = data;
}

export function updateReportId(state, reportId) {
  state.reportId = reportId;
}

export function setCreateReport(state, data = {}) {
  state.createReport.data = data;
}
