<template>
  <div class="chart-wrapper" ref="wrapper">
    <div class="filters-heading">
      <div class="filters-title">
        {{ dateFormatted }}
      </div>
    </div>
    <div class="d-flex align-items-center event-tools">
      <div class="d-flex align-items-center">
        <div class="mt-1 me-1 fw-bold">フィルター：</div>
        <div class="filters-checkboxes mt-1">
          <div class="form-check form-check-inline mb-0">
            <input
              class="form-check-input"
              type="checkbox"
              id="showSleep"
              v-model="showSleep"
            />
            <label class="form-check-label" for="showSleep"> 睡眠</label>
          </div>
          <div class="form-check form-check-inline mb-0">
            <input
              class="form-check-input"
              type="checkbox"
              id="showMeal"
              v-model="showMeal"
            />
            <label class="form-check-label" for="showMeal"> 食事</label>
          </div>
          <div class="form-check form-check-inline mb-0">
            <input
              class="form-check-input"
              type="checkbox"
              id="showActivity"
              v-model="showActivity"
            />
            <label class="form-check-label" for="showActivity"> 睡眠時間</label>
          </div>
        </div>
      </div>
      <button
        v-if="!isManagerAndViewOnly"
        v-b-modal="`modal-event-${record.date}`"
        class="btn btn-add-event ms-auto py-1 px-2"
      >
        イベント追加
      </button>
    </div>
    <div class="chart-sub-text fw-bold">グルコースmg/dL</div>
    <apexchart
      ref="chart"
      height="300"
      width="800"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
    <div class="table-responsive">
      <table v-if="tableEventData.length > 0" class="table-sm event-list">
        <thead class="border-bottom">
          <tr>
            <th scope="col">#</th>
            <th scope="col">日程</th>
            <th scope="col">時間</th>
            <th scope="col">編集</th>
            <th scope="col">削除</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in tableEventData"
            :key="item.id"
            class="border-bottom"
          >
            <td>
              <i :class="`${getIconClass(item.subtype)} mt-1`"></i>
            </td>
            <td>
              <span class="fw-bold">
                {{ formatDate(item?.end_at) }}
              </span>
            </td>
            <td>
              <span class="fw-bold">
                {{
                  formatTimeRange(item?.start_at, item?.end_at)
                    .formattedDuration
                }}
              </span>
              <span style="font-size: 11px"
                >({{ formatTimeRange(item?.start_at, item?.end_at).timeRange }})
              </span>
            </td>
            <td>
              <button v-b-modal="`modal-edit-event-${item.id}`" class="btn">
                <i class="ico-pencil"></i>
              </button>
              <EventModal
                :modal-id="`modal-edit-event-${item.id}`"
                :event-data="item"
                @edit-event="handleEditEvent"
                :event-title="`イベントを編集`"
              />
            </td>
            <td>
              <button class="btn" @click="deleteConfirmation(item)">
                <i class="ico-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      ref="deleteConfirmationModal"
      title="イベントを削除"
      :hide-header="true"
      :hide-footer="true"
    >
      <p class="text-center fs-5">このイベントを削除してもよろしいですか？</p>
      <div class="d-flex mt-2 justify-content-center">
        <b-button variant="outline-secondary" @click="closeDeleteConfirmation">
          キャンセル
        </b-button>
        <b-button
          type="submit"
          class="btn btn-primary-custom ms-3"
          @click="handleDeleteEvent"
        >
          保存
        </b-button>
      </div>
    </b-modal>
    <comment
      v-if="comment"
      :comment="comment"
      @add-comment="addComment"
      @open-comment="openComment"
      @add-reply="addReply"
      @hide="hideComment"
      style="position: absolute; right: auto"
      :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
    />
    <EventModal
      :date-selected="record.date"
      :modal-id="`modal-event-${record.date}`"
      @add-event="handleAddEvent"
      :event-title="`イベントを追加`"
    />
    <Statistics
      v-if="statistics"
      :statistics="statistics"
      style="position: absolute; right: auto; top: 280px"
      :style="{ left: rectX + 'px' }"
    />
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import EventModal from "./EventModal";
import Comment from "./Comment";
import Statistics from "./Statistics";
import moment from "moment";
export default {
  name: "ActivityChart",
  components: {
    Comment,
    EventModal,
    Statistics,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    showComment: {
      type: Boolean,
      default: true,
    },
    showMood: {
      type: Boolean,
      default: true,
    },
    syncCommentToCombineChart: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: null,
      statistics: null,
      comment: null,
      chartEventData: [],
      tableEventData: [],
      date: new Date(this.record.date),
      lastClick: 0,
      rectX: 0,
      rectY: 0,
      mouseX: 100,
      mouseY: 0,
      showModal: true,
      showEventModal: false,
      showMeal: true,
      showSleep: true,
      showActivity: true,
      options: {
        annotations: {
          yaxis: [
            {
              y: 90,
              y2: 160,
              borderColor: "#000",
              fillColor: "#333",
              opacity: 0.1,
            },
          ],
          xaxis: [],
          points: [],
        },
        chart: {
          id: "glucose-chart",
          toolbar: {
            tools: {
              customIcons: [
                {
                  icon: '<i class="fa-solid fa-rotate-right" style="margin: 5px 0 0 2.5px;"></i>',
                  index: 1,
                  title: "Reset Selection",
                  class: "custom-icon",
                  click: () => {
                    this.$refs.chart.updateOptions({
                      chart: {
                        selection: {
                          xaxis: {
                            min: undefined,
                            max: undefined,
                          },
                        },
                      },
                    });
                    this.statistics = undefined;
                  },
                },
              ],
            },
            export: {
              csv: {
                headerCategory: "日付",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return dayjs(timestamp).format("YYYY-MM-DD HH:mm");
                },
              },
            },
          },
          selection: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
          events: {
            // eslint-disable-next-line no-unused-vars
            markerClick: (
              event,
              chartContext,
              // eslint-disable-next-line no-unused-vars
              { seriesIndex, dataPointIndex }
            ) => {
              this.statistics = undefined;
              let clientRect = this.$refs.wrapper.getBoundingClientRect();
              this.mouseX = event.clientX - clientRect.left;
              this.mouseY = event.clientY - clientRect.top;

              this.comment = undefined;
              let yValue =
                chartContext.w.globals.series[seriesIndex][dataPointIndex];
              let now = new Date().getTime();
              let diff = now - this.lastClick;
              this.lastClick = now;

              // check  range time user  click twice is < 500 milisecond => allow add comment
              if (diff < 500) {
                // const date = dayjs().format("YYYY-MM-DD");
                let xHourMinute = this.record.series[0].data[dataPointIndex].x;
                xHourMinute = dayjs(xHourMinute).format("HH:mm");
                this.comment = {
                  id: undefined,
                  user: this.$route.params.id,
                  comment_by: window.user.id,
                  parent: undefined,
                  comment: "",
                  y: yValue,
                  x: xHourMinute,
                  comment_at: dayjs().format("YYYY-MM-DD HH:mm"),
                  is_advisor: false,
                  dates: [this.record.date],
                };
              }
            },
            // eslint-disable-next-line no-unused-vars
            selection: (chartContext, { xaxis, yaxis }) => {
              let min = xaxis.min;
              let max = xaxis.max;

              // Convert the timestamps to a readable date format
              let startAt = new Date(min).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });
              let endAt = new Date(max).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });

              let selectedData = this.record.series[0].data.filter((point) => {
                let pointTimestamp = new Date(point.x).getTime();
                return pointTimestamp >= min && pointTimestamp <= max;
              });

              // Calculate statistics
              let totalPoints = selectedData.length;
              let total = selectedData.reduce((sum, point) => sum + point.y, 0);

              let maxPoint, minPoint, avgPoint;

              if (selectedData.length > 0) {
                maxPoint = Math.max(...selectedData.map((point) => point.y));
                minPoint = Math.min(...selectedData.map((point) => point.y));
                avgPoint = Math.round(
                  selectedData.reduce((sum, point) => sum + point.y, 0) /
                    selectedData.length
                );
              } else {
                maxPoint = minPoint = avgPoint = NaN;
              }

              this.statistics = {
                time: `${startAt} - ${endAt}`,
                totalPoints: totalPoints,
                maxPoint: maxPoint,
                minPoint: minPoint,
                avgPoint: avgPoint,
                total: total,
              };
            },
            // eslint-disable-next-line no-unused-vars
            mouseMove: (event, chartContext, config) => {
              let clientRect = this.$refs.wrapper.getBoundingClientRect();
              this.rectX = event.clientX + 30 - clientRect.left;
              this.rectY = event.clientY - clientRect.top;
            },
            // eslint-disable-next-line no-unused-vars
            mouseLeave: (event, chartContext, config) => {
              this.statistics = undefined;
            },
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
        tooltip: {
          x: {
            formatter: function (value) {
              return dayjs(value).format("HH:mm");
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
          min: new Date(`${this.record.date}T00:00:00`).getTime(),
          max: new Date(`${this.record.date}T23:59:59`).getTime(),
        },
        yaxis: {
          min: (min) => {
            return Math.floor(min / 10) * 10;
          },
          max: (max) => {
            return Math.ceil(max / 50) * 50;
          },
          forceNiceScale: true,
        },
      },
      series: [
        {
          data: this.record.series[0].data.map((item, index) => {
            const { x, y } = item;
            const nextItem = this.record.series[0].data[index + 1];

            if (nextItem) {
              const timeDiff = moment(nextItem.x).diff(moment(x));
              if (timeDiff > 30 * 60 * 1000) {
                return {
                  x: moment(x, "YYYY-MM-DD HH:mm").valueOf(),
                  y: null,
                };
              }
            }

            return {
              x: moment(x, "YYYY-MM-DD HH:mm").valueOf(),
              y,
            };
          }),
        },
      ],
    };
  },
  computed: {
    isManagerAndViewOnly() {
      return this.user?.type === "Manager" && this.user?.view_only === true;
    },
    dateFormatted() {
      return `${this.record.date_arr[0]}年${this.record.date_arr[1]}月${this.record.date_arr[2]}日`;
    },
  },
  methods: {
    resetSelection() {
      this.$refs.chart.updateOptions({
        chart: {
          selection: {
            xaxis: {
              min: undefined,
              max: undefined,
            },
          },
        },
      });
    },
    hideModal() {
      if (this.showModal) this.showModal = false;
    },
    updateChartOptions() {
      this.$refs.chart.updateOptions(this.options);
    },
    handleEventVisibility(type, events, newValue) {
      events.forEach((event) => this.addEventAnnotations(event, newValue));

      this.chartEventData.forEach((e) => {
        if (e.type === type) {
          this.addEventAnnotations(e, newValue);
        }
      });
    },
    initializeData() {
      this.handleEventVisibility(1, this.record.sleeps, this.showSleep);
      this.handleEventVisibility(2, this.record.meals, this.showMeal);
      this.handleEventVisibility(3, this.record.activity, this.showActivity);

      if (this.showMood) {
        this.record.scan_history.forEach((mood) =>
          this.addMoodAnnotations(mood)
        );
      }

      if (this.showComment) {
        for (const comment of this.record.comments) {
          if (comment.x) {
            this.addCommentAnnotations(comment);
          }
        }
      }
    },
    addEventAnnotations(event, addAnnotation = true) {
      const eventData = {
        id: `event_annotation_${event.type}_${event.id}`,
        x: new Date(event.start_at).getTime(),
        x2: new Date(event.end_at).getTime(),
        fillColor: this.getColor(event.subtype).color,
        opacity: 0.2,
        label: {
          borderColor: this.getColor(event.subtype).color,
          style: {
            fontSize: "10px",
            color: "#fff",
            background: this.getColor(event.subtype).color,
          },
          offsetY: -5,
          text: this.getColor(event.subtype).text,
        },
      };
      if (addAnnotation) {
        this.$refs.chart.addXaxisAnnotation(eventData);
      } else {
        this.$refs.chart.removeAnnotation(eventData.id);
      }
      this.updateChartOptions();
    },
    addMoodAnnotations(mood) {
      const moodText = mood.type.slice(0, 2).join(",");
      const moodData = {
        id: "mood_annotation_" + mood.id,
        x: new Date(mood.log_at).getTime(),
        fillColor: "#775DD0",
        strokeDashArray: 5,
        opacity: 5,
        label: {
          borderColor: "#775DD0",
          style: {
            color: "#fff",
            background: "#775DD0",
          },
          text: moodText.length > 2 ? moodText + ",..." : moodText,
        },
      };
      this.$refs.chart.addXaxisAnnotation(moodData);
    },
    addCommentAnnotations(comment) {
      const dateStr = moment(this.record.date).format("YYYY-MM-DD");
      const xTimeNumber = new Date(`${dateStr}T${comment.x}:00`);
      const commentData = {
        id: "annotation_comment_" + comment.id,
        x: xTimeNumber.getTime(),
        y: 100,
        marker: { size: 15 },
        image: {
          path: comment.comment_by?.avatar
            ? comment.comment_by.avatar
            : process.env.VUE_APP_ROOT_API +
              "/static/images/avatar-default.svg",
        },
        // eslint-disable-next-line no-unused-vars
        click: (event, chartContext, config) => {
          this.comment = comment;
        },
      };
      this.$refs.chart.addPointAnnotation(commentData);
      this.updateChartOptions();
    },
    getColor(type) {
      const typeMappings = {
        1: { color: "#18698c", text: "睡眠時間" },
        2: { color: "#8c7518", text: "朝食" },
        3: { color: "#8c7518", text: "昼食" },
        4: { color: "#8c7518", text: "夜食" },
        5: { color: "#8c7518", text: "間食" },
        6: { color: "#75188c", text: "練習" },
        7: { color: "#75188c", text: "試合" },
        8: { color: "#75188c", text: "筋トレ" },
        default: { color: "#808080", text: "Unknown" },
      };

      return typeMappings[type] || typeMappings.default;
    },
    openComment(comment, x, y) {
      this.mouseX = x;
      this.mouseY = y;
      this.comment = comment;
    },
    async addComment(params) {
      try {
        const memberId = this.$route.params.id;
        this.comment.comment = params.text;
        this.comment.comment_at = dayjs().format("YYYY-MM-DD HH:mm");
        this.comment.comment_by = window.user.id;
        this.comment.parent = undefined;
        this.comment.is_advisor = false;
        this.comment.dates = [this.record.date];

        const res = await axios.post(
          `/manager/v2/comments/${memberId}/`,
          this.comment
        );
        this.comment = { ...res.data, date: this.comment.date };
        this.syncCommentToCombineChart(this.comment);
        this.addCommentAnnotations(this.comment);
      } catch (error) {
        console.log("error adding comment:", error);
      }
    },
    async addReply(params) {
      try {
        const memberId = this.$route.params.id;
        const response = await axios.post(`/manager/v2/comments/${memberId}/`, {
          parent: this.comment.id,
          comment_at: dayjs().format("YYYY-MM-DD HH:mm"),
          comment_by: window.user.id,
          comment: params.text,
        });
        this.comment.children.push(response.data);
      } catch (error) {
        console.log("error adding reply:", error);
      }
    },
    hideComment() {
      this.comment = undefined;
    },
    formatDate(date) {
      const formattedDate = date ? dayjs(date).format("YYYY.MM.DD") : "";
      const weekdayIndex = new Date(date).getDay();
      const weekdayNames = ["日", "月", "火", "水", "木", "金", "土"];
      return `${formattedDate} (${weekdayNames[weekdayIndex]})`;
    },
    formatTimeRange(start, end) {
      const startDate = start ? new Date(start) : null;
      const endDate = end ? new Date(end) : null;

      const durationInMilliseconds = endDate ? endDate - startDate : 0;

      const durationHours = Math.floor(
        durationInMilliseconds / (60 * 60 * 1000)
      );
      const durationMinutes = Math.floor(
        (durationInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
      );

      const formattedDuration = `${durationHours}時間${durationMinutes}分`;

      const formattedStartTime = startDate
        ? startDate.toLocaleTimeString("ja-JP", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
        : "";
      const formattedEndTime = endDate
        ? endDate.toLocaleTimeString("ja-JP", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          })
        : "";

      return {
        formattedDuration: formattedDuration,
        timeRange: `${formattedStartTime} - ${formattedEndTime}`,
      };
    },
    getIconClass(type) {
      const iconClasses = {
        1: "ico-sleep",
        2: "ico-breakfast",
        3: "ico-lunch",
        4: "ico-dinner",
        5: "ico-snack",
        6: "ico-workout",
        7: "ico-match",
        8: "ico-match",
      };

      return iconClasses[type] || "";
    },
    sortTableEventData() {
      this.tableEventData.sort((a, b) => {
        return new Date(a.start_at) - new Date(b.start_at);
      });
    },
    closeDeleteConfirmation() {
      this.$refs.deleteConfirmationModal.hide();
    },
    deleteConfirmation(event) {
      this.$data.eventToDelete = event;
      this.$refs.deleteConfirmationModal.show();
    },
    handleAddEvent(eventData) {
      this.addEventAnnotations(eventData);
      this.updateEventLists(eventData);
    },
    handleEditEvent({ eventData, oldType }) {
      this.$refs.chart.removeAnnotation(
        `event_annotation_${oldType}_${eventData.id}`
      );
      this.updateEventListsAfterEdit(eventData, oldType);
      this.addEventAnnotations(eventData);
    },
    async handleDeleteEvent() {
      try {
        this.$data.isLoading = true;
        const event = this.$data.eventToDelete;
        this.$refs.deleteConfirmationModal.hide();
        this.$refs.chart.removeAnnotation(
          `event_annotation_${event.type}_${event.id}`
        );

        await axios.delete(`/manager/v2/events/${event.id}/`);
        this.updateEventListsAfterDelete(event.id);
        this.$data.successMessage = "Event deleted successfully";
      } catch (error) {
        console.error("Error deleting event:", error);
        this.$data.errorMessage = "Error deleting event";
      } finally {
        this.$data.isLoading = false;
      }
    },
    updateEventLists(eventData) {
      this.chartEventData.push(eventData);
      this.record.events = this.tableEventData.push(eventData);
      this.sortTableEventData();
    },
    updateEventListsAfterEdit(eventData, oldType) {
      const newType = eventData.type;

      const recordMap = {
        1: this.record.sleeps,
        2: this.record.meals,
        3: this.record.activity,
      };

      const updateNewRecord = (type) => {
        recordMap[type].push(eventData);
      };

      const removeOldRecord = (type) => {
        const index = recordMap[type].findIndex(
          (obj) => obj.id === eventData.id
        );
        if (index !== -1) {
          recordMap[type].splice(index, 1);
        }
      };

      const updateTableEventData = () => {
        const tableIndex = this.tableEventData.findIndex(
          (event) => event.id === eventData.id
        );
        if (tableIndex !== -1) {
          this.$set(this.tableEventData, tableIndex, eventData);
          this.sortTableEventData();
        }
      };

      const updateChartEventData = () => {
        const chartIndex = this.chartEventData.findIndex(
          (event) => event.id === eventData.id
        );
        if (chartIndex !== -1) {
          this.$set(this.chartEventData, chartIndex, eventData);
        }
      };

      updateTableEventData();
      updateChartEventData();
      removeOldRecord(oldType);
      updateNewRecord(newType);
    },
    updateEventListsAfterDelete(eventId) {
      const filterById = (list) => list.filter((item) => item.id !== eventId);

      this.tableEventData = filterById(this.tableEventData);
      this.chartEventData = filterById(this.chartEventData);
      this.record.sleeps = filterById(this.record.sleeps);
      this.record.meals = filterById(this.record.meals);
      this.record.activity = filterById(this.record.activity);
    },
  },
  created() {
    try {
      this.user = JSON.parse(localStorage.getItem("user"));
    } catch (err) {
      this.user = false;
    }

    this.tableEventData = this.record.events.slice();
    this.sortTableEventData();
  },
  mounted() {
    this.initializeData();
  },
  watch: {
    showSleep(newValue) {
      this.handleEventVisibility(1, this.record.sleeps, newValue);
    },
    showMeal(newValue) {
      this.handleEventVisibility(2, this.record.meals, newValue);
    },
    showActivity(newValue) {
      this.handleEventVisibility(3, this.record.activity, newValue);
    },
  },
};
</script>

<style>
.chart-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.event-tools {
  width: 800px;
  margin-bottom: 10px;
}

.event-list {
  width: 800px;
  max-height: 150px;
  margin-top: 20px;
  margin-bottom: 30px;
  overflow-y: auto;
}

.event-list td,
th {
  padding: 0;
  text-align: center;
}

.filters-checkboxes label {
  padding-top: 3.5px;
}

.form-check {
  margin-right: 10px;
}

.form-check label {
  cursor: none;
  pointer-events: none;
}

.btn-add-event {
  background-color: transparent;
  border: 1px solid #8b172f;
  color: #8b172f;

  text-decoration: none;
}

.btn-add-event:hover {
  background-color: transparent;
  border: 1px solid #8b172f;
  color: #8b172f;

  text-decoration: none;
}
</style>
