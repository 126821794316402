<template>
  <div style="position: relative" ref="combineChartWrapper">
    <apexchart
      height="500"
      width="800"
      type="line"
      :options="options"
      :series="series"
      ref="combineChart"
    ></apexchart>
    <mutiple-comment
      v-if="comment"
      :comment="comment"
      :loading="loading"
      @add-comment="addComment"
      @open-comment="openComment"
      @add-reply="addReply"
      @hide="hideComment"
      :dates-array="datesArray"
      style="position: absolute; right: auto"
      :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import moment from "moment";
import axios from "axios";
import MutipleComment from "./MutipleComment";
export default {
  name: "CombineChart",
  props: [
    "series",
    "commentCombineChart",
    "datesArray",
    "showComment",
    "seriesDisplay",
  ],
  data: function () {
    let vm = this;
    return {
      showModal: true,
      mouseX: 100,
      mouseY: 0,
      lastClick: 0,
      comment: null,
      loading: false,
      options: {
        chart: {
          id: "combine-glucose-chart",
          zoom: {
            type: "xy",
            enabled: true,
            autoScaleYaxis: true,
          },
          animations: {
            enabled: false, // Disable animations
          },
          type: "area",
          toolbar: {
            autoSelected: "zoom",
            tools: {
              customIcons: [
                {
                  icon: '<i class="fas fa-download"></i>',
                  index: 2,
                  title: "Download glucose",
                  class: "custom-icon",
                  click: function () {
                    const memberId = vm.$route.params.id;

                    axios
                      .post(
                        "/manager/v2/teams/export-glucose/?member=" + memberId,
                        {
                          dates: vm.datesArray,
                        }
                      )
                      .then((res) => {
                        let file_name = "glucose_data.csv";
                        const url = window.URL.createObjectURL(
                          new Blob([res.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", file_name);
                        document.body.appendChild(link);
                        link.click();
                      })
                      .catch(() => {});
                  },
                },
              ],
            },
          },
          events: {
            markerClick: (
              event,
              chartContext,
              // eslint-disable-next-line no-unused-vars
              { seriesIndex, dataPointIndex }
            ) => {
              let clientRect =
                this.$refs.combineChartWrapper.getBoundingClientRect();
              this.mouseX = event.clientX - clientRect.left;
              this.mouseY = event.clientY - clientRect.top;
              this.comment = undefined;
              let now = new Date().getTime();
              let diff = now - this.lastClick;
              this.lastClick = now;

              let yValue =
                chartContext.w.globals.series[seriesIndex][dataPointIndex];

              if (diff < 500) {
                const itemHasMaxSeries = this.series.reduce((max, item) => {
                  return item.data.length > max.data.length ? item : max;
                }, this.series[0]);

                this.comment = {
                  id: undefined,
                  user: this.$route.params.id,
                  comment_by: window.user.id,
                  parent: undefined,
                  comment: "",
                  y: yValue,
                  x: itemHasMaxSeries.data[dataPointIndex].x.slice(11, 16),
                  comment_at: dayjs().format("YYYY-MM-DD HH:mm"),
                  is_advisor: false,
                };
              }

              // this.$emit('show-comment', "data here ")
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 90,
              y2: 160,
              borderColor: "#000",
              fillColor: "#333",
              opacity: 0.1,
            },
          ],
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "",
              month: "",
              day: "00:00",
              hour: "HH:mm",
              minute: "mm",
              second: "ss",
            },
          },
          min: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
          max: new Date(new Date().setHours(23, 59, 59, 999)).getTime(),
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            formatter: function (value) {
              return dayjs(value).format("HH:mm:ss");
            },
          },
        },
        yaxis: {
          min: 0,
          max: (max) => {
            return max + 50;
          },
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              return Math.round(value);
            },
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
      },
    };
  },
  watch: {
    seriesDisplay: {
      immediate: true,
      handler() {
        this.handleAddPoint();
        this.datesArray.map((item) => {
          if (this.$refs.combineChart) {
            if (this.seriesDisplay.includes(item)) {
              this.$refs.combineChart.chart.showSeries(item);
            } else {
              this.$refs.combineChart.chart.hideSeries(item);
            }
          }
        });
      },
    },
    commentCombineChart: {
      immediate: true,
      handler() {
        this.handleAddPoint();
      },
    },
  },
  components: {
    MutipleComment,
  },
  mounted: function () {
    this.handleAddPoint();
    if (this.$refs.combineChart && this.series?.length > 0) {
      // hide series is not in seriesDisplay
      this.series
        .filter((item) => !this.seriesDisplay.includes(item.name))
        .map((data) => {
          this.$refs.combineChart.chart.hideSeries(data.name);
        });
    }
  },
  methods: {
    hasDuplicateYValues() {
      const yValues = this.series.map((item) =>
        item.data.map((dataItem) => dataItem.y)
      );
      const flattenedYValues = [].concat(...yValues);
      const uniqueYValues = new Set(flattenedYValues);
      return flattenedYValues.length !== uniqueYValues.size;
    },

    addPoint(comment) {
      const dateStr = moment().format("YYYY-MM-DD");
      const xTimeNumber = new Date(`${dateStr}T${comment.x}:00`);
      const commentData = {
        id: "annotation_comment_" + comment.id,
        x: xTimeNumber.getTime(),
        y: 100,
        marker: { size: 15 },
        image: {
          path: comment.comment_by?.avatar
            ? comment.comment_by.avatar
            : process.env.VUE_APP_ROOT_API +
              "/static/images/avatar-default.svg",
        },
        // eslint-disable-next-line no-unused-vars
        click: (event, chartContext, config) => {
          this.comment = comment;
        },
      };
      this.$refs.combineChart.addPointAnnotation(commentData);
    },
    handleAddPoint() {
      if (this.showComment && this.$refs.combineChart) {
        this.commentCombineChart.forEach((item) => {
          if (item.dates.length > 0 && item.x) {
            this.$refs.combineChart.removeAnnotation(
              `annotation_comment_${item.id}`
            );

            if (item.dates.some((date) => this.seriesDisplay.includes(date))) {
              this.addPoint(item);
            }
          }
        });
      }
    },

    hideComment() {
      this.comment = undefined;
    },
    openComment(comment, x, y) {
      this.mouseX = x;
      this.mouseY = y;
      this.comment = comment;
    },
    async addComment(params, datesSelected) {
      try {
        const memberId = this.$route.params.id;
        this.comment.comment = params.text;
        this.comment.dates = datesSelected;
        this.loading = true;

        const response = await axios.post(
          `/manager/v2/comments/${memberId}/`,
          this.comment
        );
        this.comment = response.data;
        this.$emit("refresh-data");
      } catch (error) {
        console.log("error adding comment:", error);
      } finally {
        this.loading = false;
      }
    },
    async addReply(params) {
      try {
        const memberId = this.$route.params.id;
        const response = await axios.post(`/manager/v2/comments/${memberId}/`, {
          parent: this.comment.id,
          comment_at: dayjs().format("YYYY-MM-DD HH:mm"),
          comment_by: window.user.id,
          comment: params.text,
        });
        this.comment.children.push(response.data);
      } catch (error) {
        console.log("error adding reply:", error);
      }
    },
  },
};
</script>

<style></style>
