const colors = [
  "#8B172F",
  "#5EF916",
  "#07DBC1",
  "#27A6FF",
  "#C347FF",
  "#FF4FAF",
  "#FF835C",
  "#D19113",
  "#2DA813",
  "#0796C4",
  "#1A1ABC",
  "#7306A8",
  "#C00061",
  "#E13600",
];

const weekdayColors = [
  ["#FF0000", "#A30021"],
  ["#404040", "#7F7F7F"],
  ["#FF00FF", "#CC0099"],
  ["#FFE600", "#BF9000"],
  ["#00E000", "#008000"],
  ["#00F4FF", "#0099CC"],
  ["#004FEF", "#000099"],
];

const types = [
  {
    value: 2,
    label: "食事",
    iconClass: "ico-meal",
    color: "#8c7518",
  },
  {
    value: 1,
    label: "睡眠",
    iconClass: "ico-sleep",
    color: "#18698c",
  },
  {
    value: 3,
    label: "睡眠時間",
    iconClass: "ico-physical",
    color: "#75188c",
  },
];

const subtypes = [
  {
    value: 1,
    label: "睡眠時間",
    iconClass: "ico-sleep-24",
    color: "#18698c",
    type: 1,
  },
  {
    value: 2,
    label: "朝食",
    iconClass: "ico-breakfast-24",
    color: "#8c7518",
    type: 2,
  },
  {
    value: 3,
    label: "昼食",
    iconClass: "ico-lunch-24",
    color: "#8c7518",
    type: 2,
  },
  {
    value: 4,
    label: "夜食",
    iconClass: "ico-dinner-24",
    color: "#8c7518",
    type: 2,
  },
  {
    value: 5,
    label: "間食",
    iconClass: "ico-snack-24",
    color: "#8c7518",
    type: 2,
  },
  {
    value: 6,
    label: "練習",
    iconClass: "ico-workout-24 me-1",
    color: "#75188c",
    type: 3,
  },
  {
    value: 7,
    label: "試合",
    iconClass: "ico-match-24 me-1",
    color: "#75188c",
    type: 3,
  },
  {
    value: 8,
    label: "筋トレ",
    iconClass: "ico-match-24 me-1",
    color: "#75188c",
    type: 3,
  },
];

export { colors, weekdayColors, types, subtypes };

export const MSG_VALIDATE = "有効な値を入力してください";
