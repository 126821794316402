<template>
  <div class="show dropdown-menu static-menu">
    <div>時間: {{ time }}</div>
    <div>平均: {{ avgPoint }}</div>
    <div>最大: {{ maxPoint }}</div>
    <div>最小: {{ minPoint }}</div>
    <div>合計: {{ total }}</div>
    <div>データ数: {{ totalPoints }}</div>
  </div>
</template>
<script>
export default {
  props: ["statistics"],
  data() {
    return {
      time: "",
      maxPoint: 0,
      minPoint: 0,
      avgPoint: 0,
      totalPoints: 0,
      total: 0,
    };
  },
  created() {
    if (this.statistics) {
      this.time = this.statistics.time;
      this.maxPoint = this.statistics.maxPoint;
      this.minPoint = this.statistics.minPoint;
      this.avgPoint = this.statistics.avgPoint;
      this.totalPoints = this.statistics.totalPoints;
      this.total = this.statistics.total;
    }
  },
  methods: {},
  watch: {
    statistics: {
      handler(newVal) {
        if (newVal) {
          this.time = this.statistics.time;
          this.maxPoint = this.statistics.maxPoint;
          this.minPoint = this.statistics.minPoint;
          this.avgPoint = this.statistics.avgPoint;
          this.totalPoints = this.statistics.totalPoints;
          this.total = this.statistics.total;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style>
.static-menu {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  min-width: fit-content;
  padding: 10px 16px;
  border: none;
  top: 35px;
  right: 5px;
}
</style>
