<template>
  <b-modal
    ref="modal"
    @ok="handleOk"
    :id="modalId"
    :hide-footer="true"
    title="Sympafitスコア"
  >
    <span>前日の睡眠解析</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_1">
      <option
        v-for="option1 in surveyList.sleep_state"
        :value="option1.value + '+' + option1.title"
        :key="option1.value"
      >
        {{ option1.title }}
      </option>
    </select>
    <span>試合後の睡眠解析</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_2">
      <option
        v-for="option2 in surveyList.sleep_state"
        :value="option2.value + '+' + option2.title"
        :key="option2.value"
      >
        {{ option2.title }}
      </option>
    </select>
    <span>気持ち解析</span>
    <select class="form-select w-100 mt-2 mb-3" v-model="selectedOptions_3">
      <option
        v-for="option3 in surveyList.match_result"
        :value="option3.value + '+' + option3.title"
        :key="option3.value"
      >
        {{ option3.title }}
      </option>
    </select>
    <div class="text-end">
      <b-button variant="outline-secondary" @click="handleClose()"
        >キャンセル
      </b-button>
      <b-button
        type="submit"
        @click="handleOk()"
        class="btn btn-primary-custom ms-3"
        >決定</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import store from "../../store/index.js";
import { computed } from "vue";

export default {
  name: "AnalyzeResult",
  props: {
    modalId: String,
    loadAnalyze: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      surveyList: computed(() => store.state.surveyOptions.data),
      selectedOptions_1: "",
      selectedOptions_2: "",
      selectedOptions_3: "",
    };
  },

  mounted() {
    this.apiSurvey();
    this.loadAnalyzeData();
  },

  methods: {
    handleClose() {
      this.$refs.modal.hide();
    },

    handleOk() {
      this.apiSurvey();
      const analyzeData = JSON.stringify({
        selectedGOptions1: this.selectedOptions_1,
        selectedGOptions2: this.selectedOptions_2,
        selectedGOptions3: this.selectedOptions_3,
      });
      this.$emit("analyzeSubmitted", analyzeData);
      this.$refs.modal.hide();
    },

    loadAnalyzeData() {
      if (this.loadAnalyze !== "") {
        const { selectedGOptions1, selectedGOptions2, selectedGOptions3 } =
          JSON.parse(this.loadAnalyze);
        this.selectedOptions_1 = selectedGOptions1;
        this.selectedOptions_2 = selectedGOptions2;
        this.selectedOptions_3 = selectedGOptions3;
      }
      const analyzeData = JSON.stringify({
        selectedGOptions1: this.selectedOptions_1,
        selectedGOptions2: this.selectedOptions_2,
        selectedGOptions3: this.selectedOptions_3,
      });
      this.$emit("analyzeSubmitted", analyzeData);
    },

    apiSurvey() {
      store.dispatch("getSurvey");
    },
  },
  watch: {},
};
</script>

<style scope></style>
