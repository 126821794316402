<template>
  <div>
    <apexchart
      :key="chartKey"
      ref="chart"
      type="boxPlot"
      height="350"
      :options="chartOptionsColumn"
      :series="series"
    >
    </apexchart>
  </div>
</template>

<script>
import { chartOptionsColumn } from "../common.js";
import store from "../../store/index.js";

export default {
  props: {
    boxPlotUpper: {
      type: String,
      default: "#ff0000",
    },
    checkProp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartData() {
      return store.state.reportData.data.daily;
    },
  },

  data() {
    return {
      chartKey: 0,
      series: [],
      chartOptionsColumn: chartOptionsColumn(this.boxPlotUpper, ""),
      default: false,
    };
  },

  async created() {
    if (this.chartData) {
      await this.updateMergeSeries();
    }
    this.checkPropOption();
  },

  methods: {
    checkPropOption() {
      if (this.checkProp) {
        this.chartOptionsColumn = chartOptionsColumn(
          this.boxPlotUpper,
          "Interstitial glucose level / mg/dL"
        );
      } else {
        this.chartOptionsColumn = chartOptionsColumn(
          this.boxPlotUpper,
          "Interstitial glucose level in sleep / mg/dL"
        );
      }
    },
    async updateMergeSeries() {
      this.series = [
        {
          type: "boxPlot",
          data: Object.values(this.chartData).map((item) => {
            const ydata = this.checkProp
              ? [
                  item.series.min,
                  Math.round(item.series.avg),
                  0,
                  Math.round(item.series.avg),
                  item.series.max,
                ]
              : [
                  item.sleep.min,
                  Math.round(item.sleep.avg),
                  0,
                  Math.round(item.sleep.avg),
                  item.sleep.max,
                ];
            return {
              x: item.date,
              y: ydata,
            };
          }),
        },
      ];
    },
  },
  watch: {
    chartData: {
      handler: function () {
        this.updateMergeSeries();
        this.chartKey += 1;
      },
      deep: true,
    },
  },
};
</script>
