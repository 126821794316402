<template>
  <div class="mx-3">
    <apexchart
      ref="chart"
      type="line"
      height="350"
      :key="chartKey"
      :options="chartOptions"
      :series="mergeSeries"
    ></apexchart>
    <div class="rounded my-4">
      <b-form-textarea
        v-model="matchGlucoseComment"
        :rows="4"
        v-on:input="handleMatchGlucoseComment"
        placeholder="1000数字・文字以下入力してください。"
        maxlength="1000"
      >
      </b-form-textarea>
    </div>
    <div class="px-3">
      <div class="row my-3">
        <div class="col-md-3">
          <button
            v-b-modal="`modal-matchresult-${matchEvent}`"
            type="button"
            class="btn border-custom fz-12 fw-normal"
          >
            試合結果を編集
            <i class="ico-next-1"></i>
          </button>
        </div>
        <div class="col-md-9">
          <b
            class="fz-13 fw-bold color-2"
            :class="{ 'text-black': matchResult == '' }"
          >
            {{ matchResult ? matchResult : noSelect }}
          </b>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-3">
          <button
            v-b-modal="`modal-survey-${matchEvent}`"
            type="button"
            class="btn border-custom fz-12 fw-normal"
          >
            アンケートを編集
            <i class="ico-next-1"></i>
          </button>
        </div>
        <div class="col-md-9 lh-27">
          <span class="badge rounded-pill bg-dark px-2 fz-12">睡眠</span>
          <p class="fz-13 fw-bold mb-0">
            試合2-3日前：<b class="color-2">{{ surveyAnswer_1 }}</b>
          </p>
          <p class="fz-13 fw-bold mb-0">
            試合前日：<b class="color-2">{{ surveyAnswer_2 }}</b>
          </p>
          <p class="fz-13 fw-bold mb-0">
            試合後: <b class="color-2">{{ surveyAnswer_3 }}</b>
          </p>
          <span class="badge rounded-pill bg-dark px-2 fz-12">気持ち</span>
          <p class="fz-13 fw-bold mb-0">
            試合前：<b class="color-2">{{ surveyAnswer_4 }}</b>
          </p>
          <p class="fz-13 fw-bold">
            試合中：<b class="color-2">{{ surveyAnswer_5 }}</b>
          </p>
          <span class="badge rounded-pill bg-dark px-2 fz-12">体調</span>
          <p class="fz-13 fw-bold color-2">{{ surveyAnswer_6 }}</p>
          <span class="badge rounded-pill bg-dark px-2 fz-12">結果</span>
          <p class="fz-13 fw-bold color-2">{{ surveyAnswer_7 }}</p>
        </div>
        <div class="col-md-3">
          <button
            v-b-modal="`modal-analyze-${matchEvent}`"
            type="button"
            class="btn border-custom fz-12 fw-normal"
          >
            血糖値解析を編集
            <i class="ico-next-1"></i>
          </button>
        </div>
        <div class="col-md-9 lh-27">
          <span class="badge rounded-pill bg-dark px-2 fz-12">
            前日の睡眠解析
          </span>
          <p class="fz-13 fw-bold color-2">
            {{ this.analyzeResult_1 }}
          </p>
          <span class="badge rounded-pill bg-dark px-2 fz-12">
            試合後の睡眠解析
          </span>
          <p class="fz-13 fw-bold color-2">
            {{ this.analyzeResult_2 }}
          </p>
          <span class="badge rounded-pill bg-dark px-2 fz-12">気持ち解析</span>
          <p class="fz-13 fw-bold color-2">
            {{ this.analyzeResult_3 }}
          </p>
        </div>
      </div>
    </div>
    <AnalyzeResult
      @analyzeSubmitted="getAnalyzeResult"
      :modal-id="`modal-analyze-${matchEvent}`"
    />
    <MatchResult
      @matchResultSubmitted="getMatchResult"
      :modal-id="`modal-matchresult-${matchEvent}`"
    />
    <Survey
      @surveySubmitted="getSurveyResult"
      :modal-id="`modal-survey-${matchEvent}`"
    />
  </div>
</template>
<script>
import moment from "moment";
import MatchResult from "./MatchResult.vue";
import Survey from "./Survey.vue";
import AnalyzeResult from "./AnalyzeResult.vue";
import { colors } from "../../utils/const";
import { chartOptions } from "../common";
import { getValueBeforePlus } from "../common";
import { removeArray } from "../common";

export default {
  name: "MatchReport",
  props: {
    matchData: {
      type: Object,
      default: null,
    },
    matchEvent: {
      type: Number,
      default: 0,
    },
    matchStart: {
      type: String,
      default: "",
    },
    matchEnd: {
      type: String,
      default: "",
    },
    highPerLower: {
      type: Number,
      default: 0,
    },
    highPerUpper: {
      type: Number,
      default: 0,
    },
  },
  components: {
    MatchResult,
    Survey,
    AnalyzeResult,
  },
  data() {
    return {
      colors: colors,
      chartKey: 0,
      matchResult: "",
      matchGlucoseComment: "",
      validate: false,
      noSelect: "未選択",
      surveyAnswers: {},
      analyzeResults: {},
      surveyAnswer_1: "",
      surveyAnswer_2: "",
      surveyAnswer_3: "",
      surveyAnswer_4: "",
      surveyAnswer_5: "",
      surveyAnswer_6: "",
      surveyAnswer_7: "",
      analyzeResult_1: "",
      analyzeResult_2: "",
      analyzeResult_3: "",
      lower: 0,
      upper: 0,
      startTime: 0,
      endTime: 0,
      dash: 0,
      width: 2,
      chartOptions: chartOptions(0, 0, 0, 0, 0, 2),
      mergeSeries: [],
    };
  },

  created() {
    if (this.highPerLower) {
      this.lower = this.highPerLower;
    }

    if (this.highPerUpper) {
      this.upper = this.highPerUpper;
    }
  },

  methods: {
    handleMatchGlucoseComment() {},

    getMatchResult(matchResultInput) {
      this.matchResult = matchResultInput;
    },

    getSurveyResult(surveyData) {
      const survey = JSON.parse(surveyData);
      const {
        selectedOptions1,
        selectedOptions2,
        selectedOptions3,
        selectedOptions4,
        selectedOptions5,
        selectedOptions6,
        selectedOptions7,
      } = survey;

      this.surveyAnswer_1 = getValueBeforePlus(
        selectedOptions1 === "0" ? "未選択" : selectedOptions1,
        false
      );
      this.surveyAnswer_2 = getValueBeforePlus(selectedOptions2, false);
      this.surveyAnswer_3 = getValueBeforePlus(selectedOptions3, false);
      this.surveyAnswer_4 = removeArray(selectedOptions4, true).join(", ");
      this.surveyAnswer_5 = removeArray(selectedOptions5, true).join(", ");
      this.surveyAnswer_6 = getValueBeforePlus(
        selectedOptions6 ? selectedOptions6 : "",
        false
      );
      this.surveyAnswer_7 = getValueBeforePlus(
        selectedOptions7 ? selectedOptions7 : "",
        false
      );
      this.surveyAnswers = survey;
    },

    getAnalyzeResult(analyzeData) {
      const analyze = JSON.parse(analyzeData);
      const { selectedGOptions1, selectedGOptions2, selectedGOptions3 } =
        analyze;

      this.analyzeResult_1 = getValueBeforePlus(selectedGOptions1, false);
      this.analyzeResult_2 = getValueBeforePlus(selectedGOptions2, false);
      this.analyzeResult_3 = getValueBeforePlus(selectedGOptions3, false);
      this.analyzeResults = analyze;
    },

    getHighPerformance() {
      const highPerformanceData = JSON.parse(
        localStorage.getItem("highPerformance")
      );
      if (highPerformanceData) {
        const { lower, upper } = highPerformanceData;
        this.lower = lower;
        this.upper = upper;
      }
    },

    updateMergeSeries() {
      if (this.matchData?.series.length > 0) {
        this.mergeSeries = this.matchData.series.map((obj) => {
          const { date, data } = obj;
          return {
            name: date,
            data: data.slice(0, -1).map((item) => {
              const { x, y } = item;
              const nextItem = data[data.indexOf(item) + 1];
              const timeDiff = moment(nextItem.x).diff(moment(x));
              if (timeDiff > 30 * 60 * 1000) {
                return {
                  x: this.formatDate(item.x),
                  y: null,
                };
              }
              return {
                x: this.formatDate(item.x),
                y,
              };
            }),
          };
        });
      }
    },

    formatDate(dateStr) {
      const dateFormat =
        `${moment().format("YYYY-MM-DD")} ` + dateStr.substring(11, 16);
      return dateFormat;
    },

    handleColor(index) {
      return this.colors[index % 14];
    },
  },

  mounted() {
    this.updateMergeSeries();
    this.getHighPerformance();
    this.$emit("matchReportChanged", {
      matchEvent: this.matchEvent,
      matchResultInput: this.matchResult,
      matchGlucoseComment: this.matchGlucoseComment,
      surveyAnswers: this.surveyAnswers,
      analyzeResults: this.analyzeResults,
    });
  },

  watch: {
    mergeSeries: {
      immediate: true,
      handler(newVal) {
        let widthArray = [];
        let matchDate = new Date(this.matchEnd).getDate();
        for (const item of newVal) {
          let date = new Date(item.name).getDate();
          if (date == matchDate) {
            widthArray.push(5);
          } else {
            widthArray.push(2);
          }
        }
        this.width = widthArray;

        this.startTime = new Date(this.formatDate(this.matchStart)).getTime();
        this.endTime = new Date(this.formatDate(this.matchEnd)).getTime();

        this.chartOptions = chartOptions(
          this.lower,
          this.upper,
          this.startTime,
          this.endTime,
          this.dash,
          this.width
        );
      },
    },
    matchResult: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("matchReportChanged", {
          matchEvent: this.matchEvent,
          matchResultInput: newVal,
          matchGlucoseComment: this.matchGlucoseComment,
          surveyAnswers: this.surveyAnswers,
          analyzeResults: this.analyzeResults,
        });
      }
    },
    matchGlucoseComment: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("matchReportChanged", {
          matchEvent: this.matchEvent,
          matchResultInput: this.matchResult,
          matchGlucoseComment: newVal,
          surveyAnswers: this.surveyAnswers,
          analyzeResults: this.analyzeResults,
        });
      }
    },
    surveyAnswers: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("matchReportChanged", {
          matchEvent: this.matchEvent,
          matchResultInput: this.matchResult,
          matchGlucoseComment: this.matchGlucoseComment,
          surveyAnswers: newVal,
          analyzeResults: this.analyzeResults,
        });
      }
    },
    analyzeResults: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("matchReportChanged", {
          matchEvent: this.matchEvent,
          matchResultInput: this.matchResult,
          matchGlucoseComment: this.matchGlucoseComment,
          surveyAnswers: this.surveyAnswers,
          analyzeResults: newVal,
        });
      }
    },
    highPerLower: {
      handler() {
        this.lower = this.highPerLower;
        this.upper = this.highPerUpper;
        this.updateMergeSeries();
        this.chartKey += 1;
      },
    },
    highPerUpper: {
      handler() {
        this.lower = this.highPerLower;
        this.upper = this.highPerUpper;
        this.updateMergeSeries();
        this.chartKey += 1;
      },
    },
  },
};
</script>
<style>
.border-custom {
  border: 1px solid #35363a;
  display: flex;
}
.btn {
  padding: 5px 5px 5px 10px;
}
.text-black {
  font-weight: 700;
  font-size: 13px;
  color: #bfbfbf;
}
.lh-27 {
  line-height: 27px;
}
</style>
