<template>
  <div class="modal-wrapper">
    <div class="modal-overlay" @click="$emit('hide')"></div>
    <div class="show account-modal">
      <div class="setting-mail__header">
        <p class="setting-mail__title">メールの受信</p>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            v-model="notification"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"></label>
        </div>
      </div>
      <p class="setting-mail__description">
        選手へのコメント情報が登録されたメールアドレスに送信されます。当社がお客様のメールアドレスを他社と共有することはありません
      </p>
      <div class="d-flex justify-content-center">
        <button
          type="button"
          @click="switchReceiveMail"
          class="btn btn-primary"
        >
          保存する
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "SettingReceiveMail",
  components: {},
  props: ["user"],
  data: function () {
    return {
      notification: false,
    };
  },
  created: function () {
    this.notification = this.user.notification;
  },
  methods: {
    switchReceiveMail() {
      let data = {};
      data["notification"] = this.notification;
      axios.patch("/manager/v2/profile/", data).then((res) => {
        this.$emit("update-profile", res.data);
        this.$toasted.show("保存しました！", {
          theme: "bubble",
          position: "top-right",
          duration: 2000,
        });
      });
    },
  },
};
</script>

<style>
.setting-mail__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.setting-mail__title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #35363a;
}

.setting-mail__description {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #35363a;
}
</style>
