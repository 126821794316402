<template>
  <div class="border rounded mt-4 mb-4 p-5 text-center">
    <div class="mb-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 0C5.38062 0 0 5.38062 0 12C0 18.6194 5.38062 24 12 24C18.6194 24 24 18.6194 24 12C24 5.38062 18.6194 0 12 0ZM10.8387 17.8062V16.9625C10.8387 16.32 11.3575 15.8012 12 15.8012C12.6425 15.8012 13.1613 16.32 13.1613 16.9625V17.8062C13.1613 18.4487 12.6425 18.9675 12 18.9675C11.3575 18.9675 10.8387 18.4487 10.8387 17.8062ZM13.1613 13.5794C13.1613 14.2144 12.6425 14.7406 12 14.7406C11.3575 14.7406 10.8387 14.2144 10.8387 13.5794V6.19375C10.8387 5.55125 11.3575 5.0325 12 5.0325C12.6425 5.0325 13.1613 5.55125 13.1613 6.19375V13.5794Z"
          fill="#BFBFBF" />
      </svg>
    </div>
    <span class="pt-3 fz-13 lh-lg">レポートの期間が選択されていません</span>
    <p class="fz-10">レポートの期間を選択すると、期間内の血糖値データが表示されます</p>
  </div>
</template>
  
<script>
export default {
  name: 'NoDataMessage'
}
</script>
  
<style scoped></style>
  