<template>
  <b-modal
    ref="modal"
    @ok="handleOk"
    :id="modalId"
    :hide-footer="true"
    title="試合結果"
  >
    <textarea
      class="form-control mb-3"
      rows="1"
      v-model="matchResultInput"
      maxlength="300"
      placeholder="300数字。文字以下入力してください"
    ></textarea>
    <div class="text-end">
      <b-button variant="outline-secondary" @click="handleClose()"
        >キャンセル
      </b-button>
      <b-button
        type="submit"
        class="btn btn-primary-custom ms-3"
        @click="handleOk()"
        >決定
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "MatchResult",
  props: {
    modalId: String,
    matchResultData: {
      type: String,
      default: null,
    },
    loadResult: String,
  },

  data() {
    return {
      matchResultInput: "",
    };
  },

  mounted() {
    this.loadResultData();
  },

  methods: {
    openModal() {
      this.matchResultInput = this.loadResultData();
    },

    handleClose() {
      this.$refs.modal.hide();
    },

    handleOk() {
      this.$emit("matchResultSubmitted", this.matchResultInput);
      this.$refs.modal.hide();
    },

    loadResultData() {
      this.matchResultInput = this.loadResult ? this.loadResult : "";
    },
  },
};
</script>

<style scope></style>
