<template>
  <div class="container">
    <div class="login">
      <div class="login-form">
        <div class="logo text-center">
          <img
            class="img-fluid"
            src="./assets/img/logo.svg"
            alt="Sympafit"
            width="165"
            height="40"
          />
        </div>
        <div class="form">
          <fieldset class="verification-code rounded">
            <legend>承認コード</legend>
            <div class="verification-input-container">
              <input
                v-for="(digit, index) in codeDigits"
                :key="index"
                type="text"
                v-model="codeDigits[index]"
                maxlength="1"
                @input="onInput(index)"
                @keydown="onKeyDown(index, $event)"
                @paste="onPaste(index, $event)"
                class="verification-input"
                inputmode="numeric"
                pattern="[0-9]"
                :ref="`input-${index}`"
                required
              />
            </div>
          </fieldset>
          <div class="row align-items-center">
            <div class="col-7 d-inline-flex remember-me">
              <input type="checkbox" id="rememberMe" v-model="checked" />
              <label for="rememberMe" class="px-2"
                >このブラウザーを保存する</label
              >
            </div>
            <div class="col-5 text-end">
              <button
                @click="resend"
                class="border-0 bg-white re-send-btn"
                type="button"
              >
                再送信する
              </button>
            </div>
          </div>
          <div class="mt-3 text-danger" v-show="showError">{{ errorMsg }}</div>
          <button
            @click="auth"
            class="mt-4 btn btn-secondary w-100 btn-sz-lg"
            type="button"
          >
            送信する
          </button>
        </div>
      </div>
      <div class="copyright">
        © Nisshinbo Holdings Inc. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  components: {},

  data() {
    return {
      username: "",
      codeDigits: ["", "", "", ""],
      checked: false,
      showError: false,
      errorMsg: "",
    };
  },

  created: function () {
    try {
      this.username = localStorage.getItem("username");
    } catch (err) {
      this.username = "";
    }
  },

  methods: {
    onInput(index) {
      this.codeDigits[index] = this.codeDigits[index].replace(/[^0-9]/g, "");

      if (index < this.codeDigits.length - 1 && this.codeDigits[index] !== "") {
        this.$refs[`input-${index + 1}`][0].focus();
      }
    },

    onKeyDown(index, event) {
      if (event.key === "Backspace" && this.codeDigits[index] === "") {
        event.preventDefault();

        if (index > 0) {
          this.$refs[`input-${index - 1}`][0].focus();
        }
      }
    },

    onPaste(index, event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData("text");

      if (pastedText.length === this.codeDigits.length) {
        event.preventDefault();

        this.codeDigits = pastedText.split("");
        if (index < this.codeDigits.length - 1) {
          this.$refs[`input-${index + 3}`][0].focus();
        }
      }
    },

    auth() {
      const verificationCode = this.codeDigits.join("");
      const remeberMe = this.checked;

      delete axios.defaults.headers.common["Authorization"];
      axios
        .post("/manager/v2/auth/verify/", {
          username: this.username,
          code: verificationCode,
          remember_me: remeberMe,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          axios.defaults.headers.common["Authorization"] =
            "Token " + res.data.token;
          this.$router.push("/");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.code
          ) {
            this.errorMsg = error.response.data.code[0];
            this.showError = true;
          } else {
            this.errorMsg = "error";
            this.showError = true;
          }
        });
    },

    resend() {
      delete axios.defaults.headers.common["Authorization"];
      axios
        .post("/manager/v2/auth/resend/", {
          username: this.username,
        })
        .then(() => {
          localStorage.setItem("username", this.username);
          this.$router.go(0);
        })
        .catch(() => {
          this.showError = true;
        });
    },
  },
};
</script>

<style scoped>
input[type="text"].error {
  box-shadow: 0 0 0.5em 0 red;
}

.verification-code {
  margin-bottom: 20px;
  border: 1px solid #b3b1b2;
  padding: 5px 2px 12px 2px;
}

legend {
  font-family: "Noto Sans JP", sans-serif;
  color: #b3b1b2;

  padding: 0.2em 0.5em;
  font-size: 16px;
  position: relative;
  width: fit-content;
  float: none;
  margin-left: 5px;
  margin-bottom: 0px;
}

.verification-input-container {
  display: flex;
  justify-content: center;
  gap: 35px;
}

.verification-input {
  width: 45px;
  height: 45px;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.verification-input:focus {
  outline: none;
  border-color: #000;
}

.re-send-btn {
  color: #b3b1b2;
  padding-left: 0;
  padding-right: 0;
}

.re-send-btn:hover {
  color: #dc3545;
}
</style>
